import React from 'react'

const InventionIcon = () => (
    <svg
        width='206'
        height='207'
        viewBox='0 0 206 207'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M103 195.578C110.95 200.898 120.5 203.998 130.78 203.998C153.62 203.998 172.88 188.688 178.86 167.768C193.02 161.958 203 148.028 203 131.768C203 120.888 198.53 111.048 191.33 103.988C198.53 96.9283 203 87.0883 203 76.2083C203 56.1583 187.82 39.6483 168.32 37.5483C163.46 18.2683 146.01 3.98828 125.22 3.98828C117.12 3.98828 109.53 6.14828 103 9.93828C96.47 6.14828 88.87 3.98828 80.78 3.98828C59.99 3.98828 42.53 18.2683 37.68 37.5483C18.18 39.6483 3 56.1583 3 76.2083C3 87.0883 7.47 96.9283 14.67 103.988C7.47 111.048 3 120.888 3 131.768C3 148.028 12.97 161.958 27.14 167.768C33.12 188.688 52.38 203.998 75.22 203.998C85.5 203.998 95.05 200.898 103 195.578ZM103 195.578V9.94827M145.11 88.2083C121.86 88.2083 103 69.3583 103 46.0983M60.89 119.788C84.14 119.788 103 138.638 103 161.898'
            stroke='currentcolor'
            strokeWidth='6'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
)

export default InventionIcon
