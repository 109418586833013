import React from 'react'

const EPLogo = () => (
    <svg
        width='385'
        height='200'
        viewBox='0 0 385 200'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_1457_12726)'>
            <path
                d='M9.08008 155.659H139.445L147.728 115.792H65.3365L69.4563 96.3897H144.495L152.114 59.3133H77.341L81.4608 39.9113H162.125L170.188 0H41.5929L9.08008 155.659Z'
                fill='currentcolor'
            />
            <path
                d='M162.568 155.659H209.878L219.667 108.571H251.915C292.934 108.571 319.291 81.5502 319.291 50.1438C319.291 15.7254 300.774 0 272.203 0H195.038L162.568 155.659ZM234.286 39.9113H250.631C260.642 39.9113 271.362 41.9047 271.362 53.8648C271.362 64.3188 265.027 71.5392 245.847 71.5392H227.508L234.286 39.9113Z'
                fill='currentcolor'
            />
            <path
                d='M317.121 155.659H361.816L370.764 112.514H326.068L317.121 155.659ZM384.717 45.582H340.022L331.074 88.7713H375.769L384.717 45.582Z'
                fill='currentcolor'
            />
            <path
                d='M0 199.512H22.9906L24.4512 192.469H9.92246L10.6255 189.014H23.8706L25.2439 182.502H12.043L12.7461 179.047H27.0107L28.4282 172.004H5.704L0 199.512Z'
                fill='currentcolor'
            />
            <path
                d='M27.1094 199.512H34.7287L40.3987 172.004H32.7794L27.1094 199.512Z'
                fill='currentcolor'
            />
            <path
                d='M47.8844 187.243C48.4628 185.161 49.7895 183.921 51.8273 183.921C53.7767 183.921 54.7066 185.117 54.7066 186.623C54.7066 186.8 54.7066 187.066 54.5932 187.243L47.8844 187.243ZM53.3344 193.71C52.9715 194.375 52.0042 195.128 50.3214 195.128C47.9729 195.128 47.2211 193.356 47.2211 191.23H61.4845C61.7906 189.99 61.836 188.838 61.836 187.775C61.836 181.485 56.4756 179.004 52.0904 179.004C47.085 179.004 40.0859 182.016 40.0859 191.186C40.0859 196.058 42.9209 200.001 50.0084 200.001C58.6461 200.001 60.1531 195.039 60.6396 193.71H53.3344Z'
                fill='currentcolor'
            />
            <path
                d='M85.9353 187.11C86.0714 181.529 81.9493 179.004 76.412 179.004C68.7927 179.004 64.3633 184.054 64.3633 190.875C64.3633 194.596 66.0461 200.001 74.508 200.001C80.3095 200.001 83.9417 197.254 85.2719 191.54H77.9622C77.5199 192.648 76.6785 194.463 74.5965 194.463C72.3818 194.463 71.9826 192.692 71.9826 190.787C71.9826 188.926 72.8671 184.585 76.1909 184.585C77.0754 184.585 77.741 184.718 78.0948 185.161C78.5371 185.515 78.6732 186.135 78.6278 187.111L85.9353 187.11Z'
                fill='currentcolor'
            />
            <path
                d='M100.376 173.465H92.7567L91.4276 179.534H88.105L87.0391 184.229H90.4059L88.6346 192.424C88.4532 193.354 88.2377 194.063 88.2377 195.17C88.2377 196.632 88.9975 198.182 90.3197 198.935C91.7803 199.733 93.5085 199.733 95.1029 199.733C96.4762 199.642 97.8937 199.467 99.2227 199.245L100.375 193.708C99.7512 193.753 99.1343 193.841 97.8937 193.841C96.6984 193.841 96.3435 193.309 96.3435 192.645C96.3435 191.98 96.5703 191.271 96.7064 190.651L98.0343 184.228H102.066L103.177 179.533H99.0583L100.376 173.465Z'
                fill='currentcolor'
            />
            <path
                d='M101.795 199.512H109.413L111.141 191.14C112.026 186.932 113.621 185.559 116.856 185.559C117.831 185.559 118.849 185.736 119.779 186.047L121.196 179.313C120.527 179.092 119.779 179.004 118.538 179.004C116.546 179.004 114.065 180.51 112.691 182.636H112.578L113.202 179.535H105.849L101.795 199.512Z'
                fill='currentcolor'
            />
            <path
                d='M127.574 191.23C127.574 189.148 128.64 184.586 131.694 184.586C133.732 184.586 134.352 185.914 134.352 188.307C134.352 190.034 133.241 194.464 130.277 194.464C128.106 194.464 127.574 192.825 127.574 191.23ZM119.955 190.654C119.955 197.343 124.429 200.001 129.923 200.001C139.091 200.001 141.97 192.869 141.97 187.775C141.97 181.706 138.117 179.004 132.27 179.004C124.119 179.004 119.955 184.275 119.955 190.654Z'
                fill='currentcolor'
            />
            <path
                d='M143.123 199.513H150.742L153.001 188.926C153.444 186.844 154.242 185.117 156.678 185.117C157.918 185.117 158.848 185.737 158.848 187.022C158.848 187.819 158.451 189.192 158.224 190.344L156.231 199.513H163.894L166.286 188.218C166.592 186.756 166.808 185.294 166.808 184.408C166.808 180.466 163.841 179.004 160.296 179.004C157.905 179.004 155.602 180.244 154.14 182.06H154.026L154.605 179.536H147.252L143.123 199.513Z'
                fill='currentcolor'
            />
            <path
                d='M168.283 199.512H175.901L180.065 179.534H172.447L168.283 199.512ZM181.571 172.004H173.953L172.842 177.364H180.46L181.571 172.004Z'
                fill='currentcolor'
            />
            <path
                d='M202.879 187.11C203.015 181.529 198.893 179.004 193.311 179.004C185.692 179.004 181.307 184.054 181.307 190.875C181.307 194.596 182.991 200.001 191.451 200.001C197.209 200.001 200.885 197.254 202.171 191.54H194.861C194.464 192.648 193.622 194.463 191.54 194.463C189.325 194.463 188.926 192.692 188.926 190.787C188.926 188.926 189.81 184.585 193.09 184.585C193.975 184.585 194.64 184.718 195.038 185.161C195.435 185.515 195.617 186.135 195.571 187.111L202.879 187.11Z'
                fill='currentcolor'
            />
            <path
                d='M203.234 199.512H211.561L213.29 191.184H219.004C226.268 191.184 230.92 186.4 230.92 180.863C230.92 174.795 227.643 172.004 222.592 172.004H208.949L203.234 199.512ZM215.903 179.047H218.783C220.554 179.047 222.459 179.401 222.459 181.528C222.459 183.388 221.308 184.628 217.94 184.628H214.707L215.903 179.047Z'
                fill='currentcolor'
            />
            <path
                d='M238.006 193.754C238.006 192.293 239.157 191.628 240.443 191.54C241.815 191.407 243.277 191.274 244.517 190.654C244.03 193.622 242.967 195.482 240.399 195.482C239.157 195.482 238.006 195.128 238.006 193.754ZM240.177 185.56C240.619 184.054 241.682 183.522 243.233 183.522C244.385 183.522 245.624 183.876 245.624 185.25C245.624 187.73 242.17 187.42 240.044 187.553C234.285 187.997 230.387 189.414 230.387 194.685C230.387 198.494 233.399 200.001 236.81 200.001C239.069 200.001 241.638 199.203 242.967 197.564H243.081C242.967 198.229 243.194 198.893 243.341 199.513H250.827C250.521 198.583 250.691 199.247 250.521 197.741C250.521 196.412 252.958 187.465 252.958 184.32C252.958 179.491 246.889 179.004 243.256 179.004C238.384 179.004 234.22 180.244 233.023 185.56H240.177Z'
                fill='currentcolor'
            />
            <path
                d='M254.396 199.512H262.016L263.744 191.14C264.629 186.932 266.224 185.559 269.501 185.559C270.431 185.559 271.495 185.736 272.426 186.047L273.842 179.313C273.139 179.092 272.426 179.004 271.14 179.004C269.148 179.004 266.666 180.51 265.337 182.636H265.224L265.882 179.535H258.485L254.396 199.512Z'
                fill='currentcolor'
            />
            <path
                d='M286.91 173.465H279.291L277.962 179.534H274.64L273.574 184.229H276.94L275.169 192.424C274.987 193.354 274.772 194.063 274.772 195.17C274.772 196.632 275.531 198.182 276.854 198.935C278.315 199.733 280.043 199.733 281.638 199.733C283.011 199.642 284.429 199.467 285.758 199.245L286.909 193.708C286.285 193.753 285.669 193.841 284.429 193.841C283.232 193.841 282.879 193.309 282.879 192.645C282.879 191.98 283.06 191.271 283.242 190.651L284.569 184.228H288.601L289.712 179.533H285.594L286.91 173.465Z'
                fill='currentcolor'
            />
            <path
                d='M288.328 199.513H295.947L298.162 188.926C298.65 186.844 299.447 185.117 301.839 185.117C303.079 185.117 304.009 185.737 304.009 187.022C304.009 187.819 303.612 189.192 303.385 190.344L301.436 199.513H309.055L311.491 188.218C311.797 186.756 312.024 185.294 312.024 184.408C312.024 180.466 309.012 179.004 305.512 179.004C303.076 179.004 300.773 180.244 299.311 182.06H299.198L299.776 179.536H292.467L288.328 199.513Z'
                fill='currentcolor'
            />
            <path
                d='M322.125 187.243C322.704 185.161 324.031 183.921 326.068 183.921C328.018 183.921 328.948 185.117 328.948 186.623C328.948 186.8 328.948 187.066 328.834 187.243L322.125 187.243ZM327.575 193.71C327.179 194.375 326.245 195.128 324.562 195.128C322.215 195.128 321.462 193.356 321.462 191.23H335.681C336.044 189.99 336.078 188.838 336.078 187.775C336.078 181.485 330.718 179.004 326.288 179.004C321.327 179.004 314.328 182.016 314.328 191.186C314.328 196.058 317.208 200.001 324.251 200.001C332.888 200.001 334.395 195.039 334.882 193.71H327.575Z'
                fill='currentcolor'
            />
            <path
                d='M337.496 199.512H345.115L346.888 191.14C347.772 186.932 349.368 185.559 352.602 185.559C353.577 185.559 354.594 185.736 355.525 186.047L356.943 179.313C356.274 179.092 355.526 179.004 354.285 179.004C352.291 179.004 349.81 180.51 348.438 182.636H348.325L348.994 179.535H341.596L337.496 199.512Z'
                fill='currentcolor'
            />
        </g>
        <defs>
            <clipPath id='clip0_1457_12726'>
                <rect width='384.717' height='200' fill='white' />
            </clipPath>
        </defs>
    </svg>
)

export default EPLogo
