import React from 'react'

const CustomersIcon = () => (
    <svg
        width='208'
        height='166'
        viewBox='0 0 208 166'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_1429_12776)'>
            <path
                d='M94.6603 36.3302C94.6603 54.3702 80.0303 69.0002 61.9903 69.0002C43.9503 69.0002 29.3203 54.3702 29.3203 36.3302C29.3203 18.2902 43.9503 3.66016 61.9903 3.66016C80.0303 3.66016 94.6603 18.2902 94.6603 36.3302ZM102.62 162.34H21.3703C10.5403 162.34 1.78025 153.01 4.50025 142.54C22.1803 74.6002 101.8 74.6002 119.48 142.54C122.21 153.02 113.44 162.34 102.62 162.34Z'
                stroke='currentcolor'
                strokeWidth='6'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M145.999 162.34H186.619C197.449 162.34 206.219 153.06 203.499 142.58C192.299 99.4502 156.149 83.6702 126.699 95.2502C125.209 95.8402 123.739 96.4902 122.289 97.2202'
                stroke='#FF4802'
                strokeWidth='6'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M146.022 69.0002C164.065 69.0002 178.692 54.3733 178.692 36.3302C178.692 18.287 164.065 3.66016 146.022 3.66016C127.978 3.66016 113.352 18.287 113.352 36.3302C113.352 54.3733 127.978 69.0002 146.022 69.0002Z'
                stroke='#FF4802'
                strokeWidth='6'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </g>
        <defs>
            <clipPath id='clip0_1429_12776'>
                <rect width='208' height='166' fill='white' />
            </clipPath>
        </defs>
    </svg>
)

export default CustomersIcon
