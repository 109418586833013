import React from 'react'

const BlumeLogo = () => {
    return (
        <svg
            width='438'
            height='192'
            viewBox='0 0 438 192'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_1457_12653)'>
                <path
                    d='M27.15 169.98L21.45 191.4H18.72L14.55 177.36C14.37 176.8 14.2 176.23 14.05 175.64C13.9 175.05 13.77 174.53 13.66 174.08C13.55 173.63 13.48 173.32 13.47 173.14C13.45 173.4 13.34 173.95 13.14 174.8C12.94 175.65 12.71 176.54 12.45 177.46L8.4 191.41H5.67L0 169.99H2.82L6.15 183.07C6.39 183.99 6.6 184.88 6.77 185.74C6.94 186.6 7.07 187.41 7.17 188.17C7.27 187.39 7.42 186.54 7.62 185.62C7.82 184.7 8.05 183.8 8.31 182.92L12.09 169.99H14.88L18.81 183.01C19.09 183.93 19.33 184.85 19.53 185.77C19.73 186.69 19.88 187.49 19.98 188.17C20.06 187.43 20.19 186.63 20.39 185.75C20.58 184.88 20.79 183.98 21.04 183.04L24.34 169.99H27.16L27.15 169.98Z'
                    fill='currentcolor'
                />
                <path
                    d='M40.3494 191.4H32.6094V189.84L35.1294 189.27V172.14L32.6094 171.54V169.98H40.3494V171.54L37.8294 172.14V189.27L40.3494 189.84V191.4Z'
                    fill='currentcolor'
                />
                <path
                    d='M59.8894 185.67C59.8894 187.57 59.1994 189.05 57.8194 190.11C56.4394 191.17 54.5794 191.7 52.2394 191.7C51.0394 191.7 49.9294 191.61 48.9094 191.43C47.8894 191.25 47.0394 191 46.3594 190.68V188.1C47.0794 188.42 47.9794 188.71 49.0494 188.97C50.1194 189.23 51.2294 189.36 52.3594 189.36C53.9394 189.36 55.1394 189.05 55.9594 188.43C56.7794 187.81 57.1894 186.97 57.1894 185.91C57.1894 185.21 57.0394 184.62 56.7394 184.14C56.4394 183.66 55.9194 183.22 55.1794 182.82C54.4394 182.42 53.4294 181.98 52.1494 181.5C50.3094 180.84 48.9294 180.03 48.0094 179.07C47.0894 178.11 46.6294 176.8 46.6294 175.14C46.6294 174.02 46.9194 173.05 47.4994 172.23C48.0794 171.41 48.8794 170.78 49.8994 170.34C50.9194 169.9 52.1094 169.68 53.4694 169.68C54.6294 169.68 55.6994 169.79 56.6794 170.01C57.6594 170.23 58.5594 170.52 59.3794 170.88L58.5394 173.19C57.7994 172.87 56.9894 172.6 56.1094 172.38C55.2294 172.16 54.3294 172.05 53.4094 172.05C52.0694 172.05 51.0594 172.33 50.3794 172.9C49.6994 173.47 49.3594 174.23 49.3594 175.16C49.3594 175.88 49.5094 176.48 49.8094 176.96C50.1094 177.44 50.5994 177.88 51.2794 178.26C51.9594 178.65 52.8794 179.05 54.0394 179.47C55.2994 179.93 56.3694 180.43 57.2294 180.96C58.0994 181.49 58.7594 182.13 59.2094 182.87C59.6594 183.61 59.8894 184.54 59.8894 185.66V185.67Z'
                    fill='currentcolor'
                />
                <path
                    d='M78.7903 191.4H66.8203V169.98H78.7903V172.35H69.5203V179.04H78.2503V181.38H69.5203V189.03H78.7903V191.4Z'
                    fill='currentcolor'
                />
                <path
                    d='M93.89 191.4H91.19V172.35H84.5V169.98H100.55V172.35H93.89V191.4Z'
                    fill='currentcolor'
                />
                <path
                    d='M118.759 191.4H106.789V169.98H118.759V172.35H109.489V179.04H118.219V181.38H109.489V189.03H118.759V191.4Z'
                    fill='currentcolor'
                />
                <path
                    d='M135.17 172.051C132.87 172.051 131.06 172.821 129.74 174.361C128.42 175.901 127.76 178.011 127.76 180.691C127.76 183.371 128.37 185.461 129.6 187.011C130.83 188.561 132.68 189.331 135.14 189.331C136.06 189.331 136.94 189.251 137.78 189.091C138.62 188.931 139.45 188.731 140.27 188.491V190.831C139.47 191.131 138.64 191.351 137.79 191.491C136.94 191.631 135.92 191.701 134.75 191.701C132.57 191.701 130.75 191.251 129.29 190.351C127.83 189.451 126.73 188.171 126 186.511C125.27 184.851 124.91 182.901 124.91 180.661C124.91 178.421 125.3 176.601 126.1 174.951C126.89 173.301 128.05 172.011 129.59 171.081C131.13 170.151 133 169.691 135.2 169.691C137.4 169.691 139.43 170.111 141.11 170.951L140.03 173.231C139.37 172.931 138.63 172.661 137.82 172.421C137.01 172.181 136.12 172.061 135.16 172.061L135.17 172.051Z'
                    fill='currentcolor'
                />
                <path
                    d='M164.03 191.4H161.33V181.41H150.35V191.4H147.65V169.98H150.35V179.04H161.33V169.98H164.03V191.4Z'
                    fill='currentcolor'
                />
                <path
                    d='M192.98 180.091H200.39V190.591C199.23 190.971 198.06 191.251 196.88 191.431C195.7 191.611 194.36 191.701 192.86 191.701C190.64 191.701 188.77 191.261 187.25 190.371C185.73 189.481 184.57 188.211 183.79 186.561C183 184.911 182.6 182.961 182.6 180.691C182.6 178.421 183.04 176.531 183.9 174.891C184.77 173.241 186.03 171.961 187.7 171.051C189.36 170.141 191.36 169.691 193.7 169.691C194.9 169.691 196.03 169.801 197.1 170.021C198.17 170.241 199.16 170.551 200.08 170.951L199.06 173.291C198.3 172.971 197.44 172.691 196.5 172.441C195.55 172.191 194.56 172.061 193.54 172.061C190.98 172.061 188.98 172.841 187.56 174.381C186.13 175.931 185.41 178.041 185.41 180.701C185.41 182.401 185.68 183.911 186.24 185.211C186.79 186.521 187.65 187.541 188.83 188.271C190.01 189.001 191.56 189.361 193.48 189.361C194.42 189.361 195.22 189.311 195.88 189.211C196.54 189.111 197.14 188.991 197.68 188.851V182.491H192.97V180.091H192.98Z'
                    fill='currentcolor'
                />
                <path d='M208.34 191.4V169.98H211.04V189H220.4V191.4H208.34Z' fill='currentcolor' />
                <path
                    d='M244.96 180.661C244.96 182.881 244.58 184.821 243.83 186.471C243.08 188.121 241.97 189.401 240.5 190.321C239.03 191.241 237.22 191.701 235.08 191.701C232.94 191.701 231.01 191.241 229.54 190.321C228.07 189.401 226.98 188.111 226.26 186.451C225.54 184.791 225.18 182.851 225.18 180.631C225.18 178.411 225.54 176.531 226.26 174.881C226.98 173.231 228.07 171.941 229.54 171.021C231.01 170.101 232.87 169.641 235.1 169.641C237.33 169.641 239.04 170.101 240.5 171.011C241.96 171.921 243.07 173.201 243.82 174.851C244.57 176.501 244.95 178.431 244.95 180.651L244.96 180.661ZM228.04 180.661C228.04 183.361 228.61 185.491 229.75 187.041C230.89 188.591 232.67 189.371 235.09 189.371C237.51 189.371 239.28 188.601 240.42 187.041C241.55 185.491 242.11 183.371 242.11 180.661C242.11 177.951 241.55 175.851 240.43 174.321C239.31 172.791 237.54 172.031 235.12 172.031C232.7 172.031 230.89 172.791 229.75 174.321C228.61 175.851 228.04 177.961 228.04 180.661Z'
                    fill='currentcolor'
                />
                <path
                    d='M252.449 169.98H258.569C261.229 169.98 263.249 170.38 264.629 171.18C266.009 171.98 266.699 173.35 266.699 175.29C266.699 176.55 266.349 177.6 265.649 178.43C264.949 179.26 263.939 179.79 262.619 180.03V180.18C263.519 180.34 264.329 180.61 265.069 180.99C265.799 181.37 266.369 181.91 266.789 182.61C267.209 183.31 267.419 184.21 267.419 185.31C267.419 187.25 266.759 188.75 265.429 189.81C264.099 190.87 262.279 191.4 259.979 191.4H252.449V169.98ZM255.149 179.1H259.109C260.929 179.1 262.189 178.81 262.869 178.22C263.559 177.63 263.909 176.76 263.909 175.59C263.909 174.42 263.499 173.56 262.669 173.05C261.839 172.54 260.509 172.28 258.689 172.28H255.149V179.09V179.1ZM255.149 181.35V189.12H259.469C261.369 189.12 262.689 188.75 263.429 188.01C264.169 187.27 264.539 186.3 264.539 185.1C264.539 183.98 264.149 183.07 263.379 182.39C262.609 181.7 261.239 181.35 259.259 181.35H255.149Z'
                    fill='currentcolor'
                />
                <path
                    d='M288.411 191.401L285.831 184.771H277.341L274.791 191.401H272.061L280.431 169.891H282.861L291.201 191.401H288.411ZM285.021 182.371L282.621 175.891C282.561 175.731 282.461 175.441 282.321 175.021C282.181 174.601 282.041 174.161 281.921 173.721C281.791 173.271 281.681 172.921 281.601 172.681C281.441 173.301 281.271 173.901 281.101 174.491C280.931 175.081 280.791 175.551 280.671 175.891L278.241 182.371H285.021Z'
                    fill='currentcolor'
                />
                <path
                    d='M297.301 191.4V169.98H300.001V189H309.361V191.4H297.301Z'
                    fill='currentcolor'
                />
                <path
                    d='M336.11 180.091H343.52V190.591C342.36 190.971 341.19 191.251 340.01 191.431C338.83 191.611 337.49 191.701 335.99 191.701C333.77 191.701 331.9 191.261 330.38 190.371C328.86 189.481 327.7 188.211 326.91 186.561C326.12 184.911 325.73 182.961 325.73 180.691C325.73 178.421 326.16 176.531 327.03 174.891C327.9 173.241 329.16 171.961 330.82 171.051C332.48 170.141 334.48 169.691 336.82 169.691C338.02 169.691 339.15 169.801 340.23 170.021C341.3 170.241 342.29 170.551 343.22 170.951L342.2 173.291C341.44 172.971 340.58 172.691 339.64 172.441C338.69 172.191 337.7 172.061 336.68 172.061C334.12 172.061 332.12 172.841 330.69 174.381C329.26 175.931 328.55 178.041 328.55 180.701C328.55 182.401 328.82 183.911 329.37 185.211C329.92 186.521 330.79 187.541 331.97 188.271C333.15 189.001 334.7 189.361 336.62 189.361C337.56 189.361 338.36 189.311 339.02 189.211C339.68 189.111 340.28 188.991 340.82 188.851V182.491H336.11V180.091Z'
                    fill='currentcolor'
                />
                <path
                    d='M357.39 169.98C360.05 169.98 362.01 170.48 363.28 171.49C364.55 172.5 365.19 174.03 365.19 176.07C365.19 177.21 364.98 178.16 364.56 178.92C364.14 179.68 363.6 180.29 362.95 180.75C362.3 181.21 361.62 181.56 360.9 181.8L366.78 191.4H363.63L358.44 182.55H354.18V191.4H351.48V169.98H357.39ZM357.24 172.32H354.18V180.27H357.39C359.13 180.27 360.4 179.93 361.2 179.23C362 178.53 362.4 177.52 362.4 176.19C362.4 174.86 361.98 173.8 361.14 173.2C360.3 172.61 359 172.32 357.24 172.32Z'
                    fill='currentcolor'
                />
                <path
                    d='M391.411 180.661C391.411 182.881 391.031 184.821 390.281 186.471C389.531 188.121 388.421 189.401 386.951 190.321C385.481 191.241 383.681 191.701 381.541 191.701C379.401 191.701 377.471 191.241 376.001 190.321C374.531 189.401 373.431 188.111 372.711 186.451C371.991 184.791 371.631 182.851 371.631 180.631C371.631 178.411 371.991 176.531 372.711 174.881C373.431 173.231 374.521 171.941 376.001 171.021C377.471 170.101 379.321 169.641 381.561 169.641C383.801 169.641 385.501 170.101 386.961 171.011C388.421 171.921 389.521 173.201 390.271 174.851C391.021 176.501 391.401 178.431 391.401 180.651L391.411 180.661ZM374.491 180.661C374.491 183.361 375.061 185.491 376.201 187.041C377.341 188.591 379.121 189.371 381.541 189.371C383.961 189.371 385.731 188.601 386.861 187.041C387.991 185.491 388.561 183.371 388.561 180.661C388.561 177.951 388.001 175.851 386.881 174.321C385.761 172.791 383.991 172.031 381.571 172.031C379.151 172.031 377.341 172.791 376.201 174.321C375.061 175.851 374.491 177.961 374.491 180.661Z'
                    fill='currentcolor'
                />
                <path
                    d='M415.189 169.98V183.84C415.189 185.32 414.889 186.65 414.289 187.84C413.689 189.03 412.769 189.97 411.529 190.66C410.289 191.35 408.729 191.7 406.849 191.7C404.169 191.7 402.139 190.98 400.759 189.53C399.379 188.08 398.689 186.17 398.689 183.78V169.98H401.389V183.87C401.389 185.61 401.849 186.96 402.779 187.92C403.709 188.88 405.109 189.36 406.989 189.36C408.869 189.36 410.339 188.85 411.199 187.82C412.069 186.79 412.509 185.46 412.509 183.85V169.99H415.179L415.189 169.98Z'
                    fill='currentcolor'
                />
                <path
                    d='M429.199 169.98C431.999 169.98 434.039 170.53 435.319 171.63C436.599 172.73 437.239 174.28 437.239 176.28C437.239 177.46 436.969 178.57 436.449 179.6C435.919 180.63 435.029 181.47 433.779 182.1C432.529 182.74 430.829 183.06 428.699 183.06H426.239V191.4H423.539V169.98H429.209H429.199ZM428.959 172.29H426.229V180.75H428.389C430.429 180.75 431.949 180.42 432.949 179.76C433.949 179.1 434.449 177.98 434.449 176.4C434.449 175.02 433.999 173.99 433.119 173.31C432.229 172.63 430.839 172.29 428.959 172.29Z'
                    fill='currentcolor'
                />
                <path
                    d='M326.93 131.789C329.99 131.789 332.43 130.349 332.43 126.859V125.419C332.43 124.829 332.49 124.289 332.49 124.289H332.43C331.49 126.039 329.9 127.039 327.47 127.039C323.26 127.039 320.48 123.699 320.48 118.989C320.48 114.279 323.07 111.059 327.29 111.059C331.51 111.059 332.47 113.679 332.47 113.679H332.53C332.53 113.679 332.5 113.489 332.5 113.149V111.429H334.56V126.759C334.56 131.759 330.88 133.689 327.04 133.689C325.23 133.689 323.42 133.219 321.89 132.439L322.7 130.689C322.7 130.689 324.64 131.779 326.95 131.779L326.93 131.789ZM332.46 118.989C332.46 114.399 330.27 112.999 327.5 112.999C324.44 112.999 322.66 115.219 322.66 118.959C322.66 122.699 324.69 125.139 327.81 125.139C330.28 125.139 332.46 123.609 332.46 118.989Z'
                    fill='currentcolor'
                />
                <path
                    d='M342.32 105.191H344.44V122.961C344.44 125.021 345.38 125.331 346.19 125.331C346.47 125.331 346.72 125.301 346.72 125.301V127.211C346.72 127.211 346.35 127.271 345.94 127.271C344.63 127.271 342.32 126.861 342.32 123.431V105.201V105.191Z'
                    fill='currentcolor'
                />
                <path
                    d='M359.96 111.059C364.55 111.059 368.26 114.559 368.26 119.209C368.26 123.859 364.55 127.509 359.96 127.509C355.37 127.509 351.66 123.949 351.66 119.209C351.66 114.469 355.37 111.059 359.96 111.059ZM359.96 125.609C363.33 125.609 366.08 122.859 366.08 119.209C366.08 115.559 363.33 112.999 359.96 112.999C356.59 112.999 353.84 115.649 353.84 119.209C353.84 122.769 356.59 125.609 359.96 125.609Z'
                    fill='currentcolor'
                />
                <path
                    d='M375.109 105.191H377.229V112.811C377.229 113.531 377.169 114.091 377.169 114.091H377.229C377.229 114.091 378.539 111.061 382.569 111.061C386.599 111.061 389.409 114.431 389.409 119.301C389.409 124.171 386.439 127.511 382.319 127.511C378.569 127.511 377.169 124.451 377.169 124.451H377.109C377.109 124.451 377.169 124.981 377.169 125.731V127.141H375.109V105.191ZM382.169 125.641C384.949 125.641 387.229 123.301 387.229 119.301C387.229 115.301 385.199 112.991 382.269 112.991C379.649 112.991 377.179 114.861 377.179 119.331C377.179 122.481 378.799 125.641 382.179 125.641H382.169Z'
                    fill='currentcolor'
                />
                <path
                    d='M404.61 117.429H405.48V117.019C405.48 113.989 403.82 112.959 401.58 112.959C398.99 112.959 397.15 114.459 397.15 114.459L396.15 112.839C396.15 112.839 398.21 111.059 401.74 111.059C405.52 111.059 407.61 113.149 407.61 117.089V127.139H405.58V125.419C405.58 124.639 405.64 124.109 405.64 124.109H405.58C405.58 124.109 404.27 127.509 400.34 127.509C397.72 127.509 395 125.979 395 122.859C395 117.549 401.9 117.429 404.62 117.429H404.61ZM400.68 125.699C403.68 125.699 405.49 122.579 405.49 119.859V119.169H404.58C402.11 119.169 397.18 119.259 397.18 122.729C397.18 124.199 398.34 125.699 400.68 125.699Z'
                    fill='currentcolor'
                />
                <path
                    d='M415.23 105.191H417.35V122.961C417.35 125.021 418.29 125.331 419.1 125.331C419.38 125.331 419.63 125.301 419.63 125.301V127.211C419.63 127.211 419.25 127.271 418.85 127.271C417.54 127.271 415.23 126.861 415.23 123.431V105.201V105.191Z'
                    fill='currentcolor'
                />
                <path
                    d='M424.91 121.391H429.66V122.281H427.79V127.261H426.76V122.281H424.9V121.391H424.91Z'
                    fill='currentcolor'
                />
                <path
                    d='M431.141 121.391H432.591L434.211 125.991H434.231L435.811 121.391H437.241V127.261H436.261V122.731H436.241L434.611 127.261H433.761L432.131 122.731H432.111V127.261H431.131V121.391H431.141Z'
                    fill='currentcolor'
                />
                <path
                    d='M419.63 50.7398V40.0898H376.74V90.9698H419.63V80.3198H387.39V70.8598H415.11V60.1998H387.39V50.7398H419.63Z'
                    fill='currentcolor'
                />
                <path
                    d='M356.251 90.9698H345.601V57.5898L328.491 75.5198L311.371 57.5898V90.9698H300.721V40.0898H309.391L328.491 60.0898L347.581 40.0898H356.251V90.9698Z'
                    fill='currentcolor'
                />
                <path
                    d='M217.76 90.9698H174.65V40.0898H185.3V80.3198H217.76V90.9698Z'
                    fill='currentcolor'
                />
                <path
                    d='M256.16 93.0998C242.38 93.0998 231.18 81.8898 231.18 68.1198V40.0898H241.83V68.1198C241.83 76.0198 248.26 82.4498 256.16 82.4498C264.06 82.4498 270.49 76.0198 270.49 68.1198V40.0898H281.14V68.1198C281.14 81.8998 269.93 93.0998 256.16 93.0998Z'
                    fill='currentcolor'
                />
                <path
                    d='M151.89 64.3216C153.66 61.8216 154.71 58.7716 154.71 55.4816C154.71 47.0016 147.81 40.1016 139.33 40.1016H104.49V90.9816H141.47C149.95 90.9816 156.85 84.0816 156.85 75.6016C156.85 71.1516 154.94 67.1416 151.9 64.3316L151.89 64.3216ZM115.13 50.7416H139.32C141.93 50.7416 144.05 52.8616 144.05 55.4716C144.05 58.0816 141.93 60.2016 139.32 60.2016H115.13V50.7416ZM141.45 80.3216H115.13V70.8616H141.45C144.06 70.8616 146.18 72.9816 146.18 75.5916C146.18 78.2016 144.06 80.3216 141.45 80.3216Z'
                    fill='currentcolor'
                />
                <path
                    d='M78.62 60.48C76.54 43.18 63.62 30.77 46.82 30.12C37.64 29.77 29.31 33.04 23.32 39.34C17.6 45.36 14.76 53.62 15.52 62.01C15.52 62.07 15.53 62.13 15.54 62.2C17.15 74.99 27.01 84.47 39.53 85.26C46.19 85.69 52.3 83.45 56.73 78.97C60.37 75.29 62.53 70.34 62.97 65H52.27C51.9 67.51 50.83 69.78 49.15 71.47C46.89 73.76 43.79 74.85 40.19 74.62C32.88 74.16 27.11 68.54 26.11 60.95C25.65 55.56 27.39 50.49 31.03 46.66C34.88 42.61 40.34 40.49 46.39 40.75C58.08 41.2 67.01 50.21 68.12 62.65C68.21 70.32 65.48 77.32 60.42 82.43C55.12 87.77 47.74 90.61 39.71 90.39C24.08 89.98 11.33 77.23 10.64 61.34V5.33C10.64 2.39 8.26 0 5.31 0C2.36 0 0 2.38 0 5.33V61.48C0 61.55 0 61.62 0 61.7C0.87 83.22 18.19 100.51 39.44 101.07C39.81 101.07 40.19 101.08 40.56 101.08C51.15 101.08 60.85 97.15 67.99 89.96C75.31 82.58 79.13 72.5 78.76 61.58C78.75 61.21 78.7 60.84 78.61 60.5L78.62 60.48Z'
                    fill='currentcolor'
                />
            </g>
            <defs>
                <clipPath id='clip0_1457_12653'>
                    <rect width='437.24' height='191.7' fill='white' />
                </clipPath>
            </defs>
        </svg>
    )
}

export default BlumeLogo
