import React from 'react'

const EuroInformationLogo = () => (
    <svg width='63' height='59' viewBox='0 0 63 59' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_1457_12790)'>
            <path
                d='M19 27.4023H21.4V28.2023H19.9V30.3023H21.2V31.0023H19.9V33.3023H21.4V34.0023H19V27.4023Z'
                fill='currentcolor'
            />
            <path
                d='M22.1996 27.4023H23.0996V32.1023C23.0996 32.6023 23.0996 32.9023 23.1996 33.1023C23.2996 33.3023 23.4996 33.3023 23.6996 33.3023C23.8996 33.3023 24.0996 33.2023 24.1996 33.1023C24.2996 33.0023 24.2996 32.7023 24.2996 32.2023V27.4023H25.1996V32.2023C25.1996 32.9023 25.0996 33.4023 24.8996 33.7023C24.5996 34.0023 24.1996 34.1023 23.6996 34.1023C23.0996 34.1023 22.6996 33.9023 22.3996 33.5023C22.1996 33.2023 22.0996 32.8023 22.0996 32.0023V27.4023H22.1996Z'
                fill='currentcolor'
            />
            <path
                d='M26.3008 27.4023H27.5008C28.0008 27.4023 28.3008 27.5023 28.6008 27.7023C28.9008 28.0023 29.0008 28.5023 29.0008 29.1023C29.0008 29.6023 28.9008 30.0023 28.8008 30.2023C28.6008 30.5023 28.4008 30.7023 28.1008 30.7023L29.3008 33.9023H28.4008L27.2008 30.7023V34.0023H26.3008V27.4023ZM27.1008 30.3023C27.4008 30.3023 27.7008 30.2023 27.8008 30.1023C27.9008 29.9023 28.0008 29.7023 28.0008 29.3023C28.0008 29.1023 28.0008 28.9023 28.0008 28.8023C28.0008 28.7023 27.9008 28.5023 27.9008 28.4023C27.8008 28.3023 27.8008 28.2023 27.7008 28.2023C27.6008 28.2023 27.5008 28.1023 27.3008 28.1023H27.2008V30.3023H27.1008Z'
                fill='currentcolor'
            />
            <path
                d='M33.1992 30.9008C33.1992 32.1008 33.0992 32.9008 32.7992 33.4008C32.4992 33.9008 32.0992 34.1008 31.4992 34.1008C30.8992 34.1008 30.3992 33.8008 30.0992 33.3008C29.7992 32.8008 29.6992 31.9008 29.6992 30.5008C29.6992 29.3008 29.7992 28.5008 30.0992 28.0008C30.3992 27.5008 30.7992 27.3008 31.3992 27.3008C31.9992 27.3008 32.3992 27.5008 32.5992 27.9008C32.7992 28.2008 32.8992 28.5008 32.9992 29.0008C33.0992 29.4008 33.1992 30.0008 33.1992 30.9008ZM32.1992 30.9008C32.1992 29.8008 32.0992 29.0008 31.9992 28.6008C31.8992 28.2008 31.6992 28.0008 31.3992 28.0008C31.0992 28.0008 30.8992 28.2008 30.7992 28.5008C30.6992 28.8008 30.5992 29.5008 30.5992 30.6008C30.5992 31.6008 30.6992 32.2008 30.7992 32.6008C30.8992 33.0008 31.0992 33.1008 31.3992 33.1008C31.6992 33.1008 31.8992 32.9008 31.9992 32.6008C32.1992 32.4008 32.1992 31.8008 32.1992 30.9008Z'
                fill='currentcolor'
            />
            <path d='M19 36.4023H19.9V43.0023H19V36.4023Z' fill='currentcolor' />
            <path
                d='M20.9008 36.4008H21.7008L22.6008 39.0008C22.9008 39.7008 23.1008 40.5008 23.3008 41.4008C23.2008 40.9008 23.2008 40.4008 23.1008 40.0008C23.1008 39.6008 23.0008 39.2008 23.0008 38.8008V36.3008H24.0008V43.0008H23.2008L22.2008 40.0008C22.1008 39.7008 22.0008 39.4008 21.9008 39.1008C21.8008 38.8008 21.7008 38.5008 21.7008 38.2008V38.1008C21.7008 38.2008 21.7008 38.2008 21.7008 38.2008V38.9008V39.7008C21.7008 39.7008 21.7008 39.8008 21.7008 39.9008C21.7008 40.0008 21.7008 40.0008 21.7008 40.1008V43.0008H20.8008V36.4008H20.9008Z'
                fill='currentcolor'
            />
            <path
                d='M25 36.4023H27.4V37.2023H25.9V39.3023H27.2V40.0023H25.9V43.0023H25V36.4023Z'
                fill='currentcolor'
            />
            <path
                d='M31.4004 39.9008C31.4004 41.1008 31.3004 41.9008 31.0004 42.4008C30.7004 42.9008 30.3004 43.1008 29.7004 43.1008C29.1004 43.1008 28.6004 42.8008 28.3004 42.3008C28.0004 41.8008 27.9004 40.9008 27.9004 39.5008C27.9004 38.3008 28.0004 37.5008 28.3004 37.0008C28.6004 36.5008 29.0004 36.3008 29.6004 36.3008C30.2004 36.3008 30.6004 36.5008 30.8004 36.9008C31.0004 37.2008 31.1004 37.5008 31.2004 38.0008C31.4004 38.4008 31.4004 39.0008 31.4004 39.9008ZM30.5004 39.9008C30.5004 38.8008 30.4004 38.0008 30.3004 37.6008C30.2004 37.2008 30.0004 37.0008 29.7004 37.0008C29.4004 37.0008 29.2004 37.2008 29.1004 37.5008C29.0004 37.8008 28.9004 38.5008 28.9004 39.6008C28.9004 40.6008 29.0004 41.2008 29.1004 41.6008C29.2004 42.0008 29.4004 42.1008 29.7004 42.1008C30.0004 42.1008 30.2004 41.9008 30.3004 41.6008C30.4004 41.4008 30.5004 40.8008 30.5004 39.9008Z'
                fill='currentcolor'
            />
            <path
                d='M32.3008 36.4023H33.5008C34.0008 36.4023 34.3008 36.5023 34.6008 36.7023C34.9008 37.0023 35.0008 37.5023 35.0008 38.1023C35.0008 38.6023 34.9008 39.0023 34.8008 39.2023C34.6008 39.5023 34.4008 39.7023 34.1008 39.7023L35.3008 42.9023H34.4008L33.2008 39.7023V43.0023H32.3008V36.4023ZM33.2008 39.3023C33.5008 39.3023 33.8008 39.2023 33.9008 39.1023C34.0008 38.9023 34.1008 38.7023 34.1008 38.3023C34.1008 38.1023 34.1008 37.9023 34.1008 37.8023C34.1008 37.7023 34.0008 37.5023 34.0008 37.4023C33.9008 37.3023 33.9008 37.2023 33.8008 37.2023C33.7008 37.2023 33.6008 37.1023 33.4008 37.1023H33.3008V39.3023H33.2008Z'
                fill='currentcolor'
            />
            <path
                d='M36.3004 36.4008H37.1004L37.7004 39.1008C37.8004 39.4008 37.8004 39.8008 37.9004 40.1008C38.0004 40.4008 38.0004 40.8008 38.1004 41.2008V41.1008C38.1004 41.1008 38.1004 41.1008 38.1004 41.0008L38.2004 40.2008L38.3004 39.5008L38.4004 39.0008L38.9004 36.3008H39.7004L40.3004 42.9008H39.4004L39.3004 40.7008C39.3004 40.6008 39.3004 40.5008 39.3004 40.4008C39.3004 40.3008 39.3004 40.2008 39.3004 40.2008V39.3008V38.5008C39.3004 38.5008 39.3004 38.5008 39.3004 38.4008V38.5008C39.3004 38.7008 39.3004 38.8008 39.2004 38.9008C39.1004 39.0008 39.2004 39.1008 39.2004 39.2008L39.1004 39.8008V40.0008L38.5004 42.9008H37.9004L37.4004 40.4008C37.3004 40.1008 37.3004 39.7008 37.2004 39.4008C37.1004 39.1008 37.1004 38.7008 37.0004 38.3008L36.7004 42.9008H35.9004L36.3004 36.4008Z'
                fill='currentcolor'
            />
            <path
                d='M41.9004 36.4023H42.6004L44.0004 43.0023H43.1004L42.9004 41.7023H41.6004L41.3004 43.0023H40.4004L41.9004 36.4023ZM42.8004 41.0023L42.6004 39.9023C42.5004 39.2023 42.4004 38.4023 42.3004 37.6023C42.3004 38.0023 42.2004 38.4023 42.1004 38.8023C42.0004 39.2023 42.0004 39.6023 41.9004 40.1023L41.7004 40.9023H42.8004V41.0023Z'
                fill='currentcolor'
            />
            <path
                d='M43.9004 36.4023H46.8004V37.2023H45.8004V43.0023H44.9004V37.2023H43.9004V36.4023Z'
                fill='currentcolor'
            />
            <path d='M47.3008 36.4023H48.2008V43.0023H47.3008V36.4023Z' fill='currentcolor' />
            <path
                d='M52.5 39.9008C52.5 41.1008 52.4 41.9008 52.1 42.4008C51.8 42.9008 51.4 43.1008 50.8 43.1008C50.2 43.1008 49.7 42.8008 49.4 42.3008C49.1 41.8008 49 40.9008 49 39.5008C49 38.3008 49.1 37.5008 49.4 37.0008C49.7 36.5008 50.1 36.3008 50.7 36.3008C51.3 36.3008 51.7 36.5008 51.9 36.9008C52.1 37.2008 52.2 37.5008 52.3 38.0008C52.5 38.4008 52.5 39.0008 52.5 39.9008ZM51.6 39.9008C51.6 38.8008 51.5 38.0008 51.4 37.6008C51.3 37.2008 51.1 37.0008 50.8 37.0008C50.5 37.0008 50.3 37.2008 50.2 37.5008C50.1 37.8008 50 38.5008 50 39.6008C50 40.6008 50.1 41.2008 50.2 41.6008C50.3 42.0008 50.5 42.1008 50.8 42.1008C51.1 42.1008 51.3 41.9008 51.4 41.6008C51.5 41.4008 51.6 40.8008 51.6 39.9008Z'
                fill='currentcolor'
            />
            <path
                d='M53.3992 36.4008H54.1992L55.0992 39.0008C55.3992 39.7008 55.5992 40.5008 55.7992 41.4008C55.6992 40.9008 55.6992 40.4008 55.5992 40.0008C55.5992 39.6008 55.4992 39.2008 55.4992 38.8008V36.3008H56.3992V43.0008H55.5992L54.5992 40.0008C54.4992 39.7008 54.3992 39.4008 54.2992 39.1008C54.1992 38.8008 54.0992 38.5008 54.0992 38.2008V38.1008C54.0992 38.2008 54.0992 38.2008 54.0992 38.2008V38.9008V39.7008C54.0992 39.7008 54.0992 39.8008 54.0992 39.9008C54.0992 40.0008 54.0992 40.0008 54.0992 40.1008V43.0008H53.1992V36.4008H53.3992Z'
                fill='currentcolor'
            />
            <path
                d='M18.5 45.4023H21.4V46.2023H20.4V52.0023H19.5V46.2023H18.5V45.4023Z'
                fill='currentcolor'
            />
            <path
                d='M21.9004 45.4023H24.3004V46.2023H22.8004V48.3023H24.1004V49.0023H22.8004V51.3023H24.3004V52.0023H21.9004V45.4023Z'
                fill='currentcolor'
            />
            <path
                d='M25.0996 45.4023H25.9996V51.3023H27.4996V52.0023H25.1996V45.4023H25.0996Z'
                fill='currentcolor'
            />
            <path
                d='M28 45.4023H30.4V46.2023H28.9V48.3023H30.2V49.0023H28.9V51.3023H30.4V52.0023H28V45.4023Z'
                fill='currentcolor'
            />
            <path
                d='M33.7 45.5008V46.3008C33.5 46.2008 33.3 46.1008 33.1 46.1008C32.9 46.1008 32.7 46.2008 32.6 46.3008C32.5 46.4008 32.3 46.6008 32.2 46.8008C32.1 47.0008 32 47.3008 32 47.6008C32 47.9008 31.9 48.3008 31.9 48.8008C31.9 50.4008 32.3 51.2008 33.2 51.2008C33.3 51.2008 33.5 51.2008 33.7 51.1008V51.9008C33.5 52.0008 33.2 52.1008 33 52.1008C31.7 52.1008 31 51.0008 31 48.8008C31 47.6008 31.2 46.7008 31.5 46.2008C31.8 45.6008 32.3 45.3008 33 45.3008C33.3 45.3008 33.5 45.4008 33.7 45.5008Z'
                fill='currentcolor'
            />
            <path
                d='M37.6992 48.9008C37.6992 50.1008 37.5992 50.9008 37.2992 51.4008C36.9992 51.9008 36.5992 52.1008 35.9992 52.1008C35.3992 52.1008 34.8992 51.8008 34.5992 51.3008C34.2992 50.8008 34.1992 49.9008 34.1992 48.5008C34.1992 47.3008 34.2992 46.5008 34.5992 46.0008C34.8992 45.5008 35.2992 45.3008 35.8992 45.3008C36.4992 45.3008 36.8992 45.5008 37.0992 45.9008C37.2992 46.2008 37.3992 46.5008 37.4992 47.0008C37.6992 47.4008 37.6992 48.0008 37.6992 48.9008ZM36.7992 48.9008C36.7992 47.8008 36.6992 47.0008 36.5992 46.6008C36.4992 46.2008 36.2992 46.0008 35.9992 46.0008C35.6992 46.0008 35.4992 46.2008 35.3992 46.5008C35.2992 46.8008 35.1992 47.5008 35.1992 48.6008C35.1992 49.6008 35.2992 50.2008 35.3992 50.6008C35.4992 51.0008 35.6992 51.1008 35.9992 51.1008C36.2992 51.1008 36.4992 50.9008 36.5992 50.6008C36.7992 50.4008 36.7992 49.8008 36.7992 48.9008Z'
                fill='currentcolor'
            />
            <path
                d='M38.9004 45.4008H39.7004L40.3004 48.1008C40.4004 48.4008 40.4004 48.8008 40.5004 49.1008C40.6004 49.4008 40.6004 49.8008 40.7004 50.2008V50.1008C40.7004 50.1008 40.7004 50.1008 40.7004 50.0008L40.8004 49.2008L40.9004 48.5008L41.0004 48.0008L41.5004 45.3008H42.3004L42.9004 51.9008H42.0004L41.8004 49.7008C41.8004 49.6008 41.8004 49.5008 41.8004 49.4008C41.8004 49.3008 41.8004 49.2008 41.8004 49.2008V48.3008V47.5008C41.8004 47.5008 41.8004 47.5008 41.8004 47.4008V47.5008C41.8004 47.7008 41.8004 47.8008 41.7004 47.9008C41.6004 48.0008 41.7004 48.1008 41.7004 48.2008L41.6004 48.8008V49.0008L41.0004 52.0008H40.4004L39.9004 49.5008C39.8004 49.2008 39.8004 48.8008 39.7004 48.5008C39.6004 48.2008 39.6004 47.8008 39.5004 47.4008L39.2004 52.0008H38.4004L38.9004 45.4008Z'
                fill='currentcolor'
            />
            <path
                d='M8.50078 12.9008L4.40078 19.0008L0.300781 25.0008V12.9008V0.800781L4.40078 6.90078L8.50078 12.9008Z'
                fill='currentcolor'
            />
            <path
                d='M17.7 12.9008L13.6 19.0008L9.5 25.0008V12.9008V0.800781L13.6 6.90078L17.7 12.9008Z'
                fill='currentcolor'
            />
            <path
                d='M26.8992 12.9008L22.7992 19.0008L18.6992 25.0008V12.9008V0.800781L22.7992 6.90078L26.8992 12.9008Z'
                fill='currentcolor'
            />
            <path
                d='M26.5992 8.19922L27.2992 9.19922H61.6992V57.1992H13.6992V23.2992L13.5992 23.4992L12.6992 24.7992V58.1992H62.6992V8.19922H26.5992Z'
                fill='currentcolor'
            />
        </g>
        <defs>
            <clipPath id='clip0_1457_12790'>
                <rect width='63' height='59' fill='white' />
            </clipPath>
        </defs>
    </svg>
)

export default EuroInformationLogo
