import React from 'react'

const CityFibreLogo = () => {
    return (
        <svg
            width='327'
            height='100'
            viewBox='0 0 327 100'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_1457_12715)'>
                <path
                    d='M140.303 57.0706C139.179 60.9496 135.289 63.5386 130.541 63.5386C123.818 63.5386 119.472 58.7316 119.472 51.2796C119.472 43.8276 123.818 38.9586 130.541 38.9586C135.392 38.9586 139.282 41.5006 140.306 45.3086H149.348C148.022 36.9206 140.557 31.3086 130.66 31.3086C118.852 31.3086 110.922 39.2856 110.922 51.1576C110.922 63.1026 118.852 71.1286 130.66 71.1286C140.685 71.1286 148.154 65.4936 149.352 57.0706H140.303Z'
                    fill='currentcolor'
                />
                <path
                    d='M208.687 43.6443H200.529L193.748 62.0423L187.208 43.6443H175.284V36.5703H167.575V43.6443H151.514V70.3433H159.224V50.0263H167.575V70.3433H175.284V50.0263H181.513L190.218 71.4683L189.768 72.7793C188.965 74.8333 188.059 77.1443 183.511 77.1443C182.293 77.1343 181.079 77.0133 179.884 76.7823L179.833 83.1023C181.507 83.4843 183.217 83.6873 184.934 83.7083C192.964 83.7083 195.235 78.0033 196.459 74.9393L208.687 43.6443Z'
                    fill='currentcolor'
                />
                <path
                    d='M155.364 41.3672C158.053 41.3672 160.233 39.2402 160.233 36.6172C160.233 33.9932 158.053 31.8672 155.364 31.8672C152.674 31.8672 150.494 33.9932 150.494 36.6172C150.494 39.2402 152.674 41.3672 155.364 41.3672Z'
                    fill='currentcolor'
                />
                <path
                    d='M242.221 41.3672C244.911 41.3672 247.091 39.2402 247.091 36.6172C247.091 33.9932 244.911 31.8672 242.221 31.8672C239.532 31.8672 237.352 33.9932 237.352 36.6172C237.352 39.2402 239.532 41.3672 242.221 41.3672Z'
                    fill='currentcolor'
                />
                <path
                    d='M232.632 48.1182H218.65V39.5632H235.24V32.1562H210.574V70.3423H218.65V55.2232H232.577L232.632 48.1182Z'
                    fill='currentcolor'
                />
                <path d='M246.081 43.6445H238.359V70.3435H246.081V43.6445Z' fill='currentcolor' />
                <path
                    d='M266.697 42.6804C263.354 42.6804 260.546 43.6994 258.789 45.5644L258.291 46.0874V32.3984H250.582V70.3434H256.92L257.459 66.6224L257.877 67.1964C259.656 69.6574 262.959 71.1284 266.71 71.1284C274.439 71.1284 279.636 65.4364 279.636 56.9654C279.607 48.5514 274.291 42.6804 266.697 42.6804ZM265.091 64.0834C261.054 64.0834 258.346 61.1734 258.346 56.8434C258.346 52.5834 261.057 49.7244 265.091 49.7244C269.055 49.7244 271.718 52.6094 271.718 56.9044C271.718 61.1994 269.046 64.0834 265.091 64.0834Z'
                    fill='currentcolor'
                />
                <path
                    d='M299.368 50.3664V43.5914C298.64 43.4664 297.903 43.4024 297.164 43.3984C294.084 43.3984 291.874 44.7574 291.1 47.1294L290.718 48.3024L290.043 43.6394H282.996V70.3384H290.705V57.8734C290.705 55.3894 291.431 53.4664 292.857 52.1614C294.37 50.7804 296.57 50.1744 299.368 50.3664Z'
                    fill='currentcolor'
                />
                <path
                    d='M319 62.1477C318.161 63.8337 316.07 64.8697 313.481 64.8697C309.855 64.8697 307.333 62.6257 307.211 59.2727V58.9747H326.237C326.45 57.8537 326.551 56.7147 326.539 55.5747C326.539 47.9817 321.104 42.6797 313.318 42.6797C305.127 42.6797 299.621 48.3947 299.621 56.9047C299.621 65.4137 305.197 71.1287 313.494 71.1287C320.105 71.1287 325.292 67.5357 326.532 62.1547L319 62.1477ZM307.33 53.7407C307.513 50.8567 309.742 48.9977 313.009 48.9977C316.276 48.9977 318.505 50.9207 318.627 53.7477V54.0457H307.295L307.33 53.7407Z'
                    fill='currentcolor'
                />
                <path
                    d='M50.1091 0C40.1991 0 30.5111 2.932 22.2701 8.427C14.0301 13.921 7.60708 21.73 3.81408 30.866C0.0220764 40.002 -0.970923 50.056 0.963077 59.755C2.89608 69.454 7.66908 78.363 14.6771 85.355C21.6851 92.348 30.6131 97.11 40.3341 99.039C50.0541 100.968 60.1291 99.978 69.2861 96.194C78.4421 92.41 86.2681 86.001 91.7741 77.778C97.2801 69.556 100.219 59.889 100.219 50C100.219 36.739 94.9391 24.022 85.5421 14.645C76.1451 5.268 63.3991 0 50.1091 0ZM77.6861 63.513C75.2281 63.512 72.8251 62.784 70.7821 61.421C68.7391 60.058 67.1461 58.121 66.2061 55.856C65.2661 53.59 65.0201 51.096 65.5001 48.691C65.9801 46.286 67.1641 44.076 68.9021 42.343C70.6401 40.609 72.8551 39.428 75.2661 38.95C77.6761 38.471 80.1751 38.717 82.4461 39.656C84.7161 40.594 86.6571 42.184 88.0221 44.223C89.3881 46.262 90.1171 48.66 90.1171 51.112C90.1181 52.741 89.7971 54.355 89.1721 55.86C88.5481 57.366 87.6321 58.734 86.4781 59.886C85.3231 61.038 83.9531 61.952 82.4441 62.576C80.9351 63.199 79.3191 63.52 77.6861 63.519V63.513Z'
                    fill='currentcolor'
                />
            </g>
            <defs>
                <clipPath id='clip0_1457_12715'>
                    <rect width='327' height='100' fill='white' />
                </clipPath>
            </defs>
        </svg>
    )
}

export default CityFibreLogo
