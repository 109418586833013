import React from 'react'

const RedPanda = () => (
    <svg
        width='131'
        height='30'
        viewBox='0 0 131 30'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <mask id='mask0_8628_10913' maskUnits='userSpaceOnUse' x='3' y='4' width='124' height='26'>
            <path d='M126.445 4.97412H3.646V29.8321H126.445V4.97412Z' fill='white' />
        </mask>
        <g mask='url(#mask0_8628_10913)'>
            <path
                d='M27.5288 9.51793C26.5743 9.49701 25.6268 9.68485 24.752 10.0684C23.8772 10.4519 23.0962 11.0219 22.4633 11.7388C21.1305 13.2091 20.4531 15.1508 20.4531 17.5238C20.4531 19.8966 21.152 21.7236 22.5313 23.107C23.9105 24.4904 25.7073 25.2163 27.8751 25.2163C29.1981 25.2473 30.5085 24.9523 31.6912 24.357C32.7675 23.7863 33.5096 23.1969 33.9024 22.6045L33.9178 22.5858L32.2015 19.3568V19.3383H31.9541V19.3568C31.5389 20.0201 30.9675 20.5708 30.2904 20.9605C29.57 21.4167 28.7363 21.6607 27.8844 21.6647C27.0053 21.6832 26.154 21.3558 25.5125 20.7527C25.2019 20.4681 24.9546 20.1209 24.7867 19.734C24.6189 19.347 24.5342 18.9289 24.5383 18.507H34.6384L34.6662 17.3997C34.6662 15.0826 33.9797 13.1718 32.6283 11.7171C31.984 11.0068 31.1955 10.4429 30.316 10.0636C29.4364 9.68426 28.486 9.49818 27.5288 9.51793ZM30.6831 15.7463H24.5909C24.7501 15.0644 25.0838 14.4358 25.5589 13.9225C25.822 13.6373 26.144 13.4133 26.5024 13.2659C26.8608 13.1186 27.247 13.0515 27.6339 13.0695C28.0314 13.0556 28.4273 13.1273 28.7948 13.2798C29.1624 13.4323 29.493 13.662 29.7646 13.9535C30.2512 14.4393 30.5726 15.0667 30.6831 15.7463Z'
                fill='currentColor'
            />
            <path
                d='M46.7732 11.382C46.3477 10.7916 45.7735 10.325 45.1095 10.0296C44.4001 9.69213 43.6247 9.51738 42.8396 9.51788C41.8978 9.49146 40.9625 9.68113 40.105 10.0725C39.2475 10.4638 38.4904 11.0465 37.8916 11.776C36.6547 13.2741 36.0176 15.188 36.0176 17.443C36.0176 19.698 36.6206 21.584 37.8112 23.0263C39.0018 24.4686 40.6656 25.1975 42.7592 25.1975C43.6997 25.2219 44.6313 25.0085 45.4682 24.5772C46.1951 24.192 46.8043 23.6164 47.2309 22.9115H47.2587L47.6237 24.8564V24.8905H50.5986V4.97681H46.7732V11.382ZM45.9166 14.3133C46.5963 15.2109 46.9459 16.3165 46.9062 17.443C46.9508 18.5427 46.5947 19.6211 45.9042 20.4765C45.5818 20.86 45.177 21.1654 44.7201 21.3695C44.2632 21.5736 43.7662 21.6713 43.2663 21.6553C42.7753 21.679 42.2859 21.582 41.8407 21.3728C41.3956 21.1636 41.0081 20.8485 40.712 20.4549C40.0921 19.5766 39.777 18.5184 39.8152 17.443C39.8062 16.3621 40.1302 15.3049 40.7429 14.4156C41.0134 13.9926 41.388 13.6466 41.8305 13.4108C42.273 13.175 42.7685 13.0575 43.2694 13.0695C43.7771 13.0535 44.2813 13.1579 44.7412 13.374C45.2012 13.5901 45.6038 13.9119 45.9166 14.3133Z'
                fill='currentColor'
            />
            <path
                d='M60.6704 9.51775C59.6794 9.48608 58.6977 9.71833 57.8253 10.1908C57.134 10.5679 56.5611 11.1307 56.1708 11.8162H56.146L55.908 9.83716H52.7969V29.8315H56.6037V23.2277H56.6501C57.0725 23.8341 57.6433 24.3212 58.3077 24.6422C59.0296 25.0152 59.8304 25.2088 60.6425 25.2067C61.5319 25.2391 62.4162 25.0592 63.2227 24.6817C64.0292 24.3042 64.7347 23.7399 65.2812 23.0354C66.4193 21.5931 66.9945 19.7134 66.9945 17.4522C66.9945 15.1909 66.4069 13.2833 65.241 11.7882C64.7147 11.0718 64.0253 10.4919 63.2302 10.097C62.4351 9.702 61.5575 9.50341 60.6704 9.51775ZM63.1876 17.4428C63.2265 18.5269 62.8996 19.5924 62.2599 20.4671C61.9841 20.8428 61.6226 21.1468 61.2056 21.3535C60.7886 21.5602 60.3283 21.6637 59.8632 21.6551C59.3925 21.6712 58.9245 21.5782 58.4955 21.3835C58.0665 21.1887 57.6879 20.8975 57.3892 20.5322C56.7707 19.7847 56.4615 18.7456 56.4615 17.4304C56.4615 16.1152 56.7707 15.0141 57.3892 14.2325C57.6785 13.8531 58.054 13.5485 58.4842 13.3441C58.9145 13.1396 59.3873 13.0411 59.8632 13.0568C60.3408 13.0462 60.8133 13.1568 61.2367 13.3787C61.66 13.6005 62.0206 13.9262 62.2846 14.3255C62.9063 15.2429 63.2225 16.3342 63.1876 17.4428Z'
                fill='currentColor'
            />
            <path
                d='M78.9611 11.038C77.823 10.0299 76.3386 9.51806 74.5512 9.51806C73.3166 9.50197 72.0957 9.77939 70.9886 10.3276C69.9287 10.8615 69.0187 11.6526 68.3415 12.6291V12.6509L70.197 15.7527V15.7713H70.4381V15.7496C70.8446 14.9321 71.4458 14.2279 72.1885 13.6993C72.8598 13.2141 73.665 12.9507 74.4924 12.9456C75.1016 12.9057 75.7086 13.0516 76.2335 13.3643C76.4138 13.4821 76.5616 13.6436 76.6632 13.834C76.7648 14.0242 76.817 14.2371 76.8148 14.4531C76.8148 14.8501 76.5798 15.1478 76.0943 15.365C75.0177 15.7492 73.9122 16.0469 72.7885 16.2552C71.1246 16.6088 69.9156 17.1857 69.1919 17.986C68.453 18.8153 68.0619 19.8987 68.1002 21.0103C68.1002 22.3161 68.4991 23.3521 69.2908 24.0966C70.0825 24.8411 71.2576 25.2163 72.7977 25.2163C74.8481 25.2163 76.3201 24.444 77.1766 22.9179H77.2045L77.588 24.8968H80.6496V15.3309C80.665 13.4915 80.0867 12.0491 78.9611 11.038ZM75.8686 21.0754C75.1667 21.5602 74.33 21.81 73.4781 21.7888C72.4328 21.7888 71.9318 21.4166 71.9318 20.6536C71.9312 20.4757 71.9701 20.3001 72.0455 20.1391C72.1209 19.9781 72.231 19.8361 72.3679 19.7231C72.8485 19.3933 73.3952 19.1731 73.9697 19.0778C75.6768 18.6932 76.6231 18.2993 76.8643 17.8712V19.4221C76.8551 20.0425 76.5242 20.6071 75.8686 21.0754Z'
                fill='currentColor'
            />
            <path
                d='M90.5343 9.51768C89.6484 9.50868 88.7743 9.71626 87.9859 10.1225C87.2284 10.5118 86.5884 11.0964 86.1309 11.8161H86.1059L85.8651 9.83718H82.7661V24.8872H86.5759V17.2815C86.5301 16.1993 86.8409 15.1321 87.4601 14.2448C87.7476 13.8628 88.1226 13.5563 88.5534 13.3514C88.9843 13.1467 89.4584 13.0498 89.9343 13.0693C91.5893 13.0693 92.4268 14.0463 92.4268 15.9726V24.8872H96.2343V14.7691C96.2559 14.0733 96.1368 13.3804 95.8826 12.7325C95.6293 12.0846 95.2468 11.4953 94.7584 11.0003C93.8034 10.0171 92.3651 9.51768 90.5343 9.51768Z'
                fill='currentColor'
            />
            <path
                d='M108.412 11.382C107.986 10.7916 107.412 10.325 106.748 10.0296C106.038 9.69246 105.263 9.51763 104.478 9.51788C103.536 9.4898 102.6 9.67871 101.742 10.0702C100.885 10.4617 100.128 11.0452 99.5299 11.776C98.2933 13.2741 97.6558 15.188 97.6558 17.443C97.6558 19.698 98.2749 21.584 99.4499 23.0263C100.625 24.4686 102.304 25.1975 104.397 25.1975C105.338 25.2219 106.27 25.0085 107.107 24.5772C107.833 24.1911 108.442 23.6157 108.869 22.9115H108.894L109.262 24.8564V24.8905H112.237V4.97681H108.412V11.382ZM107.552 14.3133C108.234 15.2099 108.585 16.316 108.545 17.443C108.59 18.5433 108.232 19.6221 107.54 20.4765C107.217 20.86 106.813 21.1653 106.357 21.3695C105.901 21.5735 105.404 21.6713 104.905 21.6553C104.414 21.679 103.924 21.582 103.479 21.3728C103.034 21.1636 102.647 20.8485 102.351 20.4549C101.729 19.577 101.413 18.5188 101.451 17.443C101.442 16.3621 101.766 15.305 102.378 14.4156C102.648 13.9924 103.023 13.6462 103.466 13.4104C103.908 13.1746 104.404 13.0572 104.905 13.0695C105.412 13.053 105.917 13.1571 106.377 13.3733C106.837 13.5895 107.24 13.9115 107.552 14.3133Z'
                fill='currentColor'
            />
            <path
                d='M124.728 11.0379C123.587 10.0297 122.106 9.518 120.318 9.518C119.088 9.5035 117.872 9.78091 116.768 10.3276C115.707 10.8599 114.797 11.6514 114.121 12.6291L114.106 12.6508L115.961 15.7527V15.7712H116.199V15.7495C116.605 14.9323 117.205 14.228 117.947 13.6992C118.619 13.214 119.425 12.9506 120.253 12.9455C120.862 12.906 121.47 13.052 121.995 13.3642C122.174 13.4827 122.322 13.6443 122.422 13.8346C122.523 14.0248 122.575 14.2374 122.573 14.453C122.573 14.85 122.338 15.1477 121.856 15.3649C120.779 15.7492 119.673 16.0468 118.55 16.2552C116.886 16.6087 115.673 17.1857 114.95 17.9859C114.213 18.8167 113.822 19.8992 113.858 21.0102C113.858 22.3161 114.26 23.3521 115.049 24.0965C115.837 24.841 117.019 25.2162 118.556 25.2162C120.609 25.2162 122.082 24.4439 122.937 22.9178H122.966L123.349 24.8967H126.411V15.3308C126.445 13.4914 125.867 12.0491 124.728 11.0379ZM121.636 21.0753C120.934 21.5597 120.097 21.8094 119.246 21.7887C118.2 21.7887 117.699 21.4166 117.699 20.6535C117.697 20.4756 117.736 20.2995 117.812 20.1385C117.887 19.9774 117.997 19.8354 118.135 19.723C118.615 19.3922 119.162 19.1718 119.737 19.0777C121.441 18.6932 122.391 18.2992 122.632 17.8712V19.4221C122.635 20.0424 122.301 20.607 121.636 21.0753Z'
                fill='currentColor'
            />
            <path
                d='M16.2814 18.9536C15.9929 18.4283 15.7327 17.8878 15.502 17.3345C16.3592 16.9596 17.1315 16.4141 17.772 15.7308C18.774 14.7486 19.2749 13.3611 19.2749 11.5682C19.2749 9.48998 18.5966 7.87497 17.24 6.72316C15.8747 5.56886 14.136 4.95447 12.3509 4.99546H3.64551V24.8471H7.88221V17.7129H9.49337L10.4768 17.6881C10.8417 18.5783 11.655 20.5356 12.1282 21.5777L16.2814 18.9536ZM7.88221 8.95648H11.7818C13.7404 8.95648 14.7197 9.74948 14.7197 11.3356C14.7197 13.0395 13.627 13.8904 11.4416 13.8884H7.88221V8.95648Z'
                fill='currentColor'
            />
            <path
                d='M20.6595 23.4204L19.5494 22.6326L14.957 25.6165L16.5651 26.2431L19.3669 26.2369L21.3986 24.7852L20.6595 23.4204Z'
                fill='currentColor'
            />
            <path
                d='M16.9859 20.1046L12.7275 22.7939L13.9244 24.6736L18.4549 21.8106L16.9859 20.1046Z'
                fill='currentColor'
            />
        </g>
    </svg>
)

export default RedPanda
