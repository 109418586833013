import React from 'react'

const MarineLogo = () => (
    <svg
        width='377'
        height='67'
        viewBox='0 0 377 67'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_1422_12668)'>
            <path
                d='M114.129 64.0011V42.1911L105.589 58.9111H101.239L92.4891 42.1911V64.0011H86.5391V28.3711H92.1291L103.459 50.9411L114.589 28.3711H119.999V64.0011H114.129Z'
                fill='currentcolor'
            />
            <path
                d='M141.57 64.0009V61.8109C137.79 63.6209 135.46 64.2409 133.39 64.2409C128.21 64.2409 125.16 61.0409 125.16 56.2409C125.16 50.6009 131.42 48.4809 134.68 48.4809H141.57V47.0809C141.57 44.0809 138.62 43.0809 135.72 43.0809C133.415 43.0096 131.171 43.8257 129.45 45.3609L126.5 41.5809C129.082 39.297 132.424 38.0595 135.87 38.1109C142.55 38.1109 147.05 41.4809 147.05 47.8509V64.0009H141.57ZM141.57 53.0709H135.57C134.12 53.0709 130.81 53.3809 130.81 56.2809C130.81 58.2009 132.36 59.3409 134.59 59.3409C137.083 59.1689 139.491 58.3672 141.59 57.0109L141.57 53.0709Z'
                fill='currentcolor'
            />
            <path
                d='M167.659 44.779C166.637 43.8519 165.309 43.3354 163.929 43.329C162.529 43.329 160.199 44.729 158.179 46.229V63.999H152.699V38.359H158.179V40.689C161.699 38.569 162.739 38.099 164.549 38.099C166.786 38.0602 168.953 38.8756 170.609 40.379L167.659 44.779Z'
                fill='currentcolor'
            />
            <path
                d='M175.84 34.2716C175.218 34.2775 174.609 34.0982 174.089 33.7566C173.57 33.415 173.164 32.9264 172.924 32.3533C172.683 31.7802 172.619 31.1484 172.738 30.5384C172.858 29.9285 173.157 29.368 173.596 28.9285C174.036 28.4889 174.596 28.1902 175.206 28.0704C175.816 27.9506 176.448 28.0151 177.021 28.2557C177.594 28.4963 178.083 28.9022 178.425 29.4215C178.766 29.9407 178.945 30.55 178.94 31.1716C178.941 31.579 178.862 31.9828 178.706 32.3595C178.551 32.7362 178.323 33.0784 178.035 33.3666C177.746 33.6547 177.404 33.883 177.027 34.0383C176.651 34.1936 176.247 34.2729 175.84 34.2716ZM173.09 64.0016V38.3616H178.58V64.0016H173.09Z'
                fill='currentcolor'
            />
            <path
                d='M206.688 47.4716V64.0016H201.148V48.0016C201.148 44.5316 199.438 43.3416 197.218 43.4016C195.408 43.4016 193.018 44.5916 190.378 46.1916V64.0016H184.898V38.3616H190.378V40.6916C194.378 38.4716 196.028 38.1016 197.838 38.1016C202.998 38.1016 206.688 41.8316 206.688 47.4716Z'
                fill='currentcolor'
            />
            <path
                d='M216.63 53.0602V54.5602C216.63 58.0302 219.69 59.2802 222.22 59.2802C224.478 59.2001 226.638 58.3376 228.33 56.8402L231.44 60.3602C230.278 61.6559 228.845 62.6804 227.243 63.3612C225.641 64.0421 223.909 64.3626 222.17 64.3002C216.99 64.3002 211.09 61.3002 211.09 55.0802V47.5202C211.09 41.3102 216.89 38.1602 222.01 38.1602C227.29 38.1602 232.94 41.2102 232.94 47.4202V53.0602H216.63ZM222 43.1802C219.73 43.1802 216.62 44.5202 216.62 47.6302V48.9202H227.34L227.39 48.0902C227.55 44.5202 224.5 43.1802 222 43.1802Z'
                fill='currentcolor'
            />
            <path
                d='M251.262 33.7611V64.0011H245.312V33.7611H235.422V28.3711H261.102V33.7611H251.262Z'
                fill='currentcolor'
            />
            <path
                d='M279.839 44.7792C278.818 43.8513 277.489 43.3347 276.109 43.3292C274.719 43.3292 272.389 44.7292 270.369 46.2292V63.9992H264.879V38.3592H270.369V40.6892C273.889 38.5692 274.919 38.0992 276.729 38.0992C278.966 38.0574 281.134 38.8732 282.789 40.3792L279.839 44.7792Z'
                fill='currentcolor'
            />
            <path
                d='M300 64.0009V61.8109C296.22 63.6209 293.89 64.2409 291.82 64.2409C286.64 64.2409 283.59 61.0409 283.59 56.2409C283.59 50.6009 289.85 48.4809 293.11 48.4809H300V47.0809C300 44.0809 297 43.0809 294.15 43.0809C291.845 43.0095 289.601 43.8257 287.88 45.3609L284.93 41.5809C287.512 39.2978 290.853 38.0604 294.3 38.1109C300.98 38.1109 305.48 41.4809 305.48 47.8509V64.0009H300ZM300 53.0709H294C292.55 53.0709 289.24 53.3809 289.24 56.2809C289.24 58.2009 290.79 59.3409 293.02 59.3409C295.507 59.1625 297.908 58.3575 300 57.0009V53.0709Z'
                fill='currentcolor'
            />
            <path
                d='M317.891 43.1817V64.0017H312.401V43.1817H309.191V38.3617H312.401V36.8117C312.401 31.0117 316.601 28.0117 321.151 28.0117H324.211V33.0117H322.001C319.571 33.0117 317.911 34.4617 317.911 37.5617V38.3917H324.231V43.2117L317.891 43.1817Z'
                fill='currentcolor'
            />
            <path
                d='M334.559 43.1817V64.0017H329.069V43.1817H325.859V38.3617H329.069V36.8117C329.069 31.0117 333.269 28.0117 337.819 28.0117H340.879V33.0117H338.649C336.219 33.0117 334.559 34.4617 334.559 37.5617V38.3917H340.879V43.2117L334.559 43.1817Z'
                fill='currentcolor'
            />
            <path
                d='M348 34.2716C347.378 34.2775 346.769 34.0982 346.25 33.7566C345.73 33.415 345.324 32.9264 345.084 32.3533C344.843 31.7802 344.779 31.1484 344.899 30.5384C345.018 29.9285 345.317 29.368 345.757 28.9285C346.196 28.4889 346.757 28.1902 347.367 28.0704C347.976 27.9506 348.608 28.0151 349.181 28.2557C349.755 28.4963 350.243 28.9022 350.585 29.4215C350.926 29.9407 351.106 30.55 351.1 31.1716C351.101 31.579 351.022 31.9828 350.866 32.3595C350.711 32.7362 350.483 33.0784 350.195 33.3666C349.907 33.6547 349.564 33.883 349.188 34.0383C348.811 34.1936 348.407 34.2729 348 34.2716ZM345.23 64.0016V38.3616H350.71V64.0016H345.23Z'
                fill='currentcolor'
            />
            <path
                d='M376.851 41.7315L373.591 45.7315C372.872 44.9354 371.995 44.2989 371.015 43.8631C370.034 43.4273 368.974 43.2019 367.901 43.2015C365.411 43.2015 362.511 44.5915 362.511 48.4815V53.9615C362.511 57.8515 365.411 59.2415 367.901 59.2415C368.894 59.2427 369.877 59.0451 370.793 58.6604C371.708 58.2757 372.537 57.7117 373.231 57.0015L376.701 60.7215C375.572 61.9081 374.204 62.841 372.686 63.4582C371.169 64.0754 369.538 64.3629 367.901 64.3015C362.671 64.3015 357.031 61.2415 357.031 55.1315V47.3215C357.031 41.3215 362.771 38.1015 367.901 38.1015C369.562 38.0596 371.214 38.3603 372.754 38.9848C374.294 39.6093 375.689 40.5443 376.851 41.7315Z'
                fill='currentcolor'
            />
            <path
                d='M29.1795 0L27.7595 4.79L24.0195 14.51H49.6695L45.6795 4.79L44.1595 0'
                fill='currentcolor'
            />
            <path
                d='M60.4911 28.3481L68.0911 15.0781H5.62109L13.5511 28.3481H60.4911Z'
                fill='currentcolor'
            />
            <path
                d='M38.1284 19.7101C37.3587 19.3987 36.4981 19.3987 35.7284 19.7101C24.4384 24.4701 1.14844 29.2001 1.14844 29.2001C13.8184 37.8201 15.3584 65.4401 15.3584 65.4401C20.4884 66.4401 36.9284 66.1401 36.9284 66.1401L38.1284 19.7101Z'
                fill='currentcolor'
            />
            <path
                d='M38.1266 19.7101C37.3559 19.4039 36.4973 19.4039 35.7266 19.7101L36.9266 66.1401C36.9266 66.1401 53.3666 66.4901 58.5066 65.4401C58.5066 65.4401 59.8666 37.8201 72.5066 29.2001C72.5366 29.2001 50.5766 24.8401 38.1266 19.7101Z'
                fill='currentcolor'
            />
            <path
                d='M28.1904 33.4099C28.4304 32.4799 27.4404 31.4099 25.9904 31.0499C24.5404 30.6899 23.1704 31.1399 22.9304 32.0499C22.6904 32.9599 23.6804 34.0499 25.1304 34.3999C26.5804 34.7499 28.0004 34.3499 28.1904 33.4099Z'
                fill='currentcolor'
            />
            <path
                d='M47.1695 31.0513C45.7195 31.4213 44.7295 32.4813 44.9695 33.4113C45.2095 34.3413 46.5795 34.8013 48.0295 34.4113C49.4795 34.0213 50.4595 32.9913 50.2295 32.0613C49.9995 31.1313 48.6195 30.6813 47.1695 31.0513Z'
                fill='currentcolor'
            />
            <path
                d='M5.62953 15.1H68.0795L68.4295 14.5H37.1395V0H36.5495V5.46L28.1695 14.46H5.26953L5.62953 15.06V15.1ZM36.5495 14.5H35.4695L36.5495 13.34V14.5ZM36.5495 13.02L35.1695 14.5H33.7195L36.5495 11.45V13.02ZM36.5495 11.13L33.4195 14.5H31.9995L36.5895 9.57L36.5495 11.13ZM36.5495 9.25L31.6595 14.5H30.2095L36.5495 7.68V9.25ZM36.5495 5.78V7.36L29.9095 14.5H28.4695L36.5495 5.78Z'
                fill='currentcolor'
            />
            <path
                d='M61.58 25.9186C52.77 23.9186 39.96 19.9186 38.58 18.7686C37.2 17.6186 36.42 17.6086 35.02 18.7686C33.62 19.9286 20.79 23.8986 12 25.9186C5.37 27.4286 0 28.4786 0 28.4786C0.34 28.7086 1.15 29.1986 1.15 29.1986C12.7104 27.1337 24.0819 24.1236 35.15 20.1986C35.15 20.3786 35.83 48.3686 35.83 48.3686L19.49 65.9286H19.99L35.88 48.9986L36 51.9986L22.84 66.0886H22.89H23.4L36 52.6286L36.08 55.6286L26.29 66.1286H26.88L36.09 56.2386L36.16 59.2386L29.76 66.1086H30.35L36.14 59.9986L36.21 62.9986L33.21 66.1986H33.81L36.22 63.6186L36.28 66.1686H37.28L37.34 63.6186V62.9886L37.41 59.9886V59.3586L37.49 56.3586V55.7286L37.56 52.7286V52.0986L37.63 49.0986V48.4686C37.63 48.4686 38.31 20.4686 38.31 20.2986C49.4309 24.2293 60.856 27.2394 72.47 29.2986C72.47 29.2986 73.16 28.7986 73.47 28.5686C73.56 28.4786 68.18 27.4286 61.58 25.9186Z'
                fill='currentcolor'
            />
        </g>
        <defs>
            <linearGradient
                id='paint0_linear_1422_12668'
                x1='29.7195'
                y1='-0.31'
                x2='41.1295'
                y2='19.44'
                gradientUnits='userSpaceOnUse'>
                <stop stopColor='#10BEF0' />
                <stop offset='1' stopColor='#0999D6' />
            </linearGradient>
            <linearGradient
                id='paint1_linear_1422_12668'
                x1='36.8511'
                y1='28.3481'
                x2='36.8511'
                y2='15.0781'
                gradientUnits='userSpaceOnUse'>
                <stop stopColor='#10BEF0' />
                <stop offset='0.36' stopColor='#0FBBEE' />
                <stop offset='0.65' stopColor='#0EB1E7' />
                <stop offset='0.92' stopColor='#0AA0DB' />
                <stop offset='1' stopColor='#0999D6' />
            </linearGradient>
            <linearGradient
                id='paint2_linear_1422_12668'
                x1='19.6384'
                y1='66.2001'
                x2='19.6384'
                y2='19.4701'
                gradientUnits='userSpaceOnUse'>
                <stop stopColor='#0999D6' />
                <stop offset='1' stopColor='#10BEF0' />
            </linearGradient>
            <linearGradient
                id='paint3_linear_1422_12668'
                x1='72.5366'
                y1='42.8401'
                x2='35.7266'
                y2='42.8401'
                gradientUnits='userSpaceOnUse'>
                <stop stopColor='#10BEF0' />
                <stop offset='1' stopColor='#0999D6' />
            </linearGradient>
            <clipPath id='clip0_1422_12668'>
                <rect width='376.89' height='66.2' fill='currentcolor' />
            </clipPath>
        </defs>
    </svg>
)

export default MarineLogo
