import React from 'react'

const HamburgerIcon = () => {
    return (
        <svg
            width='32'
            height='17'
            viewBox='0 0 32 17'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <line y1='0.5' x2='32' y2='0.5' stroke='currentcolor' />
            <line y1='16.5' x2='32' y2='16.5' stroke='currentcolor' />
            <line y1='8.5' x2='32' y2='8.5' stroke='currentcolor' />
        </svg>
    )
}

export default HamburgerIcon
