import React from 'react'
import * as rawStyles from './styles.module.scss'
import { WrapperProps } from './types'

const Wrapper: React.FC<WrapperProps> = ({ children, className, type = 'default' }) => {
    const styles: any = rawStyles
    return (
        <div className={`${styles.b_wrapper} ${className}`} data-type={type}>
            {children}
        </div>
    )
}

Wrapper.displayName = 'Wrapper'

export default Wrapper
