import React from 'react'

const VortexaLogo = () => (
    <svg
        width='180'
        height='28'
        viewBox='0 0 180 28'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_1422_12658)'>
            <path
                d='M179.901 19.4008L172.701 8.60078C172.601 8.40078 172.401 8.30078 172.101 8.30078H171.001C170.801 8.30078 170.501 8.40078 170.401 8.60078L163.401 19.4008C163.201 19.6008 163.401 19.9008 163.701 19.9008H165.601C165.801 19.9008 166.001 19.8008 166.201 19.6008L171.601 11.5008L177.001 19.6008C177.101 19.8008 177.301 19.9008 177.601 19.9008H179.601C179.901 19.9008 180.001 19.6008 179.901 19.3008V19.4008Z'
                fill='currentcolor'
            />
            <path
                d='M159.299 8.30078H156.999C156.799 8.30078 156.699 8.30078 156.499 8.50078L152.099 12.6008L147.699 8.50078C147.599 8.40078 147.399 8.30078 147.199 8.30078H144.899C144.599 8.30078 144.399 8.70078 144.699 8.90078L150.299 14.1008L144.699 19.3008C144.499 19.5008 144.699 19.9008 144.899 19.9008H147.199C147.399 19.9008 147.499 19.9008 147.699 19.7008L152.099 15.6008L156.499 19.7008C156.599 19.8008 156.799 19.9008 156.999 19.9008H159.299C159.599 19.9008 159.799 19.5008 159.499 19.3008L153.899 14.1008L159.499 8.90078C159.699 8.70078 159.499 8.30078 159.299 8.30078Z'
                fill='currentcolor'
            />
            <path
                d='M139.799 10.2008V8.80078C139.799 8.50078 139.599 8.30078 139.299 8.30078H124.199C123.899 8.30078 123.699 8.50078 123.699 8.80078V19.5008C123.699 19.8008 123.899 20.0008 124.199 20.0008H139.299C139.599 20.0008 139.799 19.8008 139.799 19.5008V18.1008C139.799 17.8008 139.599 17.6008 139.299 17.6008H126.099V15.2008H137.199C137.499 15.2008 137.699 15.0008 137.699 14.7008V13.3008C137.699 13.0008 137.499 12.8008 137.199 12.8008H126.099V10.6008H139.299C139.599 10.6008 139.799 10.4008 139.799 10.1008V10.2008Z'
                fill='currentcolor'
            />
            <path
                d='M118.799 10.2008V8.80078C118.799 8.50078 118.599 8.30078 118.299 8.30078H103.199C102.899 8.30078 102.699 8.50078 102.699 8.80078V10.2008C102.699 10.5008 102.899 10.7008 103.199 10.7008H109.599V19.3008C109.599 19.6008 109.799 19.8008 110.099 19.8008H111.499C111.799 19.8008 111.999 19.6008 111.999 19.3008V10.7008H118.399C118.699 10.7008 118.899 10.5008 118.899 10.2008H118.799Z'
                fill='currentcolor'
            />
            <path
                d='M96.7 19.9008H99C99.3 19.9008 99.5 19.5008 99.2 19.3008L94.8 15.6008H95.3C97.3 15.6008 98.9 14.1008 99 12.1008C99 10.0008 97.4 8.30078 95.4 8.30078H84.4C84.2 8.30078 84 8.50078 84 8.70078V19.6008C84 19.8008 84.2 20.0008 84.4 20.0008H86C86.2 20.0008 86.4 19.8008 86.4 19.6008V15.6008H91.5L96.4 19.9008C96.4 19.9008 96.5 19.9008 96.6 19.9008H96.7ZM86.5 10.7008H95.5C96.2 10.7008 96.8 11.3008 96.8 12.0008C96.8 12.7008 96.2 13.3008 95.5 13.3008H86.5V10.8008V10.7008Z'
                fill='currentcolor'
            />
            <path
                d='M76.1008 8.30078H67.4008C65.4008 8.30078 63.8008 9.90078 63.8008 11.9008V16.3008C63.8008 18.3008 65.4008 19.9008 67.4008 19.9008H76.1008C78.1008 19.9008 79.7008 18.3008 79.7008 16.3008V11.9008C79.7008 9.90078 78.1008 8.30078 76.1008 8.30078ZM77.5008 15.9008C77.5008 16.9008 76.7008 17.7008 75.7008 17.7008H67.8008C66.8008 17.7008 66.0008 16.9008 66.0008 15.9008V12.3008C66.0008 11.3008 66.8008 10.5008 67.8008 10.5008H75.7008C76.7008 10.5008 77.5008 11.3008 77.5008 12.3008V15.9008Z'
                fill='currentcolor'
            />
            <path
                d='M44.2992 8.80078L51.4992 19.6008C51.5992 19.8008 51.7992 19.9008 52.0992 19.9008H53.1992C53.3992 19.9008 53.6992 19.8008 53.7992 19.6008L60.7992 8.80078C60.9992 8.60078 60.7992 8.30078 60.4992 8.30078H58.5992C58.3992 8.30078 58.1992 8.40078 57.9992 8.60078L52.5992 16.7008L47.1992 8.60078C47.0992 8.40078 46.8992 8.30078 46.5992 8.30078H44.5992C44.2992 8.30078 44.1992 8.60078 44.2992 8.90078V8.80078Z'
                fill='currentcolor'
            />
            <path
                d='M18.8005 16.6984C19.1005 17.0984 19.6005 17.0984 19.8005 16.6984L28.8005 4.0984C28.9005 3.8984 29.1005 3.7984 29.3005 3.6984L37.5005 -0.00159892C38.2005 -0.301599 38.9005 0.498401 38.5005 1.1984L20.0005 27.2984C19.7005 27.6984 19.0005 27.6984 18.7005 27.2984L0.200518 1.2984C-0.299482 0.598401 0.400518 -0.301599 1.10052 -0.00159892L9.30052 3.5984C9.50052 3.5984 9.70052 3.7984 9.80052 3.9984L18.8005 16.5984V16.6984Z'
                fill='currentcolor'
            />
            <path
                d='M18.9985 10.9994C19.0985 11.0994 19.2985 11.0994 19.4985 10.9994L23.0985 7.19938C23.2985 6.99938 23.0985 6.59938 22.7985 6.69938L19.4985 8.09938H19.2985L15.9985 6.69938C15.6985 6.59938 15.3985 6.99938 15.6985 7.19938L19.2985 10.9994H18.9985Z'
                fill='currentcolor'
            />
        </g>
        <defs>
            <clipPath id='clip0_1422_12658'>
                <rect width='180' height='27.7' fill='currentcolor' />
            </clipPath>
        </defs>
    </svg>
)

export default VortexaLogo
