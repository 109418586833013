// extracted by mini-css-extract-plugin
export var accordion_content = "styles-module--accordion_content--pQXMM";
export var accordion_item = "styles-module--accordion_item--IaCVG";
export var accordion_trigger = "styles-module--accordion_trigger--+a-6m";
export var b_accordion = "styles-module--b_accordion--sQ5Wk";
export var blink = "styles-module--blink--RkrZK";
export var icon = "styles-module--icon--sAXEW";
export var slideDown = "styles-module--slideDown--2P4vH";
export var slideUp = "styles-module--slideUp--cS+Ua";
export var text = "styles-module--text--ZGgO7";
export var title = "styles-module--title--6MvVr";