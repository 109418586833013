import React from 'react'

const BlogNavIcon = () => (
    <svg
        width='206'
        height='168'
        viewBox='0 0 206 168'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_1429_12770)'>
            <path
                d='M22.0508 22.1008C22.0508 11.5808 30.5808 3.05078 41.1008 3.05078H164.911C175.431 3.05078 183.961 11.5808 183.961 22.1008V136.391H22.0608V22.1008H22.0508Z'
                stroke='currentcolor'
                strokeWidth='6'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
            <path
                d='M3 136.379H203V145.899C203 156.419 194.47 164.949 183.95 164.949H22.05C11.53 164.949 3 156.419 3 145.899V136.379Z'
                stroke='currentcolor'
                strokeWidth='6'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
            <path
                d='M66.6797 49.1484H139.33'
                stroke='#FF4802'
                strokeWidth='6'
                strokeLinecap='round'
            />
            <path
                d='M66.6797 87.8867H122.36'
                stroke='#FF4802'
                strokeWidth='6'
                strokeLinecap='round'
            />
        </g>
        <defs>
            <clipPath id='clip0_1429_12770'>
                <rect width='206' height='168' fill='white' />
            </clipPath>
        </defs>
    </svg>
)

export default BlogNavIcon
