import React from 'react'

const InfoIcon = () => {
    return (
        <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z'
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M12 16V12'
                stroke='currentColor'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
            <path
                d='M12.0084 8L12.0084 8.66663'
                stroke='currentColor'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export default InfoIcon
