import React from 'react'

const DemoIcon = () => (
    <svg
        width='206'
        height='206'
        viewBox='0 0 206 206'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M83 131.04V74.9599C83 70.9399 87.5 68.5699 90.82 70.8299L131.94 98.8699C134.85 100.86 134.85 105.15 131.94 107.13L90.82 135.17C87.5 137.43 83 135.06 83 131.04Z'
            stroke='currentcolor'
            strokeWidth='6'
            strokeLinejoin='round'
        />
        <path
            d='M103 203C158.228 203 203 158.228 203 103C203 47.7715 158.228 3 103 3C47.7715 3 3 47.7715 3 103C3 158.228 47.7715 203 103 203Z'
            stroke='currentcolor'
            strokeWidth='6'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
)

export default DemoIcon
