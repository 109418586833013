// extracted by mini-css-extract-plugin
export var Nav_enterFromLeft = "styles-module--Nav_enterFromLeft--QHljB";
export var Nav_enterFromRight = "styles-module--Nav_enterFromRight--2CV7E";
export var Nav_exitToLeft = "styles-module--Nav_exitToLeft--XhEzx";
export var Nav_exitToRight = "styles-module--Nav_exitToRight--VhPyn";
export var Nav_fadeIn = "styles-module--Nav_fadeIn--2H5MC";
export var Nav_fadeOut = "styles-module--Nav_fadeOut--5+jfm";
export var Nav_scaleIn = "styles-module--Nav_scaleIn--q1MI6";
export var Nav_scaleOut = "styles-module--Nav_scaleOut--OVHd4";
export var b_navigation = "styles-module--b_navigation--k6WNf";
export var blink = "styles-module--blink--kaOD+";
export var caret_down = "styles-module--caret_down--xKQB7";
export var menu = "styles-module--menu--NqvtP";
export var navigation_list = "styles-module--navigation_list--CWwUU";
export var navigation_menu_content = "styles-module--navigation_menu_content--ii7+e";
export var viewport_position = "styles-module--viewport_position--nYc0p";