import React from 'react'

const LogoIcon = () => {
    return (
        <svg
            width='106'
            height='28'
            viewBox='0 0 106 28'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M10.5 0C4.70125 0 0 4.83339 0 10.7951C0 16.0967 3.72168 20.5074 8.62441 21.4151V28L12.4051 26.6948V21.405C17.2914 20.4922 21 16.0816 21 10.7951C21 4.83339 16.2988 0 10.5 0ZM10.4787 17.5872C6.83892 17.5872 3.89532 14.5524 3.89532 10.8187C3.89532 7.08505 6.84711 4.05028 10.4787 4.05028C14.1103 4.05028 17.0621 7.08505 17.0621 10.8187C17.0621 14.5524 14.1168 17.5872 10.4787 17.5872Z'
                fill='currentColor'
            />
            <path
                d='M100.418 22.7117C98.6517 22.7067 97.2887 22.3006 96.3291 21.4936C95.3852 20.6866 94.8993 19.6366 94.8715 18.3433L97.2133 18.35C97.2584 19.1071 97.5327 19.7151 98.036 20.1738C98.5551 20.6169 99.343 20.8399 100.4 20.8429C101.33 20.8456 101.993 20.6819 102.388 20.3519C102.783 20.006 102.982 19.5808 102.983 19.0762C102.984 18.6031 102.843 18.24 102.56 17.9869C102.277 17.7337 101.97 17.5673 101.639 17.4875C101.324 17.392 100.717 17.2483 99.819 17.0565C98.8893 16.833 98.1172 16.6022 97.5028 16.3639C96.8885 16.1256 96.3691 15.7692 95.9447 15.2949C95.5202 14.8206 95.3091 14.1971 95.3113 13.4244C95.3144 12.3678 95.7189 11.5253 96.525 10.8968C97.3468 10.2683 98.4753 9.95617 99.9103 9.96027C101.487 9.96478 102.701 10.331 103.55 11.0588C104.4 11.7867 104.87 12.7105 104.961 13.8305L102.62 13.8238C102.511 13.24 102.26 12.7662 101.867 12.4023C101.489 12.0228 100.851 11.8317 99.9523 11.8291C99.1953 11.827 98.6351 11.9594 98.2716 12.2265C97.9082 12.4935 97.7258 12.8636 97.7244 13.3367C97.7234 13.6836 97.8251 13.9599 98.0295 14.1655C98.234 14.3711 98.4937 14.5295 98.8088 14.6408C99.1239 14.7363 99.5888 14.848 100.203 14.9759C100.33 14.9921 100.519 15.032 100.771 15.0958C101.732 15.3194 102.528 15.5582 103.158 15.8123C103.788 16.0664 104.315 16.4464 104.74 16.9523C105.18 17.4582 105.399 18.129 105.396 18.9648C105.393 20.1476 104.956 21.0689 104.087 21.7287C103.218 22.3886 101.995 22.7162 100.418 22.7117Z'
                fill='currentColor'
            />
            <path
                d='M87.9791 22.6982C86.7333 22.6946 85.6617 22.4156 84.7644 21.861C83.8671 21.3065 83.1833 20.5476 82.7129 19.5843C82.2584 18.621 82.0328 17.5165 82.0364 16.2707C82.04 15.0091 82.2798 13.898 82.7556 12.9374C83.2472 11.9768 83.9432 11.2298 84.8436 10.6962C85.7441 10.1626 86.8014 9.89749 88.0157 9.90097C89.8292 9.90616 91.2312 10.4621 92.2215 11.5688C93.2276 12.6756 93.728 14.1752 93.7225 16.0676C93.7212 16.5249 93.7125 16.8166 93.6964 16.9427L84.4237 16.9162C84.4521 18.0044 84.7728 18.9279 85.3856 19.6866C86.0143 20.4295 86.8964 20.8027 88.0318 20.8059C88.978 20.8086 89.7041 20.582 90.21 20.1262C90.7317 19.6703 91.0643 19.1745 91.2078 18.6388L93.5732 18.6455C93.2228 19.8746 92.5813 20.8584 91.6487 21.5969C90.7162 22.3354 89.493 22.7025 87.9791 22.6982ZM91.3125 15.1145C91.3152 14.1525 91.0021 13.3631 90.373 12.7463C89.7598 12.1137 88.9486 11.796 87.9393 11.7931C86.9616 11.7903 86.1486 12.0955 85.5002 12.7087C84.8519 13.3061 84.4948 14.1015 84.4289 15.0948L91.3125 15.1145Z'
                fill='currentColor'
            />
            <path
                d='M75.8388 22.6403C74.0726 22.6352 72.7096 22.2292 71.75 21.4222C70.8061 20.6152 70.3202 19.5651 70.2924 18.2719L72.6342 18.2786C72.6793 19.0357 72.9536 19.6436 73.4569 20.1024C73.976 20.5455 74.7639 20.7685 75.8205 20.7715C76.7509 20.7742 77.4137 20.6105 77.8089 20.2804C78.2041 19.9346 78.4025 19.5094 78.4039 19.0048C78.4053 18.5317 78.2644 18.1686 77.9813 17.9154C77.6981 17.6623 77.3911 17.4959 77.0601 17.4161C76.745 17.3205 76.1383 17.1769 75.2399 16.9851C74.3102 16.7616 73.5381 16.5308 72.9237 16.2924C72.3094 16.0541 71.79 15.6978 71.3656 15.2235C70.9411 14.7492 70.73 14.1257 70.7322 13.353C70.7353 12.2964 71.1398 11.4539 71.9459 10.8254C72.7677 10.1969 73.8962 9.88475 75.3312 9.88886C76.9082 9.89337 78.1214 10.2596 78.9709 10.9874C79.8204 11.7152 80.2909 12.6391 80.3823 13.7591L78.0405 13.7524C77.9318 13.1686 77.6808 12.6947 77.2876 12.3309C76.9102 11.9514 76.2721 11.7603 75.3732 11.7577C74.6162 11.7556 74.056 11.888 73.6925 12.155C73.3291 12.4221 73.1467 12.7922 73.1453 13.2653C73.1443 13.6122 73.246 13.8885 73.4504 14.094C73.6549 14.2996 73.9146 14.4581 74.2297 14.5694C74.5448 14.6649 75.0097 14.7766 75.6244 14.9045C75.7505 14.9207 75.9396 14.9606 76.1918 15.0244C77.1531 15.248 77.9488 15.4868 78.5789 15.7409C79.2089 15.995 79.7361 16.375 80.1605 16.8809C80.6006 17.3868 80.8194 18.0576 80.8171 18.8934C80.8137 20.0761 80.3774 20.9974 79.5081 21.6573C78.6389 22.3171 77.4158 22.6448 75.8388 22.6403Z'
                fill='currentColor'
            />
            <path
                d='M66.2821 14.5473C66.2846 13.6958 66.0815 13.0329 65.6728 12.5586C65.2799 12.0844 64.6656 11.8461 63.8298 11.8437C62.8363 11.8408 62.0391 12.1145 61.4383 12.6647C60.8532 13.215 60.5594 13.9475 60.5567 14.8621L60.5356 22.266L58.2174 22.2594L58.2519 10.1955L60.5701 10.2022L60.5658 11.7161L60.6131 11.7162C60.9773 11.1811 61.4911 10.741 62.1544 10.3959C62.8178 10.0351 63.6304 9.85609 64.5924 9.85884C65.7751 9.86222 66.7361 10.2119 67.4753 10.9079C68.2303 11.5882 68.6059 12.5906 68.6021 13.9153L68.5782 22.2891L66.26 22.2824L66.2821 14.5473Z'
                fill='currentColor'
            />
            <path
                d='M50.5338 22.5909C49.2879 22.5873 48.2164 22.3083 47.3191 21.7537C46.4218 21.1992 45.738 20.4403 45.2676 19.477C44.813 18.5137 44.5875 17.4092 44.5911 16.1634C44.5947 14.9018 44.8344 13.7907 45.3103 12.8301C45.8019 11.8695 46.4979 11.1225 47.3983 10.5889C48.2988 10.0553 49.3561 9.79019 50.5704 9.79367C52.3839 9.79886 53.7858 10.3548 54.7762 11.4615C55.7823 12.5683 56.2826 14.0679 56.2772 15.9603C56.2759 16.4176 56.2672 16.7093 56.2511 16.8354L46.9784 16.8089C47.0068 17.8971 47.3275 18.8206 47.9403 19.5793C48.569 20.3222 49.4511 20.6954 50.5865 20.6986C51.5327 20.7013 52.2587 20.4747 52.7647 20.0189C53.2864 19.563 53.619 19.0672 53.7624 18.5315L56.1279 18.5382C55.7775 19.7673 55.136 20.7511 54.2034 21.4896C53.2709 22.2281 52.0477 22.5952 50.5338 22.5909ZM53.8672 15.0072C53.8699 14.0452 53.5568 13.2558 52.9277 12.639C52.3145 12.0064 51.5033 11.6887 50.494 11.6858C49.5163 11.683 48.7032 11.9882 48.0549 12.6014C47.4066 13.1988 47.0495 13.9942 46.9836 14.9875L53.8672 15.0072Z'
                fill='currentColor'
            />
            <path
                d='M33.0488 5.27405L35.6509 5.28149L35.6089 19.9474L44.1719 19.9719L44.1655 22.2191L33.0004 22.1872L33.0488 5.27405Z'
                fill='currentColor'
            />
        </svg>
    )
}

export default LogoIcon
