import React from 'react'
import * as myStyles from './styles.module.scss'
import { FooterProps } from './types'
import ClickableArea from '../../basics/clickable-area/component'
import { FOOTER_LINKS, LEGAL } from './constants'
import ReactHtmlParser from 'react-html-parser'
import { LensesLogo } from '../../basics/icons'

const Footer: React.FC<FooterProps> = ({ title, href, buttonText }) => {
    const styles: any = myStyles

    return (
        <div className={styles.m_footer}>
            <div className={styles.body}>
                <div className={styles.content}>
                    <div className={styles.text}>
                        <div className={styles.content}>
                            <h3>{ReactHtmlParser(title)}</h3>
                            {buttonText && href && (
                                <div>
                                    <ClickableArea variant='primary' color='light' href={href}>
                                        {buttonText}
                                    </ClickableArea>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={styles.links}>
                        <div className={styles.content}>
                            {FOOTER_LINKS.map((column, index) => (
                                <div key={index} className={styles.column}>
                                    <div className={styles.column_title}>{column.title}</div>
                                    {column.links.map((link, linkIndex) => (
                                        <div key={linkIndex} className={styles.row}>
                                            <a href={link.href} target={link.target}>
                                                {link.title}
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                        <div className={styles.logo}>
                            <LensesLogo key='lensesLogo' />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <div className={styles.content}>
                    <div className={styles.logo_mobile}>
                        <LensesLogo key='lensesLogo' />
                    </div>
                    <div className={styles.legal_links}>
                        <div className={styles.copy}>© Lenses.io 2024</div>
                        {FOOTER_LINKS.filter((column) => column.title === 'Legal').map(
                            (column, index) => (
                                <div key={index} className={styles.column}>
                                    {column.links.map((link, linkIndex) => (
                                        <div key={linkIndex} className={styles.row}>
                                            <a href={link.href} target={link.target}>
                                                {link.title}
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            ),
                        )}
                    </div>
                    <div className={styles.terms}>{LEGAL}</div>
                </div>
            </div>
        </div>
    )
}

export default Footer
