import React from 'react'

const AvanzaLogo = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='103'
        height='36'
        viewBox='0 0 103 36'
        fill='none'>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M6.61511 31.9483H3.94336C4.18281 31.0522 4.58483 29.6514 4.80916 28.7585C4.95787 28.1479 5.10847 27.5254 5.24269 26.9173L6.61511 31.9483ZM3.91563 24.0811L0 36H2.80723L3.33087 34.0926H7.215L7.73738 36H10.5459L6.66237 24.0811H3.91563ZM18.679 24.0811H21.4862L17.8245 36H15.1351L11.5194 24.0811H14.3304L16.5107 32.5563L18.679 24.0811ZM28.768 31.9483H26.0925C26.3313 31.0522 26.7358 29.6514 26.9608 28.7585C27.1101 28.1479 27.2563 27.5254 27.393 26.9173L28.768 31.9483ZM26.0635 24.0811L22.1491 36H24.9576L25.4812 34.0926H29.3641L29.8871 36H32.6975L28.8127 24.0811H26.0635ZM35.252 24.0811H37.8349L40.1217 28.4706C40.9585 30.1417 41.2124 30.6823 41.7058 31.7283L41.5867 24.0811H44.1104V36H41.5275L39.5533 32.235C38.598 30.463 38.4191 30.0913 37.7007 28.5046L37.7757 36H35.252V24.0811ZM47.6404 24.0811H56.1623V26.4113L50.4061 33.6515H56.1327V36H47.1476V33.7712L52.9934 26.4113H47.6404V24.0811ZM65.378 31.9483H62.7037C62.9438 31.0522 63.3452 29.6514 63.5701 28.7585C63.7188 28.1479 63.8694 27.5254 64.0036 26.9173L65.378 31.9483ZM62.6753 24.0811L58.7603 36H61.5676L62.0906 34.0926H65.9747L66.4977 36H69.5879L65.4221 24.0811H62.6753ZM73.8255 21.933H78.0556V35.8916H73.8255V21.933ZM81.7469 17.9461H85.9769V35.8929H81.7469V17.9461ZM89.626 12.9612H93.8536V35.8929H89.626V12.9612ZM97.9223 0H102.151V35.8929H97.9223V0Z'
            fill='currentcolor'
        />
    </svg>
)

export default AvanzaLogo
