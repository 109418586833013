import React from 'react'
import * as myStyles from './styles.module.scss'
import { IconProps } from './types'

const Icon = ({ icon, size, className, onClick, ...props }: IconProps) => {
    const styles: any = myStyles
    return (
        <div
            className={`${styles['b_icon']} ${className || ''}`}
            data-size={size}
            onClick={onClick}
            {...props}>
            {icon}
        </div>
    )
}

export default Icon
