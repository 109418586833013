import React from 'react'

const SasLogo = () => (
    <svg
        width='195'
        height='45'
        viewBox='0 0 195 45'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_1457_12863)'>
            <path
                d='M19.3937 13.6119L18.658 12.7536C17.5545 11.5275 15.9604 11.5275 14.6117 12.5084C13.3855 13.4893 13.0177 15.2059 13.9986 16.5547C13.9986 16.5547 14.1212 16.6773 14.3664 16.9226C16.6961 19.7427 20.0068 23.6664 20.0068 23.6664C24.0531 28.5711 22.2139 34.4566 16.8188 37.8899C12.0367 40.8326 3.2084 39.8517 0.265625 34.9471C2.47271 41.8136 10.3201 46.3503 19.0258 44.1433C26.628 42.304 34.9659 32.6174 25.7697 21.3367L19.3937 13.6119Z'
                fill='currentcolor'
            />
            <path
                d='M9.58517 30.0412L11.0566 31.8804C12.1601 33.1066 13.7541 33.1066 15.1029 32.1257C16.329 31.1448 16.6969 29.4281 15.716 28.0794C15.716 28.0794 15.1029 27.3437 14.2446 26.2401C12.1601 23.6652 9.95302 21.0903 9.95302 21.0903C5.9067 16.1856 7.74593 10.3001 13.141 6.86682C17.923 3.92404 26.7514 4.90497 29.6942 9.8096C27.3645 2.94312 19.5171 -1.59367 10.8113 0.490802C3.20915 2.45265 -5.12872 12.1393 4.06746 23.2973L9.58517 30.0412Z'
                fill='currentcolor'
            />
            <path
                d='M48.5765 18.637C44.4076 17.5335 39.6256 16.9204 39.6256 13.4872C39.6256 10.5444 42.8136 9.68608 45.5111 9.68608C48.4539 9.68608 51.5193 10.7896 51.8871 14.5907H56.4239C56.0561 7.479 51.1514 5.39453 45.1433 5.39453C40.3613 5.39453 34.8435 7.84685 34.8435 13.7324C34.8435 19.3727 39.3803 20.9667 43.7945 22.0703C48.3313 23.1738 52.7455 23.7869 52.7455 27.588C52.7455 31.2665 48.9444 32.0022 46.1242 32.0022C42.4457 32.0022 38.8899 30.5308 38.7673 26.2392V26.3618H34.2305C34.4757 33.5962 39.7482 36.2937 45.879 36.2937C51.3967 36.2937 57.5275 33.964 57.5275 26.9749C57.5275 21.3346 53.1133 19.7406 48.5765 18.637Z'
                fill='currentcolor'
            />
            <path
                d='M81.4389 28.5689V13.4872C81.4389 6.62069 76.0438 5.39453 71.1392 5.39453C65.0084 5.39453 59.7359 7.96946 59.4907 15.2038H64.0275C64.2727 10.9122 67.0929 9.56347 70.894 9.56347C73.7141 9.56347 77.0247 10.2992 77.0247 14.4681C77.0247 18.1466 72.7332 17.7787 67.8286 18.7597C63.1692 19.7406 58.5098 21.212 58.5098 27.8332C58.5098 33.7188 62.5561 36.0485 67.5833 36.0485C71.3844 36.0485 74.6951 34.5771 77.27 31.5117C77.27 34.6997 78.7414 36.0485 81.0711 36.0485C82.5425 36.0485 83.5234 35.8033 84.2591 35.1902V31.6343C83.646 31.8795 83.2782 31.8795 82.9103 31.8795C81.4389 31.8795 81.4389 30.8986 81.4389 28.5689ZM77.0247 25.2583C76.9021 27.9558 74.4498 31.8795 68.5643 31.8795C65.8667 31.8795 63.2918 30.776 63.2918 27.588C63.2918 24.0321 65.8667 22.806 68.8095 22.3155C71.8749 21.7024 75.1855 21.7024 77.0247 20.3537V25.2583Z'
                fill='currentcolor'
            />
            <path
                d='M98.2367 18.637C94.0677 17.5335 89.2857 16.9204 89.2857 13.4872C89.2857 10.667 92.3511 9.68608 95.0487 9.68608C97.9914 9.68608 100.934 10.7896 101.302 14.5907H101.547H105.839H106.084C105.716 7.479 100.812 5.39453 94.8034 5.39453C94.8034 5.39453 94.8034 5.39453 94.6808 5.39453C89.8988 5.39453 84.5037 7.84685 84.5037 13.7324C84.5037 19.3727 89.0405 20.9667 93.4547 22.0703C97.9914 23.1738 102.406 23.7869 102.406 27.588C102.406 31.2665 98.6045 31.8795 95.7844 31.8795C92.1059 31.8795 88.55 30.5308 88.4274 26.2392H83.8906V26.3618C84.1359 33.5962 89.4083 36.2937 95.5391 36.2937C101.057 36.2937 107.188 33.964 107.188 26.9749C107.188 21.3346 102.773 19.7406 98.2367 18.637Z'
                fill='currentcolor'
            />
            <path d='M121.043 0H120.43V45H121.043V0Z' fill='currentcolor' />
            <path
                d='M136.124 14.347H138.944V7.60314H141.519V5.27344H133.672V7.60314H136.124V14.347Z'
                fill='currentcolor'
            />
            <path
                d='M144.953 10.7911H147.528V14.2244H150.348V5.27344H147.528V8.46145H144.953V5.27344H142.133V14.347H144.953V10.7911Z'
                fill='currentcolor'
            />
            <path
                d='M159.053 14.2244V11.8947H154.149V10.7911H158.44V8.70668H154.149V7.60314H158.808V5.27344H151.451V14.2244H159.053Z'
                fill='currentcolor'
            />
            <path
                d='M136.248 16.4297V25.3806H139.068V22.6831H140.662C142.747 22.6831 144.218 21.7022 144.218 19.4951C144.218 18.2689 143.36 16.4297 140.907 16.4297H136.248ZM141.643 19.4951C141.643 20.476 140.907 20.476 140.172 20.476H139.068V18.6368H140.294C141.03 18.6368 141.643 18.6368 141.643 19.4951Z'
                fill='currentcolor'
            />
            <path
                d='M149 16.1875C146.302 16.1875 144.463 18.1494 144.463 20.8469C144.463 23.5444 146.302 25.5063 149 25.5063C151.697 25.5063 153.536 23.5444 153.536 20.8469C153.536 18.1494 151.697 16.1875 149 16.1875ZM149 23.4218C148.264 23.4218 147.16 22.9314 147.16 20.9695C147.16 19.0077 148.264 18.5172 149 18.5172C149.735 18.5172 150.839 19.0077 150.839 20.9695C150.839 22.9314 149.735 23.4218 149 23.4218Z'
                fill='currentcolor'
            />
            <path
                d='M156.846 21.9474L155.742 16.4297H153.045L155.497 25.3806H158.195L159.176 19.8629L160.157 25.3806H162.854L165.307 16.4297H162.609L161.505 22.07L160.402 16.4297H157.95L156.846 21.9474Z'
                fill='currentcolor'
            />
            <path
                d='M173.155 23.0509H168.373V21.9474H172.664V19.8629H168.373V18.7594H173.032V16.4297H165.553V25.3806H173.155V23.0509Z'
                fill='currentcolor'
            />
            <path
                d='M176.588 22.3152H177.937C179.04 22.3152 179.04 23.1736 179.163 24.0319C179.163 24.3997 179.285 24.8902 179.408 25.3806H182.228C181.983 24.8902 181.983 23.9093 181.983 23.4188C181.86 22.3152 181.37 21.4569 180.634 21.2117C181.492 20.9665 181.983 19.9855 181.983 19.0046C181.983 17.288 180.634 16.4297 179.04 16.4297H173.768V25.5033H176.588V22.3152ZM176.588 18.6368H177.937C178.917 18.6368 179.285 18.882 179.285 19.4951C179.285 20.2308 178.55 20.3534 178.059 20.3534H176.588V18.6368Z'
                fill='currentcolor'
            />
            <path
                d='M141.52 27.4648H133.795V29.7945H136.247V36.4158H139.067V29.7945H141.52V27.4648Z'
                fill='currentcolor'
            />
            <path
                d='M145.812 27.2227C143.115 27.2227 141.275 29.1845 141.275 31.8821C141.275 34.5796 143.115 36.5415 145.812 36.5415C148.51 36.5415 150.349 34.5796 150.349 31.8821C150.349 29.1845 148.51 27.2227 145.812 27.2227ZM145.812 34.3344C145.076 34.3344 143.973 33.9665 143.973 31.8821C143.973 29.9202 145.076 29.4297 145.812 29.4297C146.548 29.4297 147.651 29.9202 147.651 31.8821C147.651 33.8439 146.548 34.3344 145.812 34.3344Z'
                fill='currentcolor'
            />
            <path
                d='M162.364 27.4648H158.931L156.478 30.5302V27.4648H153.658V36.2932H156.478V33.7183L157.214 32.8599L159.421 36.2932H162.977L159.176 30.8981L162.364 27.4648Z'
                fill='currentcolor'
            />
            <path
                d='M168.372 32.2469L165.797 27.4648H162.977V36.2932V36.4158H165.552V31.5112L168.249 36.4158H170.947V27.4648H168.372V32.2469Z'
                fill='currentcolor'
            />
            <path
                d='M175.974 27.2227C173.277 27.2227 171.438 29.1845 171.438 31.8821C171.438 34.5796 173.277 36.5415 175.974 36.5415C178.672 36.5415 180.511 34.5796 180.511 31.8821C180.511 29.1845 178.672 27.2227 175.974 27.2227ZM175.974 34.3344C175.239 34.3344 174.258 33.9665 174.135 31.8821C174.135 29.9202 175.239 29.4297 175.974 29.4297C176.71 29.4297 177.814 29.9202 177.814 31.8821C177.814 33.8439 176.71 34.3344 175.974 34.3344Z'
                fill='currentcolor'
            />
            <path
                d='M189.462 27.4648L188.359 33.1052L187.255 27.4648H184.803L183.7 32.9826L182.596 27.4648H179.898L182.351 36.4158H185.048L186.029 31.0207L187.01 36.4158H189.708L192.16 27.4648H189.462Z'
                fill='currentcolor'
            />
            <path
                d='M32.2694 33.2305C31.2884 33.2305 30.5527 33.9662 30.5527 34.9471C30.4301 35.928 31.2884 36.6637 32.2694 36.6637C33.2503 36.6637 33.986 35.928 33.986 34.9471C33.986 33.9662 33.1277 33.2305 32.2694 33.2305ZM32.2694 36.4185C31.411 36.4185 30.798 35.8054 30.798 34.9471C30.798 34.0888 31.411 33.4757 32.2694 33.4757C33.005 33.4757 33.6181 34.0888 33.6181 34.9471C33.6181 35.8054 33.005 36.4185 32.2694 36.4185Z'
                fill='currentcolor'
            />
            <path
                d='M32.8815 34.5779C32.8815 34.2101 32.6363 33.9648 32.1459 33.9648H31.4102V35.9267H31.6554V35.0684H31.778H32.1459L32.6363 35.9267H33.0042L32.3911 35.0684C32.6363 35.0684 32.8815 34.8232 32.8815 34.5779ZM31.778 34.8232V34.2101H32.1459C32.3911 34.2101 32.6363 34.2101 32.6363 34.4553C32.6363 34.7005 32.3911 34.8232 32.1459 34.8232H31.778Z'
                fill='currentcolor'
            />
            <path
                d='M107.187 33.2305C106.206 33.2305 105.471 33.9662 105.471 34.9471C105.348 35.928 106.206 36.6637 107.187 36.6637C108.168 36.6637 108.904 35.928 108.904 34.9471C108.904 33.9662 108.046 33.2305 107.187 33.2305ZM107.187 36.4185C106.329 36.4185 105.716 35.8054 105.716 34.9471C105.716 34.0888 106.329 33.4757 107.187 33.4757C107.923 33.4757 108.536 34.0888 108.536 34.9471C108.536 35.8054 107.923 36.4185 107.187 36.4185Z'
                fill='currentcolor'
            />
            <path
                d='M107.8 34.5779C107.8 34.2101 107.554 33.9648 107.064 33.9648H106.328V35.9267H106.573V35.0684H106.819H107.186L107.677 35.9267H107.922L107.309 35.0684C107.554 35.0684 107.8 34.8232 107.8 34.5779ZM106.819 34.8232V34.2101H107.186C107.432 34.2101 107.677 34.2101 107.677 34.4553C107.677 34.7005 107.432 34.8232 107.186 34.8232H106.819Z'
                fill='currentcolor'
            />
            <path
                d='M193.14 33.2305C192.16 33.2305 191.424 33.9662 191.424 34.9471C191.424 35.928 192.282 36.6637 193.14 36.6637C194.121 36.6637 194.857 35.928 194.857 34.9471C194.857 33.9662 193.999 33.2305 193.14 33.2305ZM193.263 36.4185C192.405 36.4185 191.792 35.8054 191.792 34.9471C191.792 34.0888 192.405 33.4757 193.263 33.4757C193.999 33.4757 194.612 34.0888 194.612 34.9471C194.612 35.8054 193.999 36.4185 193.263 36.4185Z'
                fill='currentcolor'
            />
            <path
                d='M193.876 34.5779C193.876 34.2101 193.631 33.9648 193.14 33.9648H192.527V35.9267H192.773V35.0684H193.14L193.631 35.9267H193.999L193.386 35.0684C193.631 35.0684 193.876 34.8232 193.876 34.5779ZM192.773 34.8232V34.2101H193.14C193.386 34.2101 193.631 34.2101 193.631 34.4553C193.631 34.7005 193.386 34.8232 193.14 34.8232H192.773Z'
                fill='currentcolor'
            />
        </g>
        <defs>
            <clipPath id='clip0_1457_12863'>
                <rect width='195' height='45' fill='white' />
            </clipPath>
        </defs>
    </svg>
)

export default SasLogo
