import React from 'react'

const DocsIcon = () => (
    <svg
        width='206'
        height='176'
        viewBox='0 0 206 176'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_1429_12765)'>
            <path
                d='M125.352 52.4414H181.752'
                stroke='#FF4802'
                strokeWidth='6'
                strokeLinecap='round'
            />
            <path
                d='M125.352 74.4414H181.752'
                stroke='#FF4802'
                strokeWidth='6'
                strokeLinecap='round'
            />
            <path
                d='M125.352 96.8906H147.572'
                stroke='#FF4802'
                strokeWidth='6'
                strokeLinecap='round'
            />
            <path
                d='M103 33C103 16.43 89.57 3 73 3H3V143H73C89.57 143 103 156.43 103 173M103 33C103 16.43 116.43 3 133 3H203V143H133C116.43 143 103 156.43 103 173M103 33V173'
                stroke='currentcolor'
                strokeWidth='6'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </g>
        <defs>
            <clipPath id='clip0_1429_12765'>
                <rect width='206' height='176' fill='white' />
            </clipPath>
        </defs>
    </svg>
)

export default DocsIcon
