import React from 'react'

const PricingPlansIcon = () => (
    <svg
        width='206'
        height='194'
        viewBox='0 0 206 194'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_1429_12785)'>
            <path
                d='M142.51 3.44922H63.5C57.9772 3.44922 53.5 7.92637 53.5 13.4492V180.549C53.5 186.072 57.9772 190.549 63.5 190.549H142.51C148.033 190.549 152.51 186.072 152.51 180.549V13.4492C152.51 7.92637 148.033 3.44922 142.51 3.44922Z'
                stroke='currentcolor'
                strokeWidth='6'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M102.998 85.0509C115.38 85.0509 125.418 75.0132 125.418 62.6309C125.418 50.2487 115.38 40.2109 102.998 40.2109C90.6159 40.2109 80.5781 50.2487 80.5781 62.6309C80.5781 75.0132 90.6159 85.0509 102.998 85.0509Z'
                stroke='currentcolor'
                strokeWidth='6'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M82.1484 118.109H123.848'
                stroke='currentcolor'
                strokeWidth='6'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M86.668 142.23H119.328'
                stroke='currentcolor'
                strokeWidth='6'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M53.5014 31.8789H13.4414C7.92141 31.8789 3.44141 36.3589 3.44141 41.8789V152.119C3.44141 157.639 7.92141 162.119 13.4414 162.119H53.5014V31.8789Z'
                stroke='currentcolor'
                strokeWidth='6'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M192.56 31.8789H152.5V162.119H192.56C198.08 162.119 202.56 157.639 202.56 152.119V41.8789C202.56 36.3589 198.08 31.8789 192.56 31.8789Z'
                stroke='currentcolor'
                strokeWidth='6'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </g>
        <defs>
            <clipPath id='clip0_1429_12785'>
                <rect width='206' height='194' fill='white' />
            </clipPath>
        </defs>
    </svg>
)

export default PricingPlansIcon
