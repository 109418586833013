import React from 'react'

const WarpStream = () => (
    <svg
        width='156'
        height='28'
        viewBox='0 0 156 28'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M47.2584 21.0571L43.2698 6.75937H45.9698L49.0993 19.0321H48.4243L51.738 6.75937H54.3766L57.6902 19.0321H56.9539L60.0834 6.75937H62.7834L58.8561 21.0571H55.972L52.6584 9.09119H53.4561L50.1425 21.0571H47.2584Z'
            fill='currentColor'
        />
        <path
            d='M65.9762 21.3016C65.2398 21.3016 64.6262 21.1789 64.0739 20.9334C63.5216 20.688 63.0921 20.3198 62.7853 19.8903C62.4784 19.4607 62.3557 18.9084 62.3557 18.2948C62.3557 17.6812 62.4784 17.2516 62.7239 16.7607C62.9693 16.3312 63.3989 15.9016 63.9512 15.5948C64.5034 15.288 65.1784 15.0425 66.0375 14.9198L69.5966 14.3675V16.2084L66.5284 16.7607C65.9762 16.8221 65.608 17.0062 65.3012 17.2516C65.0557 17.4971 64.933 17.8039 64.933 18.1721C64.933 18.5403 65.0557 18.8471 65.3625 19.0925C65.6694 19.338 66.0375 19.3994 66.4671 19.3994C66.8966 19.3994 67.5103 19.2766 67.9398 19.0312C68.3693 18.7857 68.6762 18.4789 68.9216 18.0494C69.1671 17.6198 69.2898 17.1903 69.2898 16.6994V13.9994C69.2898 13.5084 69.1057 13.1403 68.7375 12.8334C68.3694 12.5266 67.8784 12.3425 67.2648 12.3425C66.6512 12.3425 66.2216 12.4653 65.7921 12.7721C65.3625 13.0789 65.0557 13.4471 64.8103 13.938L62.7853 12.9562C62.9693 12.4039 63.3375 11.9744 63.7671 11.5448C64.1966 11.1153 64.7489 10.8084 65.3625 10.6244C65.9762 10.3789 66.6512 10.3175 67.3262 10.3175C68.0012 10.3175 68.983 10.5016 69.5966 10.8084C70.2716 11.1153 70.7625 11.5448 71.1307 12.0971C71.4989 12.6494 71.683 13.263 71.683 13.9994V20.9948H69.3512V19.1539H69.8421C69.5966 19.5834 69.2284 19.9516 68.8603 20.2584C68.4921 20.5653 68.0625 20.8107 67.5716 20.9334C67.0807 21.1175 66.5284 21.1789 65.9762 21.1789V21.3016Z'
            fill='currentColor'
        />
        <path
            d='M73.3376 21.057V10.6251H75.6695V12.8956L75.4854 12.5888C75.7308 11.8524 76.099 11.3001 76.6513 10.9933C77.2036 10.6865 77.8172 10.5638 78.5536 10.5638H79.1672V12.7115H78.2467C77.5104 12.7115 76.8967 12.957 76.4672 13.3865C76.0376 13.8161 75.7922 14.4297 75.7922 15.2888V21.1797H73.3376V21.057Z'
            fill='currentColor'
        />
        <path
            d='M79.7788 24.9233V10.6256H82.1106V12.712L81.8652 12.2211C82.2334 11.6688 82.7243 11.2392 83.3993 10.8711C84.0129 10.5642 84.7493 10.3802 85.6084 10.3802C86.4674 10.3802 87.5106 10.6256 88.3697 11.1165C89.1674 11.6074 89.8425 12.2211 90.272 13.0802C90.7629 13.8779 91.0084 14.7983 91.0084 15.8415C91.0084 16.8847 90.7629 17.7438 90.3334 18.6029C89.8425 19.4006 89.2288 20.0756 88.4311 20.5665C87.6334 21.0574 86.7129 21.3029 85.6697 21.3029C84.6265 21.3029 84.1356 21.1188 83.4606 20.812C82.7856 20.5052 82.2947 20.0756 81.9265 19.462L82.2947 18.9711V24.862H79.8402L79.7788 24.9233ZM85.3015 19.0938C85.9152 19.0938 86.4674 18.9711 86.897 18.6642C87.3879 18.3574 87.7561 17.9892 88.0015 17.4983C88.247 17.0074 88.3697 16.4552 88.3697 15.8415C88.3697 15.2279 88.247 14.6756 88.0015 14.1847C87.7561 13.6938 87.3879 13.3256 86.897 13.0188C86.4061 12.712 85.9152 12.5892 85.3015 12.5892C84.6879 12.5892 84.197 12.712 83.7061 13.0188C83.2152 13.2642 82.847 13.6938 82.6015 14.1847C82.3561 14.6756 82.2334 15.2279 82.2334 15.8415C82.2334 16.4552 82.3561 17.0074 82.6015 17.4983C82.847 17.9892 83.2152 18.3574 83.7061 18.6642C84.197 18.9711 84.6879 19.0938 85.3015 19.0938Z'
            fill='currentColor'
        />
        <path
            d='M97.6374 21.3021C96.717 21.3021 95.8579 21.118 95.0601 20.8112C94.2624 20.5044 93.5874 20.0134 93.0351 19.3998C92.4829 18.7862 92.0533 18.1112 91.8079 17.3134L93.9556 16.393C94.3238 17.2521 94.8147 17.9271 95.4897 18.3566C96.1647 18.8475 96.901 19.0316 97.7601 19.0316C98.6192 19.0316 98.6806 18.9703 99.0488 18.7862C99.417 18.6021 99.6624 18.418 99.9079 18.1725C100.092 17.9271 100.215 17.5589 100.215 17.1907C100.215 16.8225 100.092 16.3316 99.7851 16.0862C99.5397 15.7794 99.1101 15.5953 98.5579 15.4112L95.7351 14.5521C94.6306 14.1839 93.7715 13.693 93.1579 13.018C92.6056 12.343 92.2988 11.5453 92.2988 10.5634C92.2988 9.58162 92.4829 9.02935 92.9124 8.41572C93.342 7.80208 93.8942 7.31117 94.6306 6.94299C95.367 6.57481 96.226 6.39072 97.1465 6.39072C98.0669 6.39072 98.8033 6.51344 99.5397 6.82026C100.276 7.12708 100.89 7.55663 101.442 8.04753C101.994 8.59981 102.362 9.21344 102.608 9.88844L100.521 10.8089C100.215 10.0725 99.7851 9.52026 99.2329 9.09072C98.6192 8.72253 97.9442 8.47708 97.2079 8.47708C96.4715 8.47708 96.3488 8.53844 95.9806 8.72253C95.6124 8.90663 95.367 9.09072 95.1829 9.33617C94.9988 9.58162 94.876 9.94981 94.876 10.318C94.876 10.6862 94.9988 11.1157 95.3056 11.4225C95.551 11.7294 95.9806 11.9748 96.5942 12.1589L99.2329 12.9566C100.399 13.3248 101.319 13.8157 101.871 14.4907C102.485 15.1657 102.731 15.9634 102.731 16.8839C102.731 17.8044 102.485 18.418 102.056 19.0316C101.626 19.6453 101.012 20.1362 100.276 20.5044C99.5397 20.8725 98.6192 21.0566 97.6374 21.0566V21.3021Z'
            fill='currentColor'
        />
        <path
            d='M108.988 21.1799C107.822 21.1799 106.902 20.8731 106.227 20.2595C105.613 19.6458 105.245 18.7254 105.245 17.5595V12.7731H103.404V10.6254H103.649C104.14 10.6254 104.57 10.5026 104.877 10.1958C105.183 9.95036 105.306 9.52081 105.306 9.0299V8.23218H107.761V10.6254H110.215V12.7731H107.761V17.4367C107.761 17.8049 107.761 18.0504 107.945 18.3572C108.067 18.6026 108.252 18.7867 108.497 18.9095C108.742 19.0322 109.111 19.0935 109.54 19.0935C109.97 19.0935 109.724 19.0935 109.847 19.0935C109.97 19.0935 110.092 19.0935 110.215 19.0935V21.1185C110.031 21.1185 109.847 21.1185 109.602 21.1799C109.356 21.1799 109.172 21.1799 108.988 21.1799Z'
            fill='currentColor'
        />
        <path
            d='M111.444 21.057V10.6251H113.776V12.8956L113.592 12.5888C113.837 11.8524 114.205 11.3001 114.757 10.9933C115.31 10.6865 115.923 10.5638 116.66 10.5638H117.273V12.7115H116.353C115.617 12.7115 115.003 12.957 114.573 13.3865C114.144 13.8161 113.898 14.4297 113.898 15.2888V21.1797H111.444V21.057Z'
            fill='currentColor'
        />
        <path
            d='M122.981 21.3023C121.876 21.3023 120.956 21.0568 120.097 20.5659C119.299 20.075 118.624 19.4 118.195 18.6023C117.765 17.8045 117.52 16.8227 117.52 15.8409C117.52 14.8591 117.765 13.8773 118.195 13.0795C118.685 12.2818 119.299 11.6068 120.097 11.1773C120.895 10.6863 121.815 10.4409 122.797 10.4409C123.779 10.4409 124.331 10.5636 124.945 10.8704C125.558 11.1159 126.11 11.4841 126.54 11.975C126.97 12.4659 127.338 13.0182 127.583 13.5704C127.829 14.1841 127.951 14.8591 127.951 15.5341C127.951 16.2091 127.951 15.9022 127.951 16.0863C127.951 16.2704 127.951 16.4545 127.89 16.5773H119.545V14.7363H126.479L125.313 15.5954C125.435 14.9818 125.435 14.4295 125.251 14C125.067 13.5091 124.76 13.1409 124.331 12.8954C123.901 12.65 123.41 12.4659 122.858 12.4659C122.306 12.4659 121.815 12.5886 121.385 12.8954C120.956 13.1409 120.649 13.5704 120.404 14.0613C120.158 14.5523 120.097 15.2273 120.158 15.9023C120.097 16.5773 120.158 17.1909 120.404 17.6818C120.649 18.1727 121.017 18.6023 121.447 18.8477C121.938 19.1545 122.429 19.2773 123.042 19.2773C123.656 19.2773 124.208 19.1545 124.576 18.9091C125.006 18.6636 125.313 18.2954 125.62 17.9272L127.583 18.8477C127.399 19.3386 127.031 19.7682 126.601 20.1363C126.172 20.5045 125.62 20.8113 125.006 21.0568C124.392 21.3022 123.717 21.3636 122.981 21.3636V21.3023Z'
            fill='currentColor'
        />
        <path
            d='M132.371 21.3021C131.635 21.3021 131.021 21.1794 130.469 20.9339C129.916 20.6885 129.487 20.3203 129.18 19.8908C128.873 19.4612 128.75 18.9089 128.75 18.2953C128.75 17.6817 128.873 17.2521 129.119 16.7612C129.364 16.3317 129.794 15.9021 130.346 15.5953C130.898 15.2885 131.573 15.043 132.432 14.9203L135.991 14.368V16.2089L132.923 16.7612C132.371 16.8226 132.003 17.0067 131.696 17.2521C131.45 17.4976 131.328 17.8044 131.328 18.1726C131.328 18.5408 131.45 18.8476 131.757 19.093C132.064 19.3385 132.432 19.3998 132.862 19.3998C133.291 19.3998 133.905 19.2771 134.335 19.0317C134.764 18.7862 135.071 18.4794 135.316 18.0498C135.562 17.6203 135.685 17.1908 135.685 16.6998V13.9998C135.685 13.5089 135.5 13.1408 135.132 12.8339C134.764 12.5271 134.273 12.343 133.66 12.343C133.046 12.343 132.616 12.4658 132.187 12.7726C131.757 13.0794 131.45 13.4476 131.205 13.9385L129.18 12.9567C129.364 12.4044 129.732 11.9748 130.162 11.5453C130.591 11.1158 131.144 10.8089 131.757 10.6248C132.371 10.3794 133.046 10.318 133.721 10.318C134.396 10.318 135.378 10.5021 135.991 10.8089C136.666 11.1158 137.157 11.5453 137.525 12.0976C137.894 12.6498 138.078 13.2635 138.078 13.9998V20.9953H135.746V19.1544H136.237C135.991 19.5839 135.623 19.9521 135.255 20.2589C134.887 20.5658 134.457 20.8112 133.966 20.9339C133.475 21.118 132.923 21.1794 132.371 21.1794V21.3021Z'
            fill='currentColor'
        />
        <path
            d='M139.732 21.0568V10.625H142.064V13.0182L141.819 12.65C142.003 11.9136 142.432 11.3613 143.046 10.9932C143.66 10.625 144.335 10.4409 145.132 10.4409C145.93 10.4409 146.789 10.6863 147.464 11.1159C148.139 11.5454 148.569 12.1591 148.753 12.8954H148.078C148.385 12.0977 148.876 11.4841 149.551 11.0545C150.226 10.625 150.962 10.4409 151.821 10.4409C152.68 10.4409 153.232 10.625 153.846 10.9318C154.46 11.2386 154.889 11.7295 155.257 12.3432C155.626 12.9568 155.81 13.6318 155.81 14.3682V21.1182H153.355V14.9818C153.355 14.4909 153.294 14.0613 153.11 13.7545C152.926 13.3863 152.68 13.1409 152.373 12.9568C152.067 12.7727 151.698 12.65 151.269 12.65C150.839 12.65 150.471 12.7727 150.164 12.9568C149.857 13.1409 149.612 13.3863 149.428 13.7545C149.244 14.1227 149.182 14.4909 149.182 14.9818V21.1182H146.728V14.9818C146.728 14.4909 146.667 14.0613 146.482 13.7545C146.298 13.3863 146.053 13.1409 145.746 12.9568C145.439 12.7727 145.071 12.65 144.642 12.65C144.212 12.65 143.844 12.7727 143.537 12.9568C143.23 13.1409 142.985 13.3863 142.801 13.7545C142.617 14.1227 142.555 14.4909 142.555 14.9818V21.1182H140.101L139.732 21.0568Z'
            fill='currentColor'
        />
        <path
            d='M2.76916 15.9636L1.97143 16.7614L0.375977 18.2954V18.5409L1.97143 20.075L4.36461 22.4068V14.4295L2.76916 15.9636Z'
            fill='currentColor'
        />
        <path
            d='M8.29027 17.1294L6.69482 15.5953V12.0975L5.83573 12.8953V14.7362L5.09937 13.9998V23.143L6.69482 24.6771L7.49255 25.4748L9.088 27.0089V9.76572L8.29027 10.5634V17.1294Z'
            fill='currentColor'
        />
        <path
            d='M13.0798 15.0428L11.4844 16.5769V11.0541V7.49506L10.6866 8.29278V17.436L9.88892 18.2337V27.3769L12.2821 25.0451L13.8776 23.511V5.16324L13.0798 5.96097V15.1041V15.0428Z'
            fill='currentColor'
        />
        <path
            d='M17.8035 10.3795L16.208 11.9136V2.83182L15.4103 3.62955V12.7727L14.6125 13.5705V22.7136L16.208 21.1795L17.0057 20.3818L18.6012 18.8477V0.5L17.8035 1.29773V10.4409V10.3795Z'
            fill='currentColor'
        />
        <path
            d='M22.956 3.99775L22.5878 3.62957V12.7728L20.9923 11.2387V1.97275L20.6242 1.60457L20.256 1.23639V10.3796L19.4583 9.58184V18.725L21.0537 20.2591L21.8514 21.0568L23.4469 22.5909V4.30457L23.0787 3.93639L22.956 3.99775Z'
            fill='currentColor'
        />
        <path
            d='M27.7446 8.60026L27.3765 8.23208V16.7616V17.3753L26.5787 16.5775L25.781 15.7798V6.57526L25.3515 6.20708L24.9833 5.8389V14.9821L24.1855 14.1844V23.3275L25.781 24.8616L26.5787 25.6594L28.1742 27.1934V8.96844L27.806 8.60026H27.7446Z'
            fill='currentColor'
        />
        <path
            d='M32.8976 13.5701L32.0998 12.8338V14.8588L30.5044 16.3929V11.2997L29.7067 10.5633V17.1906L28.9089 17.9883V27.1315L30.5044 25.5974L31.3021 24.7997L32.8976 23.2656V13.6315V13.5701Z'
            fill='currentColor'
        />
        <path
            d='M36.0257 16.6386L35.228 15.9023L33.6326 14.3682V22.4682L35.228 20.9341L36.0257 20.1364L37.6212 18.6023V18.1727L36.0257 16.6386Z'
            fill='currentColor'
        />
    </svg>
)

export default WarpStream
