import React from 'react'
import * as myStyles from './styles.module.scss'
import { BannerProps } from './types'

const Banner = ({ title, linkUrl, linkLabel }: BannerProps) => {
    const styles: any = myStyles
    return (
        <div className={styles.c_banner}>
            <div className={styles.wrapper}>
                <h2 className={styles.title}>{title}</h2>
                <a className={styles.link} href={linkUrl}>
                    {linkLabel}
                </a>
            </div>
        </div>
    )
}

export default Banner
