import React, { useEffect, useState } from 'react'
import * as myStyles from './styles.module.scss'
import Navigation from '../../basics/navigation'
import { HamburgerIcon } from '../../basics/icons'
import ClickableArea from '../../basics/clickable-area/component'
import { MobileNavigationProps } from './types'
import AccordionComponent from '../../basics/accordion'
import CrossIcon from '../../basics/icons/cross'
import Icon from '../../basics/icon'

const MobileNavigation = ({
    nav,
    setIsMobileOpen,
    primaryCtaLink,
    primaryCtaText,
    secondaryCtaLink,
    secondaryCtaText,
}: MobileNavigationProps) => {
    const [currentValue, setCurrentValue] = useState<string>()
    const isOpen = currentValue === 'open'
    const styles: any = myStyles

    useEffect(() => {
        setIsMobileOpen(isOpen)
    }, [isOpen, setIsMobileOpen])

    return (
        <Navigation
            data-is-open={isOpen}
            currentValue={currentValue}
            setCurrentValue={(value) => setCurrentValue(value)}
            className={styles.c_mobile_navigation}
            contentClass={styles['content_wrapper']}>
            <Navigation.Item value='open'>
                <Navigation.Trigger
                    onPointerLeave={(e: any) => e.preventDefault()}
                    onPointerMove={(e: any) => e.preventDefault()}
                    className={styles.trigger}>
                    {isOpen ? <Icon icon={<CrossIcon />} size='xl' /> : <HamburgerIcon />}
                </Navigation.Trigger>
                <Navigation.Content
                    onPointerEnter={(e: any) => e.preventDefault()}
                    onPointerLeave={(e: any) => e.preventDefault()}
                    className={styles['navigation_menu_content']}>
                    <AccordionComponent
                        disableLineAnimation
                        type='single'
                        collapsible
                        className={styles['menu_accordion']}>
                        {nav.map(({ section, items }, index) => (
                            <AccordionComponent.Item
                                key={index}
                                value={`item-${index}`}
                                className={styles['menu_accordion_item']}>
                                <AccordionComponent.Trigger className={styles.accordion_trigger}>
                                    {section}
                                </AccordionComponent.Trigger>
                                <AccordionComponent.Content className={styles.accordion_content}>
                                    {items.map(({ title, link, target }, itemIndex) => (
                                        <Navigation.Link
                                            key={`${index}-${itemIndex}`}
                                            href={link}
                                            target={target}
                                            className={styles.link}>
                                            {title}
                                        </Navigation.Link>
                                    ))}
                                </AccordionComponent.Content>
                            </AccordionComponent.Item>
                        ))}
                    </AccordionComponent>

                    <div className={styles.action_wrapper}>
                        {primaryCtaLink && primaryCtaText && (
                            <ClickableArea
                                variant='primary'
                                color='orange'
                                size='m'
                                href={primaryCtaLink}>
                                {primaryCtaText}
                            </ClickableArea>
                        )}
                        {secondaryCtaLink && secondaryCtaText && (
                            <ClickableArea
                                variant='secondary'
                                color='dark'
                                size='m'
                                href={secondaryCtaLink}>
                                {secondaryCtaText}
                            </ClickableArea>
                        )}
                    </div>
                </Navigation.Content>
            </Navigation.Item>
        </Navigation>
    )
}

export default MobileNavigation
