import React from 'react'

const ArticleLogo = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='148'
        height='20'
        viewBox='0 0 148 20'
        fill='none'>
        <g clipPath='url(#clip0_9058_1687)'>
            <path
                d='M13.6221 15.6616H4.89108L3.53723 19.2161H0L7.23938 0.705322H10.0751C10.429 0.705505 10.7746 0.812516 11.0666 1.01235C11.3587 1.21219 11.5836 1.49554 11.712 1.82532L18.5132 19.221H14.976L13.6221 15.6616ZM5.80431 12.8087H12.7114L9.26523 3.45486L5.80431 12.8087Z'
                fill='currentColor'
            />
            <path
                d='M32.4926 12.3089H29.2926V19.2209H26.0557V0.7052H34.1517C37.7997 0.7052 40.175 3.09289 40.175 6.50705C40.175 9.81043 38.0187 11.5877 35.8107 11.947L40.3154 19.2209H36.5738L32.4926 12.3089ZM33.7086 3.56551H29.2778V9.45105H33.7086C35.5326 9.45105 36.8594 8.28428 36.8594 6.4972C36.8594 4.71012 35.5326 3.56551 33.7086 3.56551Z'
                fill='currentColor'
            />
            <path
                d='M52.508 3.56561H46.9277V2.47269C46.9271 2.00568 47.1118 1.5575 47.4413 1.22659C47.7709 0.895669 48.2183 0.709068 48.6853 0.707764H61.3228V3.56561H55.7425V19.221H52.508V3.56561Z'
                fill='currentColor'
            />
            <path
                d='M68.7686 0.705322H70.2455C70.7125 0.706626 71.1599 0.893227 71.4894 1.22414C71.8189 1.55506 72.0037 2.00324 72.003 2.47025V19.2087H68.7686V0.705322Z'
                fill='currentColor'
            />
            <path
                d='M79.5371 9.97787C79.5371 4.28679 83.7636 0.400024 89.0706 0.400024C92.9377 0.400024 95.2245 2.34218 96.6128 4.56495L93.851 5.98033C93.3578 5.15394 92.6573 4.47073 91.8188 3.99838C90.9803 3.52604 90.033 3.28096 89.0706 3.28741C85.558 3.28741 82.8503 6.0911 82.8503 9.97787C82.8503 13.8646 85.558 16.6683 89.0682 16.6683C90.0316 16.6805 90.9811 16.4379 91.8206 15.965C92.6601 15.4921 93.3597 14.8057 93.8485 13.9754L96.6103 15.3908C95.2023 17.6062 92.9353 19.5557 89.0682 19.5557C83.7636 19.5557 79.5371 15.6616 79.5371 9.97787Z'
                fill='currentColor'
            />
            <path
                d='M105.949 0.705322H107.426V16.3632H115.549V19.221H104.191V2.48255C104.19 2.25028 104.234 2.01991 104.321 1.80469C104.408 1.58947 104.537 1.39365 104.701 1.22849C104.864 1.06333 105.058 0.932082 105.273 0.842298C105.487 0.752514 105.717 0.705963 105.949 0.705322Z'
                fill='currentColor'
            />
            <path
                d='M124.943 0.705322H135.813V3.56563H126.42V8.36809H135.621V11.2309H126.42V16.3632H135.813V19.221H123.186V2.48255C123.184 2.25028 123.228 2.01991 123.315 1.80469C123.402 1.58947 123.532 1.39365 123.695 1.22849C123.858 1.06333 124.053 0.932082 124.267 0.842298C124.481 0.752514 124.711 0.705963 124.943 0.705322Z'
                fill='currentColor'
            />
            <path
                d='M144.613 15.066C145.111 14.8916 145.653 14.8913 146.151 15.065C146.649 15.2388 147.073 15.5764 147.354 16.0225C147.635 16.4687 147.756 16.997 147.698 17.521C147.639 18.0451 147.405 18.5338 147.033 18.9073C146.661 19.2808 146.173 19.5169 145.649 19.577C145.125 19.6371 144.596 19.5176 144.149 19.2382C143.702 18.9587 143.363 18.5357 143.187 18.0385C143.012 17.5413 143.01 16.9992 143.183 16.501C143.298 16.1697 143.487 15.8686 143.734 15.6202C143.982 15.3717 144.282 15.1823 144.613 15.066Z'
                fill='currentColor'
            />
        </g>
        <defs>
            <clipPath id='clip0_9058_1687'>
                <rect
                    width='147.692'
                    height='19.2'
                    fill='white'
                    transform='translate(0 0.400024)'
                />
            </clipPath>
        </defs>
    </svg>
)

export default ArticleLogo
