import React from 'react'

const HoneyBookLogo = () => (
    <svg
        width='147'
        height='14'
        viewBox='0 0 147 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_1457_12786)'>
            <path
                d='M135.458 5.321C139.254 5.321 140.991 3.048 141.895 0H145.852C145.027 2.617 143.585 5.305 141.512 6.583C143.546 7.703 145.522 10.063 146.523 13.999H142.589C141.609 10.029 139.278 8.311 135.459 8.311L135.468 13.999H131.532V0H135.466L135.458 5.321ZM126.126 7.003C126.126 4.113 124.321 2.893 122.077 2.893C119.834 2.893 118.006 4.111 118.006 7.003C118.006 9.894 119.828 11.113 122.077 11.113C124.326 11.113 126.126 9.894 126.126 7.003ZM113.971 7.003C113.971 2.609 117.466 0.005 122.074 0.005C126.681 0.005 130.157 2.612 130.157 7.003C130.157 11.393 126.682 14 122.074 14C117.465 14 113.971 11.393 113.971 7.003ZM108.791 7.003C108.791 4.113 106.987 2.893 104.739 2.893C102.491 2.893 100.668 4.111 100.668 7.003C100.668 9.894 102.49 11.113 104.739 11.113C106.988 11.113 108.791 9.894 108.791 7.003ZM96.6367 7.003C96.6377 2.609 100.132 0.005 104.739 0.005C109.346 0.005 112.822 2.612 112.822 7.003C112.822 11.393 109.348 14 104.739 14C100.13 14 96.6367 11.393 96.6367 7.003Z'
                fill='currentcolor'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M95.7919 9.557C95.7919 7.767 94.5629 6.47 92.8159 5.921C93.7189 5.369 94.2559 4.367 94.2559 3.304C94.2559 1.417 92.6249 0 89.7429 0H80.8789V14H89.4549C93.7949 14 95.7919 12.152 95.7919 9.557ZM84.8169 2.753L88.9389 2.747C90.1569 2.747 90.5509 3.197 90.5509 3.727C90.5509 4.73 88.1939 5.71 84.8169 5.975V2.753ZM91.1559 10.767C91.5359 10.545 91.8039 10.286 91.9319 9.997C92.2799 9.217 91.7319 8.259 90.8589 8.079C90.4249 7.989 89.7179 7.972 88.7479 8.228L88.3929 8.322C87.4379 8.576 86.5709 8.808 84.8249 9.056V11.238H89.1489C89.8319 11.208 90.5579 11.116 91.1559 10.767Z'
                fill='currentcolor'
            />
            <path
                d='M74.119 14H70.185V8.91L64.442 0.004H68.782L72.186 5.311L75.596 0.004H79.86L74.119 8.91V14ZM50.992 0H63.472V2.99H54.93V5.323H62.897V8.313H54.93V11.01H63.665V14H50.992V0ZM37.95 14H34.015V0.001H38.911L45.112 8.651V0H49.046V14H44.879L37.949 4.346L37.95 14ZM28.631 6.997C28.631 4.107 26.827 2.887 24.583 2.887C22.339 2.887 20.512 4.106 20.512 6.997C20.512 9.889 22.334 11.107 24.582 11.107C26.832 11.107 28.631 9.889 28.631 6.997ZM16.477 6.997C16.477 2.604 19.972 0 24.579 0C29.186 0 32.662 2.607 32.662 6.997C32.662 11.388 29.188 13.995 24.579 13.995C19.971 13.995 16.477 11.388 16.477 6.997ZM15.03 0H11.093V6.117C7.833 5.963 5.426 5.33 3.938 4.503V0H0V13.999H3.938V7.849C6.323 8.717 8.647 9.159 11.093 9.207V13.999H15.031V0H15.03Z'
                fill='currentcolor'
            />
        </g>
        <defs>
            <clipPath id='clip0_1457_12786'>
                <rect width='147' height='14' fill='white' />
            </clipPath>
        </defs>
    </svg>
)

export default HoneyBookLogo
