import React from 'react'

const AboutIcon = () => (
    <svg
        width='162'
        height='206'
        viewBox='0 0 162 206'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_1429_12759)'>
            <path
                d='M81.0006 3C38.4206 3 3.89062 37.53 3.89062 80.11C3.89062 117.98 31.2206 149.48 67.2206 155.97V203L94.9806 193.68V155.9C130.861 149.38 158.101 117.88 158.101 80.12C158.101 37.54 123.581 3.00999 80.9906 3.00999'
                stroke='currentcolor'
                strokeWidth='6'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M80.8383 128.62C54.1083 128.62 32.4883 106.94 32.4883 80.2797C32.4883 53.6197 54.1683 31.9297 80.8383 31.9297C107.508 31.9297 129.188 53.6097 129.188 80.2797C129.188 106.95 107.558 128.62 80.8383 128.62Z'
                stroke='#FF4802'
                strokeWidth='6'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </g>
        <defs>
            <clipPath id='clip0_1429_12759'>
                <rect width='162' height='206' fill='white' />
            </clipPath>
        </defs>
    </svg>
)

export default AboutIcon
