import React from 'react'

const FlippLogo = () => (
    <svg
        width='2048'
        height='1436'
        viewBox='0 0 2048 1436'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M682.8 786.202C699.7 837.602 779.2 855.702 824.5 826.202C835.4 819.102 844.7 807.702 852.2 795.702C859 784.802 847.2 771.702 835.7 777.502C834.1 778.302 832.5 779.102 830.8 779.802C810 788.902 797.7 778.002 803.9 756.102C804.5 754.102 805.1 751.702 806 748.702C836.9 635.002 871.2 522.202 891.7 449.502C894.3 440.102 886 431.402 876.5 433.702L766.5 455.002C762 456.102 758.4 459.402 757.2 463.802C744.6 507.602 721.2 600.202 688.3 697.202C675.3 735.502 675.6 764.402 682.8 786.202Z'
            fill='currentcolor'
        />
        <path
            d='M860.898 400.801C895.692 400.801 923.898 372.595 923.898 337.801C923.898 303.007 895.692 274.801 860.898 274.801C826.104 274.801 797.898 303.007 797.898 337.801C797.898 372.595 826.104 400.801 860.898 400.801Z'
            fill='currentcolor'
        />
        <path
            d='M684.6 302.299L574.7 323.599C570.2 324.699 566.6 327.999 565.4 332.399C552.8 376.199 491.4 598.099 458.5 695.099C445.6 733.299 445.8 762.299 453 784.099C469.9 835.499 548.4 855.399 593.7 825.799C609 815.799 616.1 803.499 619.3 793.199C621.7 785.499 614.5 778.299 606.8 780.699C601.9 782.199 595.4 783.699 587 784.999C578.6 786.199 571 779.899 570.7 771.499C570.4 763.299 571 763.099 575.1 748.299C606 634.599 679.3 390.699 699.8 318.099C702.5 308.699 694.1 299.999 684.6 302.299Z'
            fill='currentcolor'
        />
        <path
            d='M311.701 566.6C313.101 562.2 314.601 557.8 316.101 553.3L427.901 531.7C433.201 530.7 437.501 526.9 439.201 521.7L461.101 454.9C464.601 444.1 455.301 433.5 444.101 435.6L347.601 454.3C380.701 349.2 416.801 236.9 446.401 189.6C501.901 101 564.001 140.2 571.501 145.4C547.501 154.3 530.301 177.3 530.301 204.4C530.301 238.5 550.401 267 600.001 267C663.901 267 697.701 217.2 697.701 164.9C697.701 96.2 643.401 53.5 568.901 35.2C473.901 11.9 369.501 53.7 319.801 136C286.001 192 236.001 353.9 204.901 461.8L204.701 461.6C203.601 465.5 201.501 472.8 198.501 483L59.1007 510C53.8007 511 49.5007 514.8 47.8007 520L25.9007 586.8C22.4007 597.6 31.7007 608.2 42.9007 606.1L170.001 581.6L153.601 637.9C110.401 786.2 52.0007 984.8 26.3007 1064.9C23.7007 1073.1 31.0007 1081 39.4007 1079L58.0007 1074.5C125.801 1058.2 179.801 1007.1 199.701 940.3C211.901 899.2 260.601 733.9 288.201 639.9L311.701 566.6Z'
            fill='currentcolor'
        />
        <path
            d='M1411.7 954.999C1412.4 952.999 1451.5 823.099 1451.5 823.099C1451.5 823.099 1464.2 869.699 1539.4 869.699C1614 869.699 1722.4 823.099 1757.8 692.899C1793.9 560.199 1774.5 465.699 1687.1 437.799C1629.9 417.899 1548.9 481.299 1548.9 481.299C1552.5 468.599 1557.4 455.999 1559.8 447.599C1562.4 438.199 1554.1 429.499 1544.6 431.799L1430.9 452.899C1426.4 453.999 1422.8 457.299 1421.6 461.699C1412.5 493.299 1334.8 761.199 1281.4 939.599C1321.5 945.199 1363.4 950.499 1411.7 954.999ZM1529 551.199C1559.2 519.699 1594.5 505.199 1623 516.999C1668.1 535.699 1678.8 613.399 1646.8 690.499C1614.8 767.599 1552.3 814.899 1507.2 796.199C1490.3 789.199 1478.2 773.899 1471.4 753.499L1529 551.199Z'
            fill='currentcolor'
        />
        <path
            d='M990.601 898.199C1002.8 857.299 1013 823.099 1013 823.099C1013 823.099 1025.7 869.699 1100.9 869.699C1175.5 869.699 1283.9 823.099 1319.3 692.899C1355.4 560.199 1336 465.699 1248.6 437.799C1191.4 417.899 1110.4 481.299 1110.4 481.299C1114 468.599 1118.9 455.999 1121.3 447.599C1123.9 438.199 1115.5 429.499 1106.1 431.799L992.401 452.899C987.901 453.999 984.301 457.299 983.101 461.699C974.701 490.699 911.501 709.699 859.801 883.799C903.701 887.299 946.801 892.799 990.601 898.199ZM1090.5 551.199C1120.7 519.699 1156 505.199 1184.5 516.999C1229.6 535.699 1240.3 613.399 1208.3 690.499C1176.3 767.599 1113.8 814.899 1068.7 796.199C1051.8 789.199 1039.7 773.899 1032.9 753.499L1090.5 551.199Z'
            fill='currentcolor'
        />
        <path
            d='M1943.4 873.202C1943.4 873.202 1933.9 870.302 1924.8 882.102C1897.7 917.402 1821.5 989.702 1646.9 1003.2C1436.6 1019.5 1177.3 964.902 1029.2 946.902C878.501 928.602 658.901 874.302 403.101 964.202C230.401 1025 192.501 1167.3 221.001 1264.1C257.601 1388.5 470.801 1429.5 596.501 1395.8C722.701 1362 866.801 1269.8 929.801 1109.8L798.701 1093.6C798.701 1093.6 717.401 1238.4 578.001 1283.1C456.401 1322.1 338.101 1269.7 343.301 1206.7C351.401 1108.5 503.601 991.502 1011 1077C1306.2 1126.7 1537.7 1141.8 1645.3 1141.8C1952.1 1141.8 2014.1 1063 2014.1 1063C2014.1 1063 2059.6 930.702 1943.4 873.202Z'
            fill='currentcolor'
        />
    </svg>
)

export default FlippLogo
