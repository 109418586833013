import React from 'react'

const CameoLogo = () => {
    return (
        <svg
            width='137'
            height='34'
            viewBox='0 0 137 34'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M129.227 5.12019C127.231 5.02193 125.277 5.71184 123.785 7.04119C122.398 8.48219 121.918 10.1362 121.918 13.8172V21.2332C121.918 24.9152 122.345 26.5692 123.785 28.0092C125.28 29.3332 127.232 30.0223 129.227 29.9302C130.208 29.9837 131.189 29.8414 132.115 29.5115C133.04 29.1817 133.89 28.671 134.616 28.0092C136.003 26.6222 136.483 25.0212 136.483 21.2332V13.8202C136.483 10.0322 136.056 8.48419 134.616 7.04419C133.89 6.38182 133.04 5.87055 132.115 5.54022C131.19 5.20989 130.208 5.0671 129.227 5.12019ZM132.217 21.5002C132.217 24.6482 131.257 26.0352 129.176 26.0352C127.042 26.0352 126.188 24.6482 126.188 21.5002V13.5002C126.188 10.3522 127.095 8.96518 129.176 8.96518C131.31 8.96518 132.217 10.3522 132.217 13.5002V21.5002Z'
                fill='currentcolor'
            />
            <path
                d='M45.1914 5.11969C42.4704 5.11969 40.4424 5.97368 39.2694 7.57368C38.2024 9.01468 37.7754 10.7197 37.7754 14.0297V21.0197C37.7754 24.2747 38.1484 25.9817 39.2694 27.4197C40.4434 29.0197 42.5244 29.8737 45.1914 29.8737C46.2274 29.9345 47.2643 29.7704 48.2309 29.3927C49.1975 29.0151 50.071 28.4327 50.7914 27.6857C51.9654 26.4047 52.4454 24.7507 52.4454 21.9237V21.1237H48.2334V21.7107C48.2334 24.6987 47.2734 26.0857 45.1924 26.0857C44.672 26.1112 44.1543 25.9961 43.6938 25.7523C43.2333 25.5086 42.847 25.1453 42.5754 24.7007C42.0954 23.8467 41.9884 22.7797 41.9884 20.4857V14.6197C41.9884 10.3517 42.7884 8.96369 45.1884 8.96369C47.2694 8.96369 48.2294 10.2977 48.2294 13.1787V13.8727H52.4444V12.9657C52.4444 10.1917 51.9644 8.59069 50.7904 7.30968C50.0735 6.55822 49.2007 5.97278 48.2335 5.59445C47.2662 5.21612 46.2279 5.05407 45.1914 5.11969Z'
                fill='currentcolor'
            />
            <path
                d='M113.863 23.9014C113.726 24.5376 113.366 25.1036 112.846 25.4955C112.327 25.8874 111.684 26.0791 111.035 26.0353C109.114 26.0353 108.26 25.1813 107.993 22.9403C107.886 22.2463 107.833 20.7523 107.833 20.6463V20.5404L107.94 20.4873L118.24 14.9383V14.2984C118.24 10.6164 117.76 8.85635 116.426 7.30835C115.719 6.55679 114.855 5.9709 113.894 5.59263C112.934 5.21437 111.902 5.05309 110.872 5.12035C109.892 5.06686 108.91 5.20918 107.985 5.53899C107.06 5.8688 106.209 6.37952 105.483 7.04135C103.989 8.48235 103.562 9.97635 103.562 13.8174V20.9674C103.562 24.1154 103.99 25.9293 105.057 27.4233C106.231 29.0233 108.312 29.8773 110.979 29.8773C114.02 29.8773 116.207 28.7573 117.327 26.7293C118.021 25.6093 118.181 24.4353 118.234 21.1803H114.02C114.115 22.0899 114.062 23.0088 113.863 23.9014ZM107.779 12.9634C107.779 10.0284 108.686 8.80135 110.874 8.80135C112.955 8.80135 113.915 9.97535 113.969 12.6433V12.7503L113.863 12.8034L107.727 16.1643L107.779 12.9634Z'
                fill='currentcolor'
            />
            <path
                d='M92.5191 5.12072H92.4121C90.5497 5.01701 88.7179 5.6275 87.2901 6.82772L87.1831 6.93472L87.0761 6.82772C85.6359 5.64986 83.813 5.04235 81.9541 5.12072H81.8471C79.1791 5.12072 77.2051 5.97472 76.0311 7.57472C74.9641 9.06872 74.5371 10.8827 74.5371 14.0307V29.3977H78.7521V14.2977C78.7521 14.2447 78.8051 12.6977 78.9121 12.0037C79.2321 9.81572 80.0321 8.90872 81.9001 8.90872C83.7681 8.90872 84.6211 9.81572 84.8881 12.0037C84.9951 12.6977 85.0481 14.1917 85.0481 14.2977V29.3437H89.3161V14.2977C89.3161 14.2447 89.3691 12.6977 89.4761 12.0037C89.7961 9.81572 90.5961 8.90872 92.4641 8.90872C94.3321 8.90872 95.1851 9.81572 95.4521 12.0037C95.5591 12.6977 95.6121 14.1917 95.6121 14.2977V29.3437H99.8271V14.0307C99.8271 10.8827 99.4001 9.06872 98.3331 7.57472C97.1611 5.97472 95.1861 5.17372 92.5191 5.12072Z'
                fill='currentcolor'
            />
            <path
                d='M63.3859 5.12109C60.3449 5.12109 58.1569 6.24109 57.0369 8.26909C56.3429 9.38909 56.1829 10.5631 56.1299 13.8181H60.3449C60.2881 12.888 60.3598 11.9546 60.5579 11.0441C60.694 10.4078 61.0547 9.84188 61.5741 9.44995C62.0935 9.05803 62.7367 8.86637 63.3859 8.91009C65.3069 8.91009 66.1599 9.76409 66.4269 12.0051C66.5339 12.6991 66.5869 14.1931 66.5869 14.2991V14.4061L66.4799 14.4591L56.1289 20.0591V20.6991C56.1289 24.3811 56.6089 26.1411 57.9429 27.6891C58.6496 28.44 59.5134 29.0255 60.4727 29.4037C61.4319 29.782 62.4629 29.9436 63.4919 29.8771C64.4727 29.9306 65.4543 29.7883 66.3796 29.4585C67.3048 29.1286 68.1551 28.6179 68.8809 27.9561C70.3749 26.5151 70.8019 25.0211 70.8019 21.1801V14.0311C70.8019 10.8831 70.3749 9.06909 69.3079 7.57509C68.1299 5.97509 66.0539 5.12109 63.3859 5.12109ZM66.5299 22.0351C66.5299 24.9701 65.6229 26.1971 63.4349 26.1971C61.3539 26.1971 60.3939 25.0231 60.3399 22.3551V22.2481L60.4469 22.1951L66.5829 18.8341V22.0341L66.5299 22.0351Z'
                fill='currentcolor'
            />
            <path d='M19.5274 0H14.1914L15.8454 9.817H17.9264L19.5274 0Z' fill='currentcolor' />
            <path
                d='M14.1914 33.6686H19.5274L17.8734 23.8516H15.7914L14.1914 33.6686Z'
                fill='currentcolor'
            />
            <path d='M0 14.1914V19.4734L9.817 17.8734V15.7384L0 14.1914Z' fill='currentcolor' />
            <path
                d='M6.8307 3.03906L3.0957 6.77406L11.1527 12.5901L12.6467 11.0961L6.8307 3.03906Z'
                fill='currentcolor'
            />
            <path
                d='M26.8902 30.6252L30.6252 26.8902L22.5682 21.0742L21.0742 22.5682L26.8902 30.6252Z'
                fill='currentcolor'
            />
            <path
                d='M30.6252 6.77406L26.8902 3.03906L21.0742 11.1491L22.5682 12.6391L30.6252 6.77406Z'
                fill='currentcolor'
            />
            <path
                d='M3.0957 26.8924L6.8307 30.6234L12.6467 22.5134L11.1527 21.0234L3.0957 26.8924Z'
                fill='currentcolor'
            />
        </svg>
    )
}

export default CameoLogo
