import React from 'react'

const PoeLogo = () => (
    <svg width='54' height='56' viewBox='0 0 54 56' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_1457_12705)'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12.9884 0C19.4784 0 24.6964 4.244 25.5764 10.406C28.0484 3.57 33.6224 0.288 38.9714 0.288C46.1754 0.288 53.6814 6.154 53.6814 18.544C53.6814 30.934 46.1744 36.75 38.9704 36.75C31.8174 36.75 24.2614 30.934 24.2614 18.544L24.2634 18.323C23.5204 19.883 22.4594 21.532 21.0054 23.303L10.3154 36.023C10.0184 36.372 9.67136 36.521 9.27536 36.173L4.62336 32.198C4.22736 31.8 4.12836 31.552 4.47436 31.154L10.4644 24.198C4.77136 22.956 0.318359 18.633 0.318359 12.273C0.318359 5.118 5.91136 0 12.9884 0ZM8.18836 12.273C8.18836 15.155 10.3164 17.291 12.9884 17.291C15.6614 17.291 17.8384 15.155 17.8384 12.273C17.8384 9.689 15.6614 7.652 12.9884 7.652C10.2664 7.652 8.18836 9.689 8.18836 12.273ZM38.9714 28.963C34.1364 28.963 32.2714 23.551 32.2714 18.544C32.2714 13.538 34.1364 8.026 38.9714 8.026C43.8074 8.026 45.6714 13.538 45.6714 18.544C45.6714 23.551 43.8074 28.963 38.9714 28.963Z'
                fill='currentcolor'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M16.0634 44.1611C16.0714 47.6911 13.7124 50.1351 9.41139 50.1451L7.85139 50.1491L7.86339 55.6091C7.86339 55.8381 7.71339 55.9911 7.48639 55.9911L3.94039 55.9991C3.71339 55.9991 3.56239 55.8471 3.56239 55.6191L3.52539 38.6011C3.52539 38.3731 3.67539 38.2211 3.90139 38.2201L9.38539 38.2071C13.6854 38.1971 16.0554 40.6311 16.0634 44.1611ZM11.9634 44.1711C11.9594 42.4431 10.5254 42.0151 9.31739 42.0181L7.83339 42.0211L7.84339 46.3391L9.32739 46.3351C10.5344 46.3331 11.9674 45.8721 11.9634 44.1711Z'
                fill='currentcolor'
            />
            <path
                d='M50.7586 52.549L50.7646 55.596C50.7646 55.824 50.6136 55.977 50.3846 55.978L40.6866 55.999C40.4576 55.999 40.3056 55.848 40.3046 55.619L40.2676 38.61C40.2676 38.382 40.4186 38.23 40.6476 38.229L50.3206 38.207C50.5486 38.207 50.7016 38.359 50.7026 38.587L50.7086 41.634C50.7086 41.862 50.5576 42.014 50.3286 42.015L44.6166 42.028L44.6236 45.074L49.9796 45.062C50.2096 45.062 50.3616 45.214 50.3616 45.442L50.3686 48.489C50.3686 48.717 50.2166 48.869 49.9886 48.87L44.6316 48.882L44.6386 52.182L50.3766 52.17C50.6046 52.17 50.7576 52.32 50.7586 52.549Z'
                fill='currentcolor'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M36.4755 47.103C36.4755 52.016 32.4285 55.999 27.4355 55.999C22.4435 55.999 18.3965 52.016 18.3965 47.103C18.3965 42.19 22.4435 38.207 27.4365 38.207C32.4285 38.207 36.4755 42.19 36.4755 47.103ZM34.5595 47.103C34.5587 48.2668 34.2714 49.4125 33.7231 50.439C33.1748 51.4655 32.3823 52.3413 31.4155 52.989C31.4616 52.7649 31.4573 52.5333 31.4029 52.311C31.3484 52.0887 31.2451 51.8813 31.1005 51.7039C30.956 51.5265 30.7737 51.3835 30.567 51.2853C30.3603 51.1871 30.1343 51.1361 29.9055 51.136H28.0005L30.3695 45.324H20.4195C21.2165 42.254 24.0295 39.986 27.3765 39.986C31.3435 39.986 34.5595 43.173 34.5595 47.103ZM20.2655 48.119C20.2995 48.361 20.3465 48.599 20.4055 48.832L20.2655 48.119Z'
                fill='currentcolor'
            />
        </g>
        <defs>
            <clipPath id='clip0_1457_12705'>
                <rect width='54' height='56' fill='currentcolor' />
            </clipPath>
        </defs>
    </svg>
)

export default PoeLogo
