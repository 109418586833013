import React from 'react'

const IbmCloud = () => (
    <svg width='90' height='90' viewBox='0 0 90 90' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_8628_11009)'>
            <path
                d='M86.6587 59.8231C87.3489 59.6575 88.0391 59.7955 88.7569 59.7403C88.9915 59.7127 89.2124 59.7127 89.447 59.7127V73.9167C88.7016 73.9167 87.9562 73.8891 87.2108 73.8891C87.0452 73.8891 86.8381 73.9719 86.6725 73.8063C87.0452 73.4888 87.4731 73.6406 87.8734 73.5992C89.2538 73.5716 89.1296 73.7096 89.1296 72.3017C89.1296 68.5747 89.1296 64.8477 89.1296 61.1069C89.1296 60.8998 89.1296 60.7342 89.1296 60.5271C89.1572 60.1268 88.9225 59.9888 88.5912 59.9888C88.2185 59.9888 87.7906 59.9888 87.3903 59.9888C87.1004 59.9888 86.8381 60.0578 86.6587 59.8231Z'
                fill='currentColor'
            />
            <path
                d='M89.3356 60.2792C89.3356 59.9341 89.2527 59.8513 88.9077 59.8513C88.1623 59.8789 87.4169 59.8513 86.6439 59.8237C86.5058 59.9617 86.5886 60.1964 86.5886 60.362C86.5886 61.908 86.5886 63.454 86.561 65.0139C86.561 65.1519 86.6439 65.3589 86.4506 65.4694C86.2159 64.7516 85.6776 64.1718 84.9598 63.8958C83.331 63.2332 81.4537 63.8958 80.5978 65.4418C80.2528 66.0767 80.0871 66.7669 79.9629 67.4847C79.7972 69.155 79.7972 70.7838 80.5978 72.3298C81.5779 74.1933 84.1868 74.7178 85.6776 73.3651C86.0227 73.02 86.285 72.6473 86.4506 72.1918C86.5886 72.7991 86.3954 73.3099 86.5886 73.793C87.334 73.793 88.0794 73.7654 88.8524 73.7654C89.1975 73.7654 89.2804 73.655 89.2804 73.3375C89.308 69.0031 89.308 64.6412 89.3356 60.2792ZM86.5748 70.0108C86.5748 71.2117 85.857 71.8743 84.6285 71.7915C83.7727 71.7639 83.0549 71.1289 82.9307 70.2731C82.7926 69.3482 82.7926 68.4372 82.9031 67.5123C83.1377 66.0215 84.7666 65.3866 85.9951 66.3114C86.3402 66.5461 86.5334 66.9464 86.5334 67.3191C86.5334 67.8298 86.5334 68.3543 86.5334 68.8651C86.5748 69.2378 86.5748 69.6381 86.5748 70.0108Z'
                fill='currentColor'
            />
            <path
                d='M42.7672 67.2509C42.7948 67.8583 42.712 68.4794 42.85 69.142C43.0847 70.4396 43.8577 71.2402 45.0862 71.461C46.3148 71.6957 47.4329 71.1712 48.0126 70.0254C48.2473 69.5975 48.3577 69.5699 48.7304 69.7908C49.2411 70.1359 49.7381 70.4258 50.2764 70.6742C50.5387 70.8123 50.5387 70.9365 50.4145 71.185C49.324 73.3383 47.0326 74.4012 44.4237 73.9733C42.2979 73.6282 40.8623 72.4825 40.1721 70.4396C39.3991 68.0929 39.3991 65.7049 40.4344 63.4135C41.4145 61.3153 43.416 60.1972 45.7074 60.28C47.7504 60.3353 49.2964 61.1083 50.304 62.9442C50.4697 63.2616 50.5387 63.4549 50.097 63.6343C49.4896 63.8966 48.9513 64.2417 48.3439 64.5592C48.1783 64.642 47.9988 64.7662 47.916 64.4764C47.4053 62.9856 45.9421 62.6957 44.6997 63.0132C43.4712 63.3307 42.7258 64.3935 42.7258 65.8567C42.7672 66.3123 42.7672 66.7402 42.7672 67.2509Z'
                fill='currentColor'
            />
            <path
                d='M31.5407 62.7231C30.2155 65.3872 28.7247 67.9685 27.2615 70.674C25.7983 67.9823 24.3351 65.401 22.872 62.7507V63.4133C22.872 66.7124 22.872 69.9838 22.872 73.2829C22.872 73.7108 22.8167 73.9179 22.3336 73.8903C21.2983 73.8351 21.2983 73.8903 21.2983 72.8274C21.2983 68.9209 21.2983 65.0283 21.2983 61.0943C21.2983 60.6663 21.3812 60.5559 21.8091 60.5559C23.3827 60.5835 23.3827 60.5559 24.1281 61.9363L27.1649 67.651C27.1925 67.6786 27.1925 67.6786 27.2201 67.7062C27.4272 67.7062 27.4272 67.4992 27.4824 67.3887C28.6005 65.2906 29.7462 63.2062 30.8643 61.1081C31.0713 60.763 31.2646 60.5283 31.6925 60.5973C32.1756 60.6525 32.8106 60.3903 33.1281 60.7078C33.4456 61.0252 33.2109 61.6602 33.2109 62.1433C33.2109 65.8427 33.2109 69.5697 33.2385 73.3105C33.2385 73.7936 33.1557 73.9731 32.6036 73.9455C31.6235 73.8903 31.6235 73.9455 31.6235 72.9654V62.8059L31.5407 62.7231Z'
                fill='currentColor'
            />
            <path
                d='M16.189 67.0017C16.1338 66.8913 15.8716 67.0294 15.9544 66.7671C16.4651 66.629 16.9068 66.3392 17.2519 65.9389C18.3148 64.6137 17.9421 62.1567 16.5341 61.2042C15.8163 60.7763 15.0157 60.5416 14.1875 60.5692C12.5863 60.5692 10.9713 60.5968 9.34242 60.5416C8.9145 60.5416 8.83168 60.6797 8.83168 61.0524C8.85929 63.5784 8.83168 66.0769 8.83168 68.603C8.83168 70.1766 8.83168 71.8192 8.83168 73.3929C8.83168 73.6275 8.77647 73.8484 9.17677 73.8484C11.1921 73.8208 13.2213 73.876 15.2366 73.7932C16.8102 73.7379 18.0525 72.4128 18.2734 70.6597C18.5219 68.8929 17.8317 67.6229 16.189 67.0017ZM10.4329 62.3223C10.4329 62.0876 10.5157 61.9772 10.778 61.9772C12.0065 62.0048 13.2213 61.9496 14.4774 62.0048C15.4574 62.06 16.0234 62.6122 16.1476 63.5784C16.2028 63.9511 16.2028 64.3514 16.1752 64.7241C16.0648 65.649 15.4574 66.2149 14.505 66.2702C13.87 66.3254 13.2489 66.2702 12.6139 66.2702V66.2978C12.0065 66.2978 11.413 66.2978 10.8332 66.2978C10.5709 66.2978 10.4605 66.2425 10.4605 65.9527C10.4329 64.7241 10.4329 63.5232 10.4329 62.3223ZM16.5894 70.6045C16.5065 71.6674 15.8992 72.3576 14.8363 72.3852C13.4559 72.4404 12.117 72.4128 10.7642 72.4404C10.5019 72.4404 10.4191 72.33 10.4467 72.0953C10.4743 71.4051 10.4467 70.7149 10.4467 70.0248C10.4467 69.3622 10.4743 68.7272 10.4467 68.0508C10.4467 67.761 10.5019 67.6505 10.8194 67.6505C12.0479 67.6781 13.3179 67.6229 14.574 67.6781C15.8025 67.7057 16.4927 68.3683 16.617 69.6244C16.5894 69.9557 16.5894 70.3008 16.5894 70.6045Z'
                fill='currentColor'
            />
            <path
                d='M66.6985 66.6572C66.0635 64.7937 64.5727 63.7308 62.5712 63.648C60.3626 63.5928 58.789 64.5729 58.0712 66.464C57.6156 67.7615 57.5328 69.1005 57.8089 70.4532C58.3196 72.9793 60.7629 74.6081 63.2889 74.0974C63.9239 73.9594 64.4899 73.7247 65.0144 73.3796C66.588 72.2891 66.9883 70.6879 67.0574 68.9072C67.0435 68.0928 66.9193 67.375 66.6985 66.6572ZM64.0482 70.467C63.9101 71.3919 63.2475 71.875 62.2951 71.875C61.3702 71.875 60.7767 71.3643 60.6248 70.4394C60.4868 69.3765 60.4868 68.3137 60.6248 67.2784C60.7629 66.3535 61.4255 65.8428 62.3779 65.8704C63.3028 65.898 63.8687 66.3812 64.0482 67.3336C64.1586 67.8719 64.1034 68.4241 64.1034 69.1971C64.1448 69.5836 64.1172 70.0115 64.0482 70.467Z'
                fill='currentColor'
            />
            <path
                d='M74.9823 72.2475C74.5268 73.0481 73.9746 73.7107 73.0636 73.9453C71.1725 74.4561 69.6264 73.6002 69.1295 71.6815C68.9915 71.1432 68.9639 70.591 68.9639 70.0113C68.9639 68.1202 68.9639 66.2015 68.9639 64.2966C68.9639 63.9515 69.0191 63.7858 69.4194 63.8134C70.0544 63.8686 70.6755 63.8686 71.3105 63.8134C71.6832 63.7858 71.7936 63.9239 71.766 64.2966C71.7384 66.0496 71.766 67.7751 71.766 69.4867C71.766 69.7214 71.766 69.9423 71.7936 70.1769C71.8488 71.2122 72.3734 71.8058 73.2844 71.7505C74.2369 71.6953 75.0375 71.1708 75.0099 70.0803C74.9547 68.1616 75.0099 66.2015 74.9823 64.3104C74.9823 63.8824 75.0927 63.7996 75.493 63.7996C76.1556 63.8548 76.7906 63.8272 77.4669 63.7996C77.7568 63.7996 77.812 63.8824 77.812 64.1447C77.812 67.2367 77.812 70.3426 77.812 73.4346C77.812 73.7521 77.7016 73.8073 77.4117 73.8073C76.7491 73.7797 76.059 73.7797 75.3964 73.8073C75.1065 73.8073 74.9685 73.7245 74.9961 73.4346C74.9823 73.0757 74.9823 72.6754 74.9823 72.2475Z'
                fill='currentColor'
            />
            <path
                d='M55.3392 65.7317C55.3392 67.54 55.3668 69.3483 55.3116 71.1566C55.3116 71.5845 55.4496 71.7639 55.8499 71.6673C56.4849 71.5569 56.6782 71.8053 56.5677 72.3851C56.5677 72.4679 56.5677 72.5921 56.5677 72.675C56.5677 73.8759 56.5677 73.8759 55.3944 73.8207C55.0493 73.8207 54.7042 73.7931 54.3591 73.7378C53.4343 73.5998 52.6889 72.8544 52.5785 71.9296C52.5233 71.5569 52.468 71.1842 52.468 70.7839C52.468 67.2777 52.468 63.7854 52.4404 60.2793C52.4404 59.8513 52.5233 59.6443 52.9788 59.6995C53.5861 59.7547 54.1797 59.7547 54.7595 59.6995C55.1322 59.6719 55.2702 59.7823 55.2702 60.2102C55.3392 62.0875 55.3392 63.8958 55.3392 65.7317Z'
                fill='currentColor'
            />
            <path
                d='M4.03098 67.2223C4.03098 68.8235 4.03098 70.4386 4.03098 72.0674C4.03098 72.4125 4.1138 72.5505 4.4865 72.4953C4.72116 72.4677 4.94202 72.5229 5.17668 72.4953C5.78404 72.4125 5.94969 72.6333 5.92208 73.2131C5.89447 73.7238 5.81165 73.8757 5.28711 73.8481C3.90674 73.7929 2.47116 73.8205 1.06318 73.8481C0.690485 73.8481 0.48343 73.7652 0.552449 73.3373C0.580056 73.1993 0.580056 73.0751 0.552449 72.937C0.497234 72.5919 0.607663 72.4539 0.980363 72.4815C1.32545 72.5367 1.67055 72.4539 2.01564 72.5091C2.36073 72.5367 2.44355 72.3987 2.44355 72.0812C2.44355 68.8097 2.44355 65.5383 2.44355 62.2668C2.44355 61.9769 2.38834 61.8389 2.07085 61.8665C1.80858 61.8941 1.53251 61.8389 1.27024 61.8665C0.731896 61.9493 0.552449 61.7837 0.552449 61.2039C0.552449 60.6241 0.690485 60.4861 1.27024 60.5137C2.65061 60.5689 4.03098 60.5413 5.42515 60.5137C5.82545 60.5137 6.0049 60.5965 5.93588 61.0245C5.90828 61.1901 5.90828 61.3695 5.93588 61.5352C5.96349 61.7975 5.85306 61.8803 5.59079 61.8803C5.2457 61.8527 4.873 61.9079 4.5003 61.8527C4.1276 61.7975 4.04478 61.9355 4.07239 62.2806C4.03098 63.9784 4.03098 65.5935 4.03098 67.2223Z'
                fill='currentColor'
            />
            <path
                d='M44.1707 19.8208C44.1707 18.8131 44.1707 17.8054 44.1431 16.784C44.1431 16.3284 44.4329 16.0662 44.7504 15.8315H45.1507C45.6063 16.1214 45.7581 16.4941 45.7581 17.0048C45.7305 18.2334 45.7581 19.4757 45.7581 20.6766C45.7581 20.9389 45.8133 21.1873 45.6201 21.422C45.2198 20.9665 45.482 20.4143 45.3854 19.9312C45.3578 19.2686 45.3854 18.5784 45.5234 17.9573C45.4958 18.4404 45.413 18.4956 44.5434 18.2195C44.4882 18.5646 44.571 18.9373 44.5158 19.2824C44.4882 19.4481 44.571 19.7656 44.1707 19.8208Z'
                fill='currentColor'
            />
            <path
                d='M31.8569 53.6539C28.6407 53.3088 26.2941 51.68 24.748 48.864C24.8033 48.4085 25.1207 48.6293 25.2864 48.6984C25.6867 48.864 26.087 49.0711 26.4321 49.3333C26.4045 49.1677 26.1146 48.9606 26.4597 48.8226C27.8125 50.5205 29.4689 51.6662 31.5947 52.0941C31.8845 52.3287 32.202 52.0665 32.4505 52.2321C32.4229 52.5772 32.133 52.4944 31.9122 52.522C31.9398 52.4944 31.995 52.522 32.0226 52.5496C32.9474 53.3778 34.0931 53.3778 35.2388 53.3502C35.4045 53.3502 35.5563 53.3502 35.722 53.433C35.8048 53.4606 35.86 53.5435 35.8876 53.5987C35.8876 53.6815 35.8048 53.7643 35.722 53.7643C34.4934 53.6815 33.1821 53.7643 31.8569 53.6539Z'
                fill='currentColor'
            />
            <path
                d='M29.2752 30.96C29.0405 30.96 28.8196 30.8772 28.6402 30.7253L25.2307 28.779C24.7476 28.4891 24.3749 28.0888 24.72 27.5229C25.0374 26.9155 25.5482 27.095 26.0175 27.3572C27.1908 28.0474 28.3641 28.71 29.5512 29.3726C29.9239 29.6072 30.1862 29.9109 30.0344 30.3802C29.9654 30.7529 29.6203 30.9876 29.2752 30.96Z'
                fill='currentColor'
            />
            <path
                d='M32.4625 52.246C32.1726 52.1632 31.8551 52.3564 31.6343 52.0804C35.0438 52.2184 38.4671 52.108 41.8766 52.1632C42.1389 52.1632 42.3873 52.1908 42.6496 52.2184C43.0775 52.4531 43.533 52.246 44.0024 52.3288C44.2094 52.384 44.3751 52.4945 44.5407 52.6463C45.1757 53.157 45.8935 53.4745 46.7217 53.3365C46.984 53.3641 47.2324 53.3089 47.3843 53.6264C47.2186 53.8886 46.9564 53.7644 46.7217 53.7644C45.714 53.7644 44.7064 53.7644 43.6849 53.7644C43.4502 53.7092 43.2846 53.6816 43.0499 53.7092C41.987 53.8196 41.0346 53.6816 40.234 52.881C39.9717 52.6187 39.6542 52.6739 39.3781 52.6463C37.1972 52.4807 35.0162 52.5083 32.8352 52.4807C32.6972 52.3978 32.5453 52.3288 32.4625 52.246Z'
                fill='currentColor'
            />
            <path
                d='M44.0349 52.3563C43.5794 52.2735 43.0549 52.5634 42.627 52.1907C44.4905 52.1355 46.3263 52.1355 48.1898 52.1907C48.7006 52.3977 49.2527 52.2735 49.7911 52.2735C50.1086 52.2459 50.4536 52.3563 50.7159 52.5634C51.2266 53.0741 51.972 53.364 52.6898 53.3088C52.8969 53.2812 53.3524 53.1431 53.2282 53.6815C51.4475 53.6815 49.6668 53.7367 47.9138 53.6263C47.6791 53.6539 47.4859 53.5986 47.3064 53.4882H46.5886C45.8018 52.9913 45.0288 52.4529 44.0349 52.3563Z'
                fill='currentColor'
            />
            <path
                d='M35.3623 21.0903C35.735 21.7253 36.1353 22.3464 36.4804 22.9814C36.7703 23.4369 36.7427 23.9338 36.2182 24.2099C35.735 24.4998 35.3347 24.2375 35.0448 23.782C34.5065 22.8019 33.9544 21.8357 33.416 20.8556C33.5541 20.2759 33.9268 20.5381 34.2166 20.621C34.6169 20.8004 35.0172 20.9384 35.3623 21.0903Z'
                fill='currentColor'
            />
            <path
                d='M54.8945 52.2181C56.3853 52.1905 57.9037 51.9282 59.2841 51.3899C60.0571 51.4175 60.5816 51.8178 60.6093 52.3699C59.6844 53.0049 58.5939 53.4052 57.4758 53.5708C57.4482 53.5156 57.4482 53.4328 57.5034 53.3638C57.5862 53.3086 57.6691 53.3086 57.9037 53.3086C57.5586 53.3086 57.2135 53.1429 57.0203 52.9083C56.6752 52.5356 56.1369 52.3699 55.6123 52.508C55.3362 52.5356 54.9912 52.6736 54.8945 52.2181Z'
                fill='currentColor'
            />
            <path
                d='M53.3184 53.6258C53.1528 53.4878 52.9457 53.4187 52.7111 53.5154C51.9381 53.6534 51.1651 53.3773 50.6405 52.7976C50.2402 52.3697 49.7847 52.3697 49.2601 52.3697C48.9151 52.3697 48.4871 52.5353 48.1973 52.2316C49.3982 52.1764 50.5853 52.1764 51.7862 52.2316C51.8414 52.3144 51.9243 52.3421 52.0485 52.3145C53.0562 52.204 53.9948 52.3421 54.7402 53.1427C55.0301 53.4325 55.458 53.3083 55.8307 53.3497C56.1206 53.3773 56.5209 53.2117 56.6589 53.6672L56.2862 53.6948C55.2371 53.7638 54.2985 53.7638 53.3184 53.6258Z'
                fill='currentColor'
            />
            <path
                d='M56.6201 53.6255C56.4545 53.4875 56.2474 53.4185 56.0128 53.4875C55.2121 53.5979 54.5496 53.4323 53.9974 52.7973C53.5971 52.3418 53.0726 52.2866 52.479 52.3418C52.2168 52.3694 51.9683 52.4522 51.7612 52.1761C52.7965 52.1209 53.8594 52.1209 54.8947 52.1761C54.9775 52.3418 55.1845 52.4108 55.3226 52.3142C56.275 51.9139 56.841 52.4798 57.5312 53.0044C57.9315 53.2942 57.5864 53.3495 57.476 53.4875C57.2275 53.6531 56.91 53.6807 56.6201 53.6255Z'
                fill='currentColor'
            />
            <path
                d='M64.5738 28.7797L62.5998 29.9254C61.744 29.815 61.3989 29.5803 61.2471 28.9177C61.9373 28.4346 62.655 28.0343 63.4281 27.6616C63.9664 27.8272 64.4219 28.2275 64.5738 28.7797Z'
                fill='currentColor'
            />
            <path
                d='M26.4994 48.8083C26.2923 49.181 26.7893 49.2638 26.8169 49.6917C26.389 49.4295 25.9887 49.2362 25.616 49.0292C25.3261 48.8911 25.1052 48.6012 24.7601 48.8635C24.3874 48.2009 23.9871 47.566 23.9319 46.8206C23.7249 46.2408 24.0699 46.2408 24.3874 46.4755C24.7325 46.7101 25.0776 46.8758 25.4779 46.793C25.6022 47.566 26.2371 48.0905 26.4994 48.8083Z'
                fill='currentColor'
            />
            <path
                d='M60.581 52.3563C60.374 51.6937 59.6976 51.7213 59.2559 51.3762L61.5197 49.9682C61.8648 50.3133 62.5273 50.4238 62.5549 51.1139C61.989 51.5833 61.3816 52.1078 60.581 52.3563Z'
                fill='currentColor'
            />
            <path
                d='M63.4283 37.5578L62.6001 36.4121C62.6829 35.7219 63.2075 35.5562 63.7458 35.3768C64.4636 36.1498 65.0434 37.0056 65.4437 37.9305C65.2366 38.3308 64.9605 38.1928 64.6707 38.0685C64.2565 37.9305 63.8562 37.6958 63.4283 37.5578Z'
                fill='currentColor'
            />
            <path
                d='M25.4941 46.7932C25.0938 47.3039 24.7763 46.9036 24.4312 46.6551C24.1965 46.4895 23.9205 46.1996 23.9205 46.7932C23.6858 46.2134 23.603 45.5923 23.6306 44.9573C23.5478 44.1843 23.5754 44.1567 24.5831 44.1567C24.9834 44.1567 25.1628 44.3913 25.1214 44.7916C25.0662 45.0539 25.0938 45.2472 25.1766 45.4818L25.4941 46.7932Z'
                fill='currentColor'
            />
            <path
                d='M53.3243 53.6263L56.2507 53.6539C55.9332 53.7644 55.5881 53.792 55.2706 53.7367C47.8167 53.7367 40.4041 53.7367 32.9501 53.7367C32.605 53.7367 32.2323 53.792 31.8872 53.6539L35.9317 53.6263C36.3596 53.3364 36.8151 53.4607 37.3121 53.4607C37.8504 53.4331 38.4025 53.4607 38.9409 53.4331C40.5145 53.3502 42.0329 53.6401 43.6479 53.6401C44.7108 53.6401 45.8013 53.6401 46.8642 53.6677C47.0713 53.6677 47.2369 53.6953 47.3749 53.5297C49.3351 53.6263 51.3228 53.6815 53.3243 53.6263Z'
                fill='currentColor'
            />
            <path
                d='M66.1711 44.9848C66.0882 45.6198 65.964 46.2409 65.5637 46.7931C65.0254 46.7102 64.4456 46.7379 64.2109 46.0477C64.5284 45.0952 64.7493 44.1013 64.8459 43.0661C65.1082 43.0661 65.1358 43.2731 65.1358 43.3836C65.1082 43.9909 65.4257 44.336 65.964 44.5293C66.1296 44.5569 66.2263 44.7363 66.1987 44.8467C66.1987 44.9296 66.1987 44.9572 66.1711 44.9848Z'
                fill='currentColor'
            />
            <path
                d='M60.4423 32.8237C61.6156 33.3068 62.6785 34.1212 63.4791 35.0875C63.6172 35.6949 63.0512 35.7225 62.7337 35.9433C62.4991 36.109 62.3334 35.8053 62.1954 35.6535C61.588 34.9357 61.0221 34.1903 60.3871 33.4725C60.0282 33.0584 60.0696 32.9617 60.4423 32.8237Z'
                fill='currentColor'
            />
            <path
                d='M35.3605 21.0906C34.9602 21.3805 34.6427 21.0354 34.3528 20.8283C34.063 20.6213 33.7731 20.5108 33.497 20.8283C33.29 20.5384 33.1519 20.29 32.9863 20.0001C32.7792 19.6274 32.9311 19.1719 33.3038 18.9648C33.7317 18.7026 34.1044 18.882 34.339 19.2547C34.6979 19.8483 35.0154 20.4832 35.3605 21.0906Z'
                fill='currentColor'
            />
            <path
                d='M54.8657 20.511C55.128 20.0831 55.3212 19.6552 55.6111 19.2135C55.8734 18.8684 56.2461 18.6752 56.6464 18.9236C57.0191 19.1307 57.1571 19.5862 56.9639 19.9589C56.9639 19.9865 56.9363 20.0141 56.9087 20.0417C56.6464 20.442 56.4531 20.8699 56.1909 21.2426C55.5835 21.2841 55.1832 20.9804 54.8657 20.511Z'
                fill='currentColor'
            />
            <path
                d='M54.8682 20.5111L56.221 21.2841C55.9035 21.8639 55.6413 22.4851 55.2409 23.0096C54.6612 23.0648 54.1505 22.7473 53.8882 22.2366L54.8682 20.5111Z'
                fill='currentColor'
            />
            <path
                d='M44.1725 19.821C44.5176 19.186 44.1725 18.4682 44.4072 17.778C44.7247 17.8608 44.9179 18.2611 45.2354 18.1783C45.6633 18.0955 45.3458 17.64 45.6081 17.2949V19.7934C45.5529 19.8762 45.5253 19.959 45.5253 20.0832C45.4701 20.663 45.3182 20.7458 44.8075 20.5111C44.6971 20.4559 44.6418 20.3455 44.5176 20.3455C44.4348 20.7182 44.628 21.1737 44.2829 21.4912C43.9655 20.9805 44.2001 20.4007 44.1725 19.821Z'
                fill='currentColor'
            />
            <path
                d='M61.2442 28.9168C61.5341 29.4965 62.0724 29.6898 62.597 29.9244C62.1414 30.2143 61.6445 30.5318 61.1614 30.7803C60.7335 31.0149 60.3056 31.0702 59.9881 30.518C59.7258 30.0625 59.9053 29.6622 60.3056 29.3447C60.6092 29.2067 60.9543 29.0962 61.2442 28.9168Z'
                fill='currentColor'
            />
            <path
                d='M62.5875 51.0717C62.4219 50.5334 61.6627 50.5334 61.5522 49.926L62.905 48.518C63.2501 48.8907 63.7884 49.0564 64.0783 49.5257C63.6504 50.1193 63.1673 50.6438 62.5875 51.0717Z'
                fill='currentColor'
            />
            <path
                d='M65.8558 39.0769C66.4907 40.7058 66.6288 42.5141 66.3113 44.2671C65.9938 43.9772 66.0766 43.5493 66.049 43.2042C66.049 42.7211 66.0766 42.1966 66.049 41.7134C65.911 41.2579 65.4416 41.1751 65.1242 40.968C64.9171 41.1751 65.069 41.4512 64.8343 41.603L64.434 39.7119C64.2683 39.3116 64.4064 39.1736 64.7791 39.3392C65.1794 39.5048 65.5521 39.4496 65.8558 39.0769Z'
                fill='currentColor'
            />
            <path
                d='M65.8556 39.0766C65.7452 39.6563 65.4553 39.7944 64.9307 39.4769C64.8479 39.4217 64.3924 39.049 64.4476 39.6425C64.1301 38.9247 63.7574 38.2346 63.4399 37.5168C63.8955 37.3511 64.2129 37.6824 64.5856 37.8343C64.8755 37.9447 65.124 38.1517 65.4139 37.9171C65.6347 38.3036 65.7728 38.6763 65.8556 39.0766Z'
                fill='currentColor'
            />
            <path
                d='M66.2975 41.3687C66.2975 42.3211 66.3251 43.315 66.3251 44.2675C66.3527 44.5297 66.2975 44.7506 66.1871 44.9852C66.1871 44.93 66.1871 44.8472 66.1595 44.8472C65.3036 44.5573 64.9309 43.9638 64.9033 43.0665V42.9009C64.9861 42.4454 64.9861 41.8932 65.7315 42.2383C65.9938 42.3487 65.9662 42.0727 65.9938 41.9208C66.0628 41.769 65.9248 41.4377 66.2975 41.3687Z'
                fill='currentColor'
            />
            <path
                d='M33.9019 29.7586C34.9095 28.4611 36.1657 27.412 37.6012 26.6666C37.9463 26.5286 38.0015 26.6942 37.8911 26.9565C37.7255 27.2188 37.5736 27.412 37.3528 27.5915C36.7454 28.1988 36.0966 28.7372 35.4065 29.2203C35.1166 29.5102 34.7439 29.7034 34.3988 29.9105C34.1227 30.0623 34.1227 30.0347 33.9019 29.7586Z'
                fill='currentColor'
            />
            <path
                d='M53.8741 22.2365C54.2744 22.554 54.7023 22.8439 55.2269 23.0095C55.0612 23.2994 54.937 23.6445 54.7437 23.9344C54.4815 24.3071 54.1088 24.4727 53.7084 24.2243C53.3081 23.962 53.1011 23.6169 53.391 23.1338C53.5014 22.8439 53.6946 22.5264 53.8741 22.2365Z'
                fill='currentColor'
            />
            <path
                d='M60.4436 32.8239C60.0985 33.0862 60.3884 33.1966 60.5264 33.3346C61.0648 33.9972 61.6169 34.6322 62.1553 35.281C62.7626 35.9987 63.0111 35.9711 63.4804 35.0463C63.6184 35.1015 63.7427 35.2119 63.7703 35.3362C63.3976 35.6537 62.8868 35.8745 62.6246 36.3714L61.0786 34.9635C60.6507 34.1353 59.9605 33.4727 59.4083 32.7273C59.2013 32.465 59.008 32.2718 59.574 32.3546C59.781 32.6168 60.2089 32.4512 60.4436 32.8239Z'
                fill='currentColor'
            />
            <path
                d='M64.02 49.5535C63.6197 49.2636 62.9571 49.2084 62.8467 48.5458L63.6749 47.2207C64.1304 47.5105 64.5031 47.8832 64.8758 48.2836C64.6274 48.6839 64.4617 49.1808 64.02 49.5535Z'
                fill='currentColor'
            />
            <path
                d='M39.7258 25.6738C40.1261 25.4391 40.554 25.3287 41.0234 25.2459C41.3961 25.1631 41.4789 25.3287 41.2304 25.5634C40.5955 26.1431 40.0295 26.7643 39.505 27.4269L37.7795 28.4069C37.4068 28.1171 37.7243 27.9514 37.8623 27.7444C38.2902 27.2336 38.7458 26.7643 39.2427 26.3364C39.4359 26.1431 39.7534 26.0189 39.7258 25.6738Z'
                fill='currentColor'
            />
            <path
                d='M64.9153 48.2569C64.4045 48.0498 63.9628 47.6771 63.7144 47.194C63.9214 46.8213 64.0871 46.421 64.2941 46.0483C64.6116 46.4762 65.1499 46.5038 65.6469 46.7937C65.3984 47.3182 65.3156 47.8842 64.9153 48.2569Z'
                fill='currentColor'
            />
            <path
                d='M23.6329 43.4663C23.6329 42.8865 23.7433 42.3206 23.9228 41.7961C24.5025 42.2516 24.9304 42.8865 25.1237 43.6043C25.2617 44.0599 25.0961 44.4049 24.5163 44.2945H24.2955C23.5086 44.2945 23.5086 44.2945 23.6329 43.4663Z'
                fill='currentColor'
            />
            <path
                d='M35.3938 30.3801L34.3585 31.6776C33.5579 31.9675 33.1023 32.7405 32.3846 33.1132C32.2465 33.196 32.1223 33.3754 31.9842 33.3754C31.6944 33.4031 31.6944 33.265 31.8186 33.0856C31.8462 32.8509 31.9566 32.6577 32.1085 32.5058C32.8539 31.788 33.6269 31.1254 34.4551 30.5319C34.6898 30.3939 34.8278 30.1868 35.1177 30.1868C35.2143 30.1868 35.3385 30.242 35.3938 30.3801Z'
                fill='currentColor'
            />
            <path
                d='M66.3 41.368C66.1343 41.5061 66.1619 41.7131 66.1619 41.8788C66.1619 42.4585 66.1895 42.8588 65.4165 42.3343C64.8782 41.9892 65.1543 42.7622 64.9058 42.8726L64.8506 41.4923C64.9058 40.5122 64.9334 40.4846 65.803 41.0367C65.9825 41.2024 66.2033 41.2024 66.3 41.368Z'
                fill='currentColor'
            />
            <path
                d='M41.481 26.6818C41.3706 26.7922 41.1911 26.8198 41.0531 26.7922C40.708 26.6818 40.708 26.5023 40.8874 26.2539C40.9979 26.1158 41.1221 25.9916 41.2049 25.9088C41.4396 25.6465 41.6604 25.4532 41.9503 25.2738C42.0607 25.3014 41.9503 25.1358 41.9503 25.2186C41.9503 25.2462 41.9227 25.2462 41.8951 25.2462C41.7847 25.2462 41.6328 25.191 41.6052 25.1081C42.1436 24.8735 42.7233 24.8183 43.3031 24.8183C43.4687 24.7631 43.6206 24.6802 43.7586 24.8459C43.8414 24.9287 43.7586 25.0529 43.6758 25.1081C42.9718 25.6465 42.3644 26.3643 41.481 26.6818Z'
                fill='currentColor'
            />
            <path
                d='M35.3944 30.3809L35.0769 30.2429C34.9665 30.0358 35.0493 29.8702 35.1045 29.6355C35.1873 29.4699 35.3116 29.3456 35.422 29.2628C36.057 28.8349 36.6229 28.338 37.2303 27.8548C37.4649 27.772 37.6858 27.6202 37.9205 27.8272C37.8376 28.0343 37.603 28.1447 37.7824 28.407C36.9404 29.0143 36.1398 29.6631 35.3944 30.3809Z'
                fill='currentColor'
            />
            <path
                d='M41.5924 25.1076H42.2274C41.8271 25.5355 41.4544 25.9358 41.0817 26.2809C40.8746 26.4879 40.7642 26.5983 41.0265 26.8192L39.4805 27.4266C39.7703 26.5707 40.4605 26.0462 41.0817 25.4803C41.344 25.2456 41.1093 25.3146 40.9989 25.2456C41.1645 25.1076 41.3854 25.0799 41.5924 25.1076Z'
                fill='currentColor'
            />
            <path
                d='M52.8936 27.1095C53.2939 27.22 53.6666 27.4546 53.9013 27.8273C53.6942 28.3657 53.1835 28.7108 52.6037 28.6555L51.458 27.9377C51.9411 27.6893 52.3414 27.3166 52.8936 27.1095Z'
                fill='currentColor'
            />
            <path
                d='M64.5744 28.7792L63.4287 27.6611C63.6358 27.5507 63.7738 27.4264 63.9671 27.3436C64.4226 27.0261 64.8505 27.0261 65.1956 27.5507C65.4855 28.0614 65.2784 28.4065 64.8505 28.6964C64.7677 28.6964 64.6848 28.7516 64.5744 28.7792Z'
                fill='currentColor'
            />
            <path
                d='M44.3389 21.518V20.0272C44.8772 20.3999 45.4294 20.8554 45.6364 19.8201C45.6364 20.3584 45.6364 20.883 45.664 21.4213C45.2085 22.0011 44.8634 22.0287 44.3389 21.518Z'
                fill='currentColor'
            />
            <path
                d='M31.7706 33.0861C31.7982 33.2518 31.8534 33.376 32.0329 33.2242C32.5436 32.7134 33.2614 32.4235 33.7031 31.8438C33.8688 31.5815 34.0758 31.4987 34.3657 31.7058L33.6479 32.8515C33.1096 33.0585 32.7645 33.4588 32.3504 33.8315C32.1157 34.0662 31.8948 34.2318 31.6326 34.4113C31.4945 34.4941 31.3151 34.5769 31.3427 34.2456C31.3703 33.7763 31.5359 33.4312 31.7706 33.0861Z'
                fill='currentColor'
            />
            <path
                d='M32.3278 42.4868C32.6729 43.4392 32.5625 43.8119 31.6376 44.2398C31.472 44.3227 31.3478 44.2951 31.3201 44.157C31.1545 43.9224 31.0303 43.6463 31.0303 43.3564C31.3754 42.611 31.7205 42.404 32.3278 42.4868Z'
                fill='currentColor'
            />
            <path
                d='M59.4933 32.368C59.4105 32.4232 59.1759 32.3404 59.3829 32.6027C59.866 33.141 60.363 33.7208 60.7909 34.3281C60.9289 34.5352 61.1912 34.7008 60.9979 35.0183L59.8522 34.3005C59.5348 33.638 58.9274 33.1548 58.5547 32.5199C58.4443 32.3128 58.1544 31.9815 58.7618 32.1195C59.0378 32.1057 59.3139 32.1886 59.4933 32.368Z'
                fill='currentColor'
            />
            <path
                d='M41.478 26.6813L43.7142 24.8454L43.3139 24.7902C43.7418 24.5556 44.1697 24.6798 44.6391 24.6798C44.6943 24.7902 44.6667 24.9421 44.5562 24.9973C43.9765 25.4252 43.6314 26.1154 42.9274 26.3777L41.478 26.6813Z'
                fill='currentColor'
            />
            <path
                d='M42.9126 26.3636L44.6381 24.6657H45.6457C45.8114 24.928 45.6733 25.066 45.5077 25.2041C44.997 25.6044 44.5552 26.2118 43.7822 26.2394C43.4786 26.4188 43.1887 26.3636 42.9126 26.3636Z'
                fill='currentColor'
            />
            <path
                d='M30.766 42.3766L30.6279 41.6588C30.7384 41.3137 31.0282 41.148 31.2905 40.941C31.608 40.7339 31.7736 40.4026 31.7184 40.0575C31.7184 39.9195 31.7184 39.7677 31.7184 39.6296C31.7184 39.4226 31.7184 39.2569 31.7184 39.0499C31.7736 38.76 31.608 38.3597 32.0359 38.2216V40.941C32.1739 41.7692 31.8288 42.3213 31.1111 42.5146C31.0006 42.556 30.8212 42.487 30.766 42.3766Z'
                fill='currentColor'
            />
            <path
                d='M53.585 31.7889C54.565 31.361 55.6003 31.4714 56.6218 31.6508C56.5942 31.706 56.5113 31.7613 56.4561 31.7889C56.3181 31.8441 56.1662 31.8441 56.0282 31.8441C55.7659 31.8441 55.5451 31.8441 55.2828 31.8441C55.1172 31.8165 54.9653 31.8441 54.8825 31.706C55.1172 32.2444 55.6831 32.5343 55.9178 33.0864H55.062C54.358 32.9622 53.8058 32.4514 53.585 31.7889Z'
                fill='currentColor'
            />
            <path
                d='M31.3207 34.177C31.8314 34.2599 32.0109 33.7767 32.3284 33.5145C32.6182 33.2522 32.9081 33.0313 33.1566 32.7415C33.3222 32.5758 33.4465 32.7139 33.6121 32.7691L33.0323 33.9148C32.425 34.2323 31.8866 34.6326 31.3621 35.0605C31.1274 35.2261 30.9342 35.4056 31.0446 34.8948C31.0584 34.6602 31.1412 34.3979 31.3207 34.177Z'
                fill='currentColor'
            />
            <path
                d='M35.5018 29.3867L35.212 29.6766C34.7012 30.5048 33.8316 30.9327 33.0862 31.4849C32.8791 31.6229 32.6307 31.6505 32.7963 31.2226C33.0862 30.6842 33.4865 30.1873 33.9144 29.7594C34.1767 30.1045 34.3147 29.7318 34.4942 29.649C34.784 29.4833 35.1015 29.2763 35.35 29.0692C35.4466 29.152 35.5018 29.2625 35.5018 29.3867Z'
                fill='currentColor'
            />
            <path
                d='M52.8982 27.1087C52.4979 27.4814 52.0976 27.9369 51.4903 27.9369L50.7725 27.509C51.0899 27.081 51.5731 26.7083 52.07 26.5289C52.4151 26.5703 52.636 26.8878 52.8982 27.1087Z'
                fill='currentColor'
            />
            <path
                d='M32.7841 31.25C32.8117 31.457 32.8669 31.5122 33.0463 31.3604C33.7641 30.7806 34.4819 30.2423 35.2273 29.6902C35.2273 29.8972 35.1997 30.0905 35.0893 30.2699C34.1368 30.9601 33.1982 31.6779 32.37 32.4785C32.2871 32.5613 32.2043 32.6856 32.0801 32.5061C32.1491 31.9954 32.4942 31.6503 32.7841 31.25Z'
                fill='currentColor'
            />
            <path
                d='M24.6252 44.2122C25.3154 44.1293 25.0808 43.729 24.9151 43.3839C24.5976 42.8732 24.1697 42.3763 23.9351 41.8103C23.9351 41.6447 23.9627 41.5205 24.0731 41.3824C24.6114 41.5481 25.1636 42.3349 25.2188 42.956L25.1912 45.4821C24.9289 45.2474 25.0532 45.0266 25.0532 44.7367C25.0532 44.4468 24.9151 44.3088 24.6252 44.3364C24.57 44.3088 24.57 44.2812 24.57 44.2536C24.5976 44.2398 24.5976 44.2122 24.6252 44.2122Z'
                fill='currentColor'
            />
            <path
                d='M31.8823 39.0772V39.6569C31.4544 40.0296 31.1645 40.6094 30.4743 40.5127C30.2397 39.5879 30.2397 38.594 30.4743 37.6416L30.5572 37.6968C30.6124 37.8348 30.6676 37.9867 30.64 38.0971C30.64 38.2351 30.64 38.387 30.64 38.4974C30.64 38.6354 30.64 38.8149 30.64 39.0081C30.64 39.1462 30.64 39.298 30.6676 39.436C31.1093 39.3946 31.3716 38.7597 31.8823 39.0772Z'
                fill='currentColor'
            />
            <path
                d='M30.7666 42.3762C31.5396 42.3486 32.0641 41.7688 32.0641 40.9682C32.3264 41.4514 32.1746 42.0311 32.354 42.5419C31.7191 42.5142 31.4982 43.0526 31.0565 43.3977C30.8908 43.0664 30.7666 42.7213 30.7666 42.3762Z'
                fill='currentColor'
            />
            <path
                d='M31.0566 34.9499C31.0842 35.0328 31.0566 35.2398 31.2222 35.0328C31.5949 34.5772 32.2023 34.3978 32.6026 33.9147C32.7407 33.749 32.8925 33.8595 33.0305 33.9423C33.0029 34.1769 32.9201 34.3426 32.7683 34.522C32.368 35.1294 31.6502 35.3503 31.1394 35.8748C31.0842 35.93 30.9738 36.0128 30.8772 35.93C30.7115 35.792 30.7943 35.6401 30.8495 35.4745C30.891 35.2674 30.9462 35.088 31.0566 34.9499Z'
                fill='currentColor'
            />
            <path
                d='M46.0325 26.2528L44.6245 26.2252C44.6245 25.9353 44.8592 25.963 44.9972 25.9353C45.1352 25.9353 45.2871 25.9353 45.4251 25.9353C46.2533 26.0734 46.6537 25.397 47.2886 25.0105C47.3438 25.0105 47.261 24.9553 47.2334 24.9829C47.0264 25.0933 46.8883 25.1209 46.8883 24.8172C47.4681 24.7068 47.896 25.1899 48.4343 25.1071C47.7579 25.7421 47.1506 26.5427 46.0325 26.2528Z'
                fill='currentColor'
            />
            <path
                d='M31.8821 39.0767C31.3023 39.0767 30.9987 39.5598 30.4741 39.9049V39.0767C30.6812 38.704 31.0125 38.4969 31.3023 38.2485C31.5646 38.0828 31.7026 37.8758 31.7579 37.5583C31.8131 37.4202 31.8683 37.2684 32.0477 37.3236C32.0477 37.6135 32.0477 37.9034 32.0201 38.1518C31.7993 38.5107 31.9511 38.7868 31.8821 39.0767Z'
                fill='currentColor'
            />
            <path
                d='M56.0528 31.6782H56.4807C56.9915 31.8438 57.4332 32.1889 57.7093 32.6583C57.8749 32.9205 57.9991 33.1414 57.7369 33.4313L57.0191 33.2656C56.8534 33.1 56.6188 33.238 56.4393 33.1276C56.5498 32.9619 56.5222 32.7549 56.4117 32.5892C56.2461 32.3132 55.901 32.0785 56.0528 31.6782Z'
                fill='currentColor'
            />
            <path
                d='M58.7745 32.0779C58.4846 32.1055 58.5122 32.216 58.6917 32.3678C59.0368 32.7957 59.3819 33.2512 59.7546 33.7206C59.8926 33.8862 60.0444 34.038 59.8926 34.2589L59.0644 33.831C58.8573 33.2236 58.3466 32.8509 57.9463 32.423C57.8358 32.285 57.684 32.1331 57.8082 31.9951C57.9187 31.8571 58.0981 31.8847 58.2361 31.9675C58.4018 31.8985 58.6088 31.9675 58.7745 32.0779Z'
                fill='currentColor'
            />
            <path
                d='M53.0331 33.4039L52.0393 33.68C51.5285 33.4454 51.1144 33.045 50.8936 32.5343C51.1834 32.2996 51.5285 32.1616 51.9012 32.134C52.1497 32.6171 52.55 33.0865 53.0331 33.4039Z'
                fill='currentColor'
            />
            <path
                d='M37.8888 27.8273L37.171 27.9654C37.1434 27.8549 37.171 27.7583 37.2538 27.6755C37.8612 27.0957 38.4547 26.5298 39.0345 25.9224L39.7247 25.6325C39.9593 25.6878 40.125 25.7154 39.8351 25.95C39.1725 26.5988 38.5514 27.2062 37.8888 27.8273Z'
                fill='currentColor'
            />
            <path
                d='M49.1693 35.3769C49.0037 35.6115 48.7138 35.6668 48.5896 35.9566C47.9822 35.8738 47.5267 35.4183 47.4438 34.8109C47.6095 34.521 47.7889 34.3002 48.1616 34.2312C48.4239 34.6591 48.769 35.087 49.1693 35.3769Z'
                fill='currentColor'
            />
            <path
                d='M47.4681 34.7982C47.7856 35.2261 48.1031 35.654 48.6138 35.9439C48.5034 36.2338 48.2135 36.289 48.034 36.5236C47.4267 36.3856 47.1782 35.9439 47.0264 35.3779C47.0678 35.1157 47.2334 34.8948 47.4681 34.7982Z'
                fill='currentColor'
            />
            <path
                d='M45.0545 26.115C44.8888 26.115 44.737 26.0874 44.6266 26.2531L43.7983 26.2807C44.5713 25.9632 45.1787 25.4248 45.6618 24.7071C46.0345 24.6518 46.4348 24.7071 46.8075 24.8175C46.8352 24.8451 46.8628 24.9003 46.8628 24.9279C46.8628 24.9831 46.8351 24.9831 46.8351 24.9831C46.0621 25.1626 45.6342 25.7699 45.0545 26.115Z'
                fill='currentColor'
            />
            <path
                d='M53.5839 31.7886C54.0946 32.1889 54.4121 32.8239 54.9918 33.1138H54.5915C54.0118 33.2794 53.5286 33.2242 53.2388 32.603C53.1283 32.3131 52.728 32.3131 52.728 31.9957C53.0041 31.761 53.3216 31.8438 53.5839 31.7886Z'
                fill='currentColor'
            />
            <path
                d='M39.0357 25.9362C39.1185 25.9638 39.3255 26.019 39.2013 26.1018C38.4835 26.5574 38.028 27.3028 37.3378 27.7997C37.2274 27.7445 37.1031 27.6616 37.1998 27.5098C37.4068 27.2751 37.5725 27.1095 37.7381 26.8748C37.9038 26.6402 37.7381 26.6402 37.6001 26.6126C38.028 26.3641 38.5249 26.1156 39.0357 25.9362Z'
                fill='currentColor'
            />
            <path
                d='M53.03 33.4038C52.3674 33.321 51.8843 32.7688 51.8843 32.1063L52.3122 31.9682C52.8919 32.2581 53.1404 32.8517 53.6097 33.2658C53.4579 33.4314 53.2646 33.3624 53.03 33.4038Z'
                fill='currentColor'
            />
            <path
                d='M46.0376 26.2526C46.5207 26.3078 47.0177 26.1422 47.3628 25.7971C47.6526 25.5072 48.1082 25.3692 48.4809 25.1345C48.7155 25.1345 48.8812 25.1345 48.9364 25.4244C48.398 25.7971 47.9839 26.4873 47.1833 26.432C46.783 26.363 46.3827 26.4873 46.0376 26.2526Z'
                fill='currentColor'
            />
            <path
                d='M57.7415 33.4312C57.7691 32.5477 56.9685 32.2026 56.4854 31.6781L56.651 31.6505C56.858 31.6229 57.0513 31.7057 57.1893 31.8575C57.9623 32.175 58.4869 32.8928 58.5973 33.721C58.3212 33.6244 58.0038 33.5416 57.7415 33.4312Z'
                fill='currentColor'
            />
            <path
                d='M56.4812 33.0868C56.6883 33.1144 56.9091 33.0316 57.061 33.2525C56.6883 33.2801 56.288 33.2249 55.9153 33.1144C55.3355 32.8245 55.0594 32.2862 54.7144 31.7617C54.88 31.6236 55.0318 31.6788 55.1699 31.6788C55.6668 32.0792 56.2742 32.3966 56.4812 33.0868Z'
                fill='currentColor'
            />
            <path
                d='M50.4828 34.397C50.4 34.5626 50.2205 34.6869 50.0549 34.6869C49.3371 34.7421 49.1991 34.1485 48.9092 33.6792C49.0196 33.4169 49.2543 33.3617 49.4889 33.2789C49.9721 33.5136 50.3724 33.831 50.4828 34.397Z'
                fill='currentColor'
            />
            <path
                d='M30.8928 35.4605L30.948 35.8056C31.5277 35.2949 32.1489 34.8531 32.8115 34.4804C32.7563 34.7703 32.6734 35.0878 32.5216 35.3087C32.0109 35.5709 31.6382 35.9436 31.1412 36.2887C31.0308 36.3715 30.879 36.551 30.7961 36.4268C30.6857 36.3439 30.7133 36.1369 30.7685 35.9988C30.7133 35.8332 30.7685 35.6676 30.8928 35.4605Z'
                fill='currentColor'
            />
            <path
                d='M25.2038 42.9559C24.8311 42.4175 24.5688 41.7826 24.0581 41.3822C24.0857 41.0924 24.1685 40.8715 24.348 40.6645C24.8863 41.12 25.328 41.6445 25.3557 42.4175C25.3833 42.6108 25.3418 42.804 25.2038 42.9559Z'
                fill='currentColor'
            />
            <path
                d='M32.4643 35.5155C32.4367 35.8606 32.3539 36.2057 32.1745 36.4956C31.5947 36.8407 31.1944 37.4757 30.4766 37.6137L30.4214 37.6413C30.4766 37.3514 30.3662 37.0339 30.587 36.7855C31.1668 36.4956 31.5671 35.9849 32.0778 35.6674C32.2573 35.6122 32.3263 35.4603 32.4643 35.5155Z'
                fill='currentColor'
            />
            <path
                d='M32.4639 35.5157C32.036 36.1783 31.1664 36.3439 30.7109 37.0341C30.628 37.1446 30.6557 36.8271 30.6004 36.7443C30.5728 36.482 30.6004 36.2611 30.7385 36.0265C30.8489 36.0541 30.5728 36.5372 30.9731 36.2335C31.2906 35.9436 31.6633 35.7228 31.9808 35.4329C32.1465 35.2949 32.2707 35.1982 32.4915 35.3501C32.4915 35.4329 32.4915 35.4881 32.4639 35.5157Z'
                fill='currentColor'
            />
            <path
                d='M52.0363 26.5436C51.6912 26.9439 51.2357 27.3166 50.7388 27.5512C50.4489 27.5236 50.2281 27.4132 50.021 27.2614C50.4765 26.9439 50.9044 26.516 51.429 26.2537C51.6912 26.2813 51.8845 26.3641 52.0363 26.5436Z'
                fill='currentColor'
            />
            <path
                d='M48.9027 33.6513C49.3582 33.9136 49.5376 34.4796 50.0484 34.659C49.9379 34.9213 49.6757 34.9489 49.4686 35.0869C48.9579 34.8523 48.5162 34.5762 48.4609 33.9412C48.53 33.7756 48.6956 33.6513 48.9027 33.6513Z'
                fill='currentColor'
            />
            <path
                d='M53.61 33.2509C52.8923 33.1129 52.7542 32.4227 52.3125 31.9534H52.7404C53.0027 32.119 53.3754 32.119 53.4582 32.4641C53.6238 33.1267 54.1208 33.1267 54.6039 33.0991C54.3278 33.3062 53.9551 33.1957 53.61 33.2509Z'
                fill='currentColor'
            />
            <path
                d='M58.6077 33.6518C58.235 32.9617 57.8071 32.2715 57.1997 31.7883C57.5448 31.7607 57.9175 31.6779 58.2074 31.8988C57.9175 31.8988 57.6966 31.8988 58.0003 32.2439C58.3178 32.5337 58.6353 32.8788 58.8838 33.2239C58.9942 33.431 59.1736 33.5414 58.9942 33.8037L58.6077 33.6518Z'
                fill='currentColor'
            />
            <path
                d='M31.9142 40.0849C32.0522 40.5957 31.7071 40.8579 31.3758 41.1202C31.1136 41.3273 30.8927 41.4653 30.6304 41.6585C30.52 41.4929 30.4648 41.2859 30.4924 41.0788C30.8237 40.5405 31.1964 40.1125 31.9142 40.0849Z'
                fill='currentColor'
            />
            <path
                d='M26.1851 37.9316C26.2403 37.6693 26.4749 37.6417 26.613 37.5037C27.1237 37.7936 27.4412 38.3319 27.3308 38.9117C27.2755 39.0497 27.1651 39.1739 27.0409 39.2015C26.4611 38.9945 26.3231 38.4699 26.1851 37.9316Z'
                fill='currentColor'
            />
            <path
                d='M25.9484 40.8302C25.9208 41.0924 25.8104 41.3133 25.6586 41.548C25.2859 41.0096 24.8303 40.5127 24.8027 39.7949C24.8303 39.5878 24.9132 39.4774 25.065 39.367C25.631 39.6569 25.8104 40.2228 25.9484 40.8302Z'
                fill='currentColor'
            />
            <path
                d='M48.4771 33.9694C48.7394 34.4249 49.1949 34.6872 49.4848 35.1151L49.1949 35.405C48.5047 35.3222 48.3115 34.8666 48.2148 34.2593L48.4771 33.9694Z'
                fill='currentColor'
            />
            <path
                d='M50.8766 32.5057C51.3045 32.8232 51.7048 33.2235 52.0223 33.6514C51.7876 33.8171 51.5668 33.9137 51.3045 33.9413C50.849 33.6514 50.5591 33.1683 50.5591 32.6161C50.6557 32.5333 50.7661 32.5057 50.8766 32.5057Z'
                fill='currentColor'
            />
            <path
                d='M46.7518 24.956V24.7903C46.807 24.7903 46.8622 24.7903 46.8898 24.7903C46.9727 25.025 47.2349 24.8455 47.373 25.0802C46.7656 25.4805 46.3377 26.2259 45.4266 26.0879C45.261 25.8256 45.4542 25.7704 45.5923 25.6599C45.9788 25.3977 46.3515 25.163 46.7518 24.956Z'
                fill='currentColor'
            />
            <path
                d='M49.336 25.3975C49.5982 25.3423 49.8191 25.4527 49.9157 25.6874C49.5706 26.0049 49.1151 26.2257 48.8252 26.6398C48.77 26.7503 48.5906 26.7779 48.5078 26.7227L48.4801 26.6951C48.2731 26.6675 48.0798 26.7503 47.9004 26.557C48.0798 25.7978 48.77 25.646 49.336 25.3975Z'
                fill='currentColor'
            />
            <path
                d='M27.3316 38.9386C27.2487 38.4003 26.876 37.9862 26.6138 37.5306C26.7242 37.3236 26.876 37.2132 27.0417 37.1303C27.5248 37.4202 27.8147 37.9586 27.7595 38.5383C27.7043 38.704 27.5248 38.842 27.3316 38.9386Z'
                fill='currentColor'
            />
            <path
                d='M50.6039 32.6444L51.3493 33.9696C51.2941 34.08 51.1837 34.1352 51.0594 34.1076C50.5211 34.0524 50.2312 33.7349 50.0518 32.9619C50.1622 32.7963 50.3968 32.6859 50.6039 32.6444Z'
                fill='currentColor'
            />
            <path
                d='M50.0207 32.9336C50.421 33.251 50.421 33.9412 51.0283 34.0793L50.4486 34.3691L49.4409 33.2234C49.648 33.1406 49.786 32.9612 50.0207 32.9336Z'
                fill='currentColor'
            />
            <path
                d='M30.5167 37.6418C31.0965 37.2967 31.4416 36.6065 32.2146 36.5237C32.2422 36.7859 32.187 37.0068 32.0765 37.2414C31.7315 37.3519 31.4692 37.5865 31.1931 37.7798C30.9585 37.9178 30.848 38.1525 30.5029 38.0697L30.5167 37.6418Z'
                fill='currentColor'
            />
            <path
                d='M24.7731 39.7951C25.0353 40.4025 25.5737 40.858 25.6289 41.5482C25.5737 41.6586 25.5461 41.7829 25.4908 41.8657C25.0353 41.4654 24.635 41.0375 24.4556 40.4025C24.5108 40.1678 24.5936 39.9332 24.7731 39.7951Z'
                fill='currentColor'
            />
            <path
                d='M27.2222 36.9235L27.6501 36.6336C28.2574 36.6612 28.5749 37.2133 28.5059 37.9311L28.078 38.221C27.7329 37.8483 27.443 37.4204 27.2222 36.9235Z'
                fill='currentColor'
            />
            <path
                d='M50.6028 25.8252L51.1826 26.1151C50.8375 26.6258 50.382 27.0399 49.7746 27.1228C49.609 27.1228 49.4571 27.1504 49.3467 26.9847C49.5123 26.3084 50.1611 26.1979 50.6028 25.8252Z'
                fill='currentColor'
            />
            <path
                d='M24.4886 40.3469C24.8061 40.8576 25.3168 41.2027 25.5239 41.8101C25.5515 42.0171 25.4963 42.1828 25.3583 42.3484L24.3506 40.5954C24.3506 40.5401 24.4058 40.4297 24.4886 40.3469Z'
                fill='currentColor'
            />
            <path
                d='M28.4755 37.9591C28.2408 37.5036 28.1856 36.8962 27.6196 36.6616L28.0475 36.3717C28.6549 36.5787 28.9034 37.0066 28.8758 37.6692L28.4755 37.9591Z'
                fill='currentColor'
            />
            <path
                d='M48.4497 26.6812C48.7948 26.5156 48.9052 26.1015 49.2779 25.9082C49.485 25.7702 49.6782 25.6736 49.9129 25.646L50.2028 25.6736C50.0923 26.3361 49.3746 26.4742 49.0571 26.9297L48.4497 26.6812Z'
                fill='currentColor'
            />
            <path
                d='M49.7334 27.1092L51.1414 26.1015C51.2518 26.1015 51.376 26.1567 51.4313 26.2396C51.0586 26.6951 50.6306 27.1644 50.0233 27.2472C49.9405 27.261 49.8162 27.2058 49.7334 27.1092Z'
                fill='currentColor'
            />
            <path
                d='M49.3343 25.3985C48.9063 25.8265 48.299 26.0611 47.9263 26.5443C47.664 26.5166 47.4156 26.5995 47.2085 26.4062C47.6364 26.2958 47.9815 26.0059 48.299 25.6884C48.506 25.5228 48.7269 25.3985 48.9892 25.3709L49.3343 25.3985Z'
                fill='currentColor'
            />
            <path
                d='M26.3532 40.1122C26.3256 40.2226 26.3256 40.3468 26.1876 40.3744C25.5526 40.2088 25.3594 39.6567 25.2075 39.1183C25.2903 38.9527 25.3179 38.8008 25.4974 38.6904C26.0081 38.9665 26.3256 39.5048 26.3532 40.1122Z'
                fill='currentColor'
            />
            <path
                d='M49.0146 26.9429C49.1251 26.2804 49.8429 26.1975 50.1604 25.6868L50.5883 25.8248C50.326 26.4046 49.6634 26.515 49.2907 26.9705L49.0146 26.9429Z'
                fill='currentColor'
            />
            <path
                d='M30.52 38.0702L31.4449 37.3524C31.6519 37.2143 31.8176 37.0625 32.0798 37.2143C32.0798 37.2696 32.0798 37.2972 32.0798 37.3524C31.9694 37.4076 31.9142 37.518 31.9418 37.6423C31.4863 37.9321 31.1688 38.5257 30.5338 38.4705L30.52 38.0702Z'
                fill='currentColor'
            />
            <path
                d='M27.2214 36.9242C27.677 37.214 27.9944 37.6972 28.0497 38.2217L27.7598 38.5116L27.042 37.1036L27.2214 36.9242Z'
                fill='currentColor'
            />
            <path
                d='M28.9293 37.6689C28.6946 37.2134 28.5566 36.6889 28.1011 36.3714C28.3081 36.2334 28.529 35.9159 28.7084 36.3162C28.8189 36.7303 29.5919 37.0754 28.9293 37.6689Z'
                fill='currentColor'
            />
            <path
                d='M31.9097 40.0845C31.3714 40.3468 30.9572 40.7195 30.5017 41.0646C30.5017 40.8575 30.5017 40.6919 30.4741 40.4848C31.0539 40.3468 31.3299 39.7394 31.8821 39.629C31.9097 39.7946 31.9097 39.9327 31.9097 40.0845Z'
                fill='currentColor'
            />
            <path
                d='M52.605 28.6693C53.0881 28.5036 53.5298 28.2137 53.9025 27.841C53.9853 27.9239 54.1648 28.0067 54.1372 28.1033C54.0406 28.545 53.0329 28.9453 52.605 28.6693Z'
                fill='currentColor'
            />
            <path
                d='M25.2012 39.0775C25.5187 39.4778 25.7395 39.9609 26.1813 40.3336L25.919 40.8167L24.9941 39.3536C25.0908 39.2569 25.146 39.1603 25.2012 39.0775Z'
                fill='currentColor'
            />
            <path
                d='M26.349 40.1119L25.4932 38.6763C25.576 38.5659 25.7002 38.4693 25.783 38.3864C26.2386 38.7868 26.6665 39.2147 26.5008 39.9601L26.349 40.1119Z'
                fill='currentColor'
            />
            <path
                d='M26.1861 37.9316L27.042 39.2292L26.7521 39.5466C26.2414 39.2568 26.0895 38.7184 25.8687 38.2215L26.1861 37.9316Z'
                fill='currentColor'
            />
            <path
                d='M47.0374 35.3767C47.3825 35.7218 47.6171 36.205 48.045 36.5224C47.5343 36.8399 47.3549 36.3154 47.1616 36.0945C47.0374 35.9151 46.5128 35.6942 47.0374 35.3767Z'
                fill='currentColor'
            />
            <path
                d='M25.8938 38.2216C26.2941 38.5667 26.4735 39.1051 26.7772 39.5468L26.4873 39.9471C26.5149 39.2845 25.88 38.967 25.7695 38.3735L25.8938 38.2216Z'
                fill='currentColor'
            />
            <path
                d='M24.6244 44.2119V44.3499C24.1965 44.4051 23.5615 44.0877 23.6167 44.9573C23.4787 44.4742 23.4787 43.9496 23.6167 43.4665C23.4787 44.3223 24.086 44.2671 24.6244 44.2119Z'
                fill='currentColor'
            />
            <path
                d='M43.5915 53.626H38.8844C38.139 53.5155 37.3936 53.5984 36.6482 53.5707C36.386 53.5431 36.1375 53.626 35.8752 53.5155H35.7372C35.5715 53.3499 35.3093 53.2809 35.1574 53.1428C34.7847 52.6873 34.2326 52.5631 33.6666 52.5631C33.3768 52.5631 32.9488 52.7287 32.7832 52.2732C35.1712 52.1904 37.4903 52.3008 39.8369 52.3836C40.0715 52.3836 40.2372 52.4664 40.3752 52.6459C40.955 53.2809 41.6314 53.6812 42.5562 53.5293C42.8737 53.4879 43.2878 53.3913 43.5915 53.626Z'
                fill='currentColor'
            />
            <path
                d='M32.8058 52.2452C33.0957 52.5075 33.4684 52.3833 33.7583 52.3556C34.766 52.2176 35.2767 52.8112 35.8012 53.5014C35.3181 53.4737 34.8212 53.4737 34.3104 53.529C33.2752 53.736 32.585 53.101 31.8672 52.5213C32.0328 52.2314 32.3503 52.4937 32.5022 52.2314H32.8058V52.2452Z'
                fill='currentColor'
            />
            <path
                d='M35.9014 53.4884C36.8814 53.5712 37.8753 53.3503 38.883 53.6264H35.9014V53.4884Z'
                fill='currentColor'
            />
            <path
                d='M44.0332 52.3563C45.1513 52.1217 45.9519 52.7014 46.5869 53.502C45.524 53.6539 44.682 53.1707 44.0332 52.3563Z'
                fill='currentColor'
            />
            <path
                d='M37.2026 27.5094L37.3406 27.7992L37.2026 27.9373C36.7746 28.5999 36.0845 28.9173 35.4771 29.3453C35.3667 29.29 35.3115 29.1796 35.3391 29.0554L37.2026 27.5094Z'
                fill='currentColor'
            />
            <path
                d='M56.4802 33.0862C56.0799 32.603 55.5277 32.2303 55.1826 31.6782H56.0108L56.4111 32.3132C56.6182 32.5616 56.7562 32.8239 56.4802 33.0862Z'
                fill='currentColor'
            />
            <path
                d='M30.4741 38.5385C31.0125 38.3728 31.3023 37.7102 31.8821 37.7102C31.9373 38.3452 31.3023 38.4004 31.0539 38.7455C30.8882 38.9112 30.7088 39.0354 30.4741 39.1182V38.5385Z'
                fill='currentColor'
            />
            <path
                d='M46.7486 24.9559C46.5416 25.3286 46.1413 25.4666 45.8238 25.7013C45.6581 25.8117 45.4787 25.8669 45.4787 26.1016H45.0508C45.5201 25.5632 45.8652 24.873 46.7486 24.9559Z'
                fill='currentColor'
            />
        </g>
        <defs>
            <clipPath id='clip0_8628_11009'>
                <rect width='90' height='90' fill='white' transform='translate(0.000976562)' />
            </clipPath>
        </defs>
    </svg>
)

export default IbmCloud
