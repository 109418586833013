import React from 'react'

const AskIcon = () => (
    <svg
        width='207'
        height='206'
        viewBox='0 0 207 206'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_1429_12756)'>
            <path
                d='M145.068 194.2C147.268 193.21 149.728 192.89 152.078 193.42L190.108 201.93C197.718 203.63 204.468 196.76 202.638 189.18L193.798 152.67C193.198 150.21 193.538 147.62 194.608 145.33C200.628 132.47 203.988 118.13 203.988 103C203.988 47.77 159.218 3 103.988 3C48.7583 3 3.98828 47.77 3.98828 103C3.98828 158.23 48.7583 203 103.988 203C118.628 203 132.528 199.85 145.058 194.2H145.068Z'
                stroke='currentcolor'
                strokeWidth='6'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M78.3281 77.3095C78.3281 65.9695 87.5281 56.7695 98.8681 56.7695H110.008C120.868 56.7695 129.678 65.5795 129.678 76.4395C129.678 83.3495 126.048 89.7495 120.128 93.3095L113.978 96.9995C107.788 100.71 104.008 107.4 104.008 114.61V128.65M103.998 149.2V149.3'
                stroke='#FF4802'
                strokeWidth='6'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </g>
        <defs>
            <clipPath id='clip0_1429_12756'>
                <rect width='207' height='206' fill='white' />
            </clipPath>
        </defs>
    </svg>
)

export default AskIcon
