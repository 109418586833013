import React from 'react'

const ArityLogo = () => (
    <svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 165 51'>
        <path
            fill='currentColor'
            strokeWidth='0'
            fillRule='evenodd'
            d='m115.19,11.55l7.52,19.18,6.76-19.18h8.28l-12.21,31.93c-.98,2.4-1.96,4.03-3.16,5.12-1.74,1.63-4.25,2.4-7.41,2.4-1.2,0-2.4-.11-3.6-.44v-7.08c.87.22,1.96.44,3.38.44,1.09,0,1.85-.22,2.4-.65.54-.44.76-.87,1.09-1.74l.44-1.2-11.88-28.77h8.39Zm-11.33,0v7.41h-6.1v9.81c0,2.62.98,3.71,3.49,3.71.65,0,1.42-.11,2.07-.22v7.19c-1.09.33-2.4.44-4.03.44-6.32,0-9.48-3.27-9.48-9.81v-11.12h-3.6v-7.41h17.65Zm-74.65-.44c4.14,0,7.41,1.74,8.17,4.14v-3.6h8.17v17.87c0,2.18.54,3.05,2.07,3.05.33,0,.87-.11,1.42-.11v6.76c-.87.33-2.07.44-3.71.44-1.85,0-3.38-.33-4.47-.98-1.31-.87-2.07-1.85-2.51-2.94-1.85,2.62-4.9,3.92-9.15,3.92-3.92,0-7.19-1.42-9.81-4.14-2.62-2.83-3.92-6.21-3.92-10.24s1.31-7.41,3.92-10.13c2.62-2.72,5.88-4.03,9.81-4.03Zm53.4.44v17.87c0,2.07.44,2.94,1.85,2.94.33,0,.65,0,.98-.11v6.97c-.65.33-1.96.44-3.81.44-4.79,0-7.19-2.62-7.19-7.74V11.55h8.17Zm-14.17-.54c.76,0,1.42.11,2.18.11v7.63c-.87-.22-1.85-.22-2.72-.33-4.69,0-7.41,2.62-7.41,7.19v13.4h-8.17V11.55h8.17v3.81c1.2-2.83,4.14-4.36,7.96-4.36Zm-37.81,7.52c-1.96,0-3.6.65-5.01,2.07-1.42,1.31-2.07,2.94-2.07,4.9s.65,3.6,2.07,4.9,3.05,1.96,5.01,1.96c2.07,0,3.71-.65,5.12-1.96,1.42-1.42,2.18-3.05,2.18-4.9s-.76-3.6-2.18-4.9c-1.42-1.42-3.05-2.07-5.12-2.07Zm114.31-6.97c2.51,0,4.58,2.07,4.58,4.58s-2.07,4.58-4.58,4.58-4.58-2.07-4.58-4.58c.11-2.51,2.18-4.58,4.58-4.58Zm0,.76c-2.07,0-3.81,1.74-3.81,3.81s1.74,3.81,3.81,3.81,3.81-1.74,3.81-3.81-1.63-3.81-3.81-3.81Zm.11,1.53c.54,0,.98.11,1.2.33.33.22.44.54.44.98,0,.33-.11.54-.22.76s-.33.33-.65.44l.98,1.85h-.87l-.87-1.74h-.87v1.74h-.76v-4.58h1.63v.22Zm.11.65h-.87v1.53h.76c.22,0,.44-.11.65-.22.11-.11.22-.33.22-.54s-.11-.44-.22-.54c-.11-.22-.33-.22-.54-.22ZM78.47,0c2.51,0,4.69,2.07,4.69,4.69s-2.07,4.58-4.69,4.58-4.69-2.07-4.69-4.58c.11-2.62,2.18-4.69,4.69-4.69Zm15.37,0c2.51,0,4.69,2.07,4.58,4.69,0,2.62-2.07,4.58-4.69,4.58s-4.69-2.07-4.69-4.58c.22-2.62,2.29-4.69,4.79-4.69Z'
        />
    </svg>
)

export default ArityLogo
