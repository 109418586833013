import React, { useEffect, useRef } from 'react'
import * as myStyles from './styles.module.scss'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import { NavigationMenuContentProps } from '@radix-ui/react-navigation-menu'
import { NavigationProps, TriggerProps } from './types'

const styles: any = myStyles

const NavigationListItem = React.forwardRef<
    HTMLAnchorElement,
    { children: React.ReactNode; href?: string; className?: string }
>(({ children, href, className }, ref) => {
    return (
        <li>
            <NavigationMenu.Link className={className} href={href} ref={ref} asChild>
                {children}
            </NavigationMenu.Link>
        </li>
    )
})
NavigationListItem.displayName = 'Navigation.ListItem'

const NavigationContent = React.forwardRef<HTMLDivElement, NavigationMenuContentProps>(
    ({ children, className, ...props }, ref) => {
        return (
            <NavigationMenu.Content
                ref={ref}
                className={`${styles['navigation_menu_content']} ${className}`}
                {...props}>
                {children}
            </NavigationMenu.Content>
        )
    },
)
NavigationContent.displayName = 'Navigation.Content'

const NavigationTrigger = React.forwardRef<
    React.ElementRef<typeof NavigationMenu.Trigger>,
    TriggerProps
>(({ chevronDownIcon, children, ...restProps }, forwardedRef) => {
    return (
        <NavigationMenu.Trigger {...restProps} ref={forwardedRef}>
            {children}
            {chevronDownIcon &&
                React.createElement(chevronDownIcon, {
                    'aria-hidden': 'true',
                    className: styles['caret-down'],
                })}
        </NavigationMenu.Trigger>
    )
})
NavigationTrigger.displayName = 'Navigation.Trigger'

const NavigationLink = React.forwardRef<
    HTMLAnchorElement,
    {
        children: React.ReactNode
        href?: string
        className?: string
        active?: boolean
        target?: React.HTMLAttributeAnchorTarget
    }
>(({ children, href, className, active, target }, ref) => {
    return (
        <NavigationMenu.Link
            active={active}
            className={className}
            href={href}
            ref={ref}
            target={target}>
            {children}
        </NavigationMenu.Link>
    )
})
NavigationLink.displayName = 'Navigation.Link'

const Navigation = ({
    className,
    children,
    contentClass,
    currentValue,
    setCurrentValue,
}: NavigationProps) => {
    const navRef = useRef<HTMLElement>(null)
    const viewRef = useRef<HTMLDivElement>(null)

    const updateTop = () => {
        if (!navRef.current || !viewRef.current) return

        const navBarHeight = navRef.current?.clientHeight
        const navBarYPosition = navRef.current?.getBoundingClientRect().y
        viewRef.current.style.top = navBarHeight + navBarYPosition + 'px'
    }

    useEffect(() => {
        if (typeof window !== 'undefined') window.addEventListener('resize', updateTop)
        return () => {
            window.removeEventListener('resize', updateTop)
        }
    }, [])

    useEffect(() => {
        if (navRef.current && viewRef.current) {
            updateTop()
        }
    }, [navRef.current, viewRef.current])

    return (
        <NavigationMenu.Root
            value={currentValue}
            onValueChange={setCurrentValue}
            ref={navRef}
            className={`${styles['b_navigation']} ${className}`}>
            <NavigationMenu.List className={styles['navigation_list']}>
                {children}
            </NavigationMenu.List>
            <div ref={viewRef} className={styles['viewport_position']}>
                <NavigationMenu.Viewport className={`${styles.menu} ${contentClass}`} />
            </div>
        </NavigationMenu.Root>
    )
}
Navigation.displayName = 'Navigation'

Navigation.List = NavigationMenu.List
Navigation.Item = NavigationMenu.Item
Navigation.Link = NavigationLink
Navigation.Trigger = NavigationTrigger
Navigation.Content = NavigationContent
Navigation.ListItem = NavigationListItem
Navigation.SubMenu = NavigationMenu.Sub

export default Navigation
