import React from 'react'

const SimplicityIcon = () => (
    <svg
        width='206'
        height='206'
        viewBox='0 0 206 206'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M140.53 25.61C125.93 11.62 106.13 3 84.3 3C39.4 3 3 39.4 3 84.31C3 106.13 11.62 125.93 25.61 140.54'
            stroke='currentcolor'
            strokeWidth='6'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M121.689 202.999C166.595 202.999 202.999 166.595 202.999 121.689C202.999 76.7826 166.595 40.3789 121.689 40.3789C76.7826 40.3789 40.3789 76.7826 40.3789 121.689C40.3789 166.595 76.7826 202.999 121.689 202.999Z'
            stroke='currentcolor'
            strokeWidth='6'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
)

export default SimplicityIcon
