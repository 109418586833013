import React from 'react'

const ProductIcon = () => (
    <svg
        width='207'
        height='206'
        viewBox='0 0 207 206'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_1429_12800)'>
            <path
                d='M164.708 163.71L203.998 203M189.708 95.86C189.708 147.14 148.138 188.72 96.8483 188.72C45.5583 188.72 3.98828 147.15 3.98828 95.86C3.98828 44.57 45.5583 3 96.8483 3C148.138 3 189.708 44.57 189.708 95.86Z'
                stroke='currentcolor'
                strokeWidth='6'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M96.8601 58.6289C114.52 58.6289 131.81 69.5889 143.38 90.7389C145.12 93.9289 145.12 97.7889 143.38 100.969C131.82 122.119 114.52 133.079 96.8601 133.079C79.2001 133.079 61.9102 122.119 50.3402 100.969C48.6002 97.7789 48.6002 93.9189 50.3402 90.7389C61.9002 69.5889 79.2001 58.6289 96.8601 58.6289Z'
                stroke='currentcolor'
                strokeWidth='6'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M81.7305 95.8583C81.7305 87.5083 88.5005 80.7383 96.8505 80.7383C105.2 80.7383 111.97 87.5083 111.97 95.8583C111.97 104.208 105.2 110.978 96.8505 110.978C88.5005 110.978 81.7305 104.208 81.7305 95.8583Z'
                stroke='#FF4802'
                strokeWidth='6'
                strokeLinejoin='round'
            />
        </g>
        <defs>
            <clipPath id='clip0_1429_12800'>
                <rect width='207' height='206' fill='white' />
            </clipPath>
        </defs>
    </svg>
)

export default ProductIcon
