import React from 'react'

const AdidasLogo = () => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 165 51'>
        <path
            fill='currentColor'
            fillRule='evenodd'
            strokeWidth='0'
            d='m123.11,51L93.68,0l-15.77,9.09,24.18,41.91h21.02Zm-28.05,0l-19.44-33.66-15.77,9.09,14.19,24.56h21.02Zm-37.41-16.28l9.39,16.28h-21.02l-4.14-7.19,15.76-9.09Z'
        />
    </svg>
)

export default AdidasLogo
