// extracted by mini-css-extract-plugin
export var blink = "styles-module--blink--QThI1";
export var body = "styles-module--body--RbdNc";
export var column = "styles-module--column--9ph2M";
export var column_title = "styles-module--column_title--b88g-";
export var content = "styles-module--content--BsXlT";
export var copy = "styles-module--copy--VN5Us";
export var footer = "styles-module--footer--00pQf";
export var legal_links = "styles-module--legal_links--gV3Br";
export var links = "styles-module--links--UAfXI";
export var logo = "styles-module--logo--0aGIw";
export var logo_mobile = "styles-module--logo_mobile--1dOyg";
export var m_footer = "styles-module--m_footer---2Y2d";
export var terms = "styles-module--terms--OJdjD";
export var text = "styles-module--text--QreV9";