import React from 'react'

const RequestQuoteIcon = () => (
    <svg
        width='178'
        height='206'
        viewBox='0 0 178 206'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_1429_12793)'>
            <path
                d='M59.4602 203.002H26.1302C13.8602 203.002 3.91016 193.052 3.91016 180.782V25.2317C3.91016 12.9617 13.8602 3.01172 26.1302 3.01172H137.24C149.51 3.01172 159.46 12.9617 159.46 25.2317V91.9017'
                stroke='currentcolor'
                strokeWidth='6'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M103.91 202.999V177.069L142.8 138.179C149.96 131.019 161.57 131.019 168.73 138.179C175.89 145.339 175.89 156.949 168.73 164.109L129.84 202.999H103.91Z'
                stroke='currentcolor'
                strokeWidth='6'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
            <path
                d='M55.3291 40.3302V33.9102M55.3291 98.0901V104.51M69.2291 49.9501C66.4591 46.1101 61.2691 43.5302 55.3291 43.5302H53.5491C45.6691 43.5302 39.2891 48.6402 39.2891 54.9402V55.4301C39.2891 59.9401 42.4691 64.0602 47.5091 66.0702L63.1591 72.3302C68.1991 74.3502 71.3791 78.4702 71.3791 82.9702C71.3791 89.5402 64.7191 94.8701 56.5091 94.8701H55.3391C49.3991 94.8701 44.2191 92.2901 41.4391 88.4501'
                stroke='#FF4802'
                strokeWidth='6'
                strokeLinecap='round'
            />
        </g>
        <defs>
            <clipPath id='clip0_1429_12793'>
                <rect width='178' height='206' fill='white' />
            </clipPath>
        </defs>
    </svg>
)

export default RequestQuoteIcon
