import React from 'react'

const TelnetLogo = () => (
    <svg
        width='112'
        height='29'
        viewBox='0 0 112 29'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M23.703 20.8486C23.6169 21.6654 23.2582 22.4294 22.6847 23.0174C22.1112 23.6054 21.3564 23.9831 20.542 24.0896C18.3689 24.3831 16.1778 24.5227 13.985 24.5076C11.7926 24.5228 9.60176 24.3831 7.429 24.0896C6.61461 23.9828 5.85985 23.605 5.28627 23.0171C4.71269 22.4292 4.35366 21.6653 4.267 20.8486C4.0033 18.6484 3.86974 16.4345 3.867 14.2186C3.86987 12.0023 4.00343 9.78811 4.267 7.58756C4.35378 6.77113 4.71292 6.00769 5.28654 5.42028C5.86017 4.83287 6.61486 4.45571 7.429 4.34957C9.60168 4.05513 11.7925 3.9151 13.985 3.93056C16.1778 3.91518 18.369 4.0552 20.542 4.34957C21.3562 4.45541 22.1109 4.8325 22.6844 5.41999C23.258 6.00748 23.6168 6.77109 23.703 7.58756C23.967 9.78807 24.1006 12.0023 24.103 14.2186C24.1008 16.4345 23.9672 18.6484 23.703 20.8486ZM27.411 5.05556C27.2916 3.927 26.7956 2.87153 26.003 2.05932C25.2104 1.24711 24.1673 0.725491 23.042 0.578559C20.0397 0.173259 17.0125 -0.0195496 13.983 0.00156292C10.9535 -0.0195463 7.92633 0.173262 4.92401 0.578559C3.79904 0.725606 2.75639 1.24734 1.96428 2.05959C1.17217 2.87184 0.676762 3.92725 0.557999 5.05556C0.191381 8.09588 0.00505645 11.1552 0 14.2176C0.00512656 17.2799 0.191451 20.3392 0.557999 23.3796C0.676853 24.5082 1.17255 25.5638 1.96506 26.3761C2.75757 27.1884 3.80067 27.7099 4.92601 27.8566C7.92823 28.2631 10.9555 28.4566 13.985 28.4356C17.0145 28.4566 20.0418 28.2631 23.044 27.8566C24.1693 27.7096 25.2124 27.188 26.005 26.3758C26.7976 25.5636 27.2936 24.5081 27.413 23.3796C27.7796 20.3392 27.9659 17.2799 27.971 14.2176C27.9653 11.1552 27.7783 8.09584 27.411 5.05556Z'
            fill='currentcolor'
        />
        <path
            d='M15.4732 11.555C16.1093 11.4642 16.7518 11.4264 17.3942 11.442C18.0851 11.4543 18.7739 11.5212 19.4542 11.642C19.4804 11.6501 19.5081 11.6515 19.535 11.646C19.5618 11.6406 19.5869 11.6285 19.6079 11.6109C19.6288 11.5933 19.6451 11.5708 19.6551 11.5453C19.6651 11.5198 19.6686 11.4922 19.6652 11.465V9.98804C19.6652 9.85304 19.4962 9.80904 19.4962 9.80904C18.811 9.62303 18.106 9.51963 17.3962 9.50104C16.7406 9.49068 16.0861 9.55781 15.4462 9.70104C15.4462 9.70104 15.2832 9.75204 15.2832 9.90104V11.362C15.2832 11.632 15.4712 11.562 15.4712 11.562'
            fill='currentcolor'
        />
        <path
            d='M10.3958 12.4595C10.3958 12.4595 12.5769 12.4597 12.5769 10.5967C12.5769 8.73368 10.3958 8.73438 10.3958 8.73438C10.3958 8.73438 8.21289 8.73468 8.21289 10.5967C8.21289 12.4587 10.3958 12.4595 10.3958 12.4595Z'
            fill='currentcolor'
        />
        <path
            d='M19.835 16.2148C18.0839 17.3139 16.0634 17.9084 13.996 17.9328C11.9281 17.9084 9.90691 17.314 8.15504 16.2148C8.15504 16.2148 7.87305 16.0148 7.87305 16.3908V17.1458C7.90237 17.3247 7.98989 17.4888 8.12205 17.6128C9.79027 18.969 11.8469 19.7588 13.994 19.8678C16.1413 19.7579 18.1979 18.9674 19.866 17.6108C19.9986 17.487 20.0865 17.3228 20.116 17.1438V16.3908C20.116 16.0108 19.832 16.2148 19.832 16.2148'
            fill='currentcolor'
        />
        <path
            d='M31.25 11.7291V11.5091C31.25 10.6891 31.7347 10.2791 32.704 10.2791H33.293V8.80613C33.26 8.58964 33.2774 8.36845 33.3441 8.15984C33.4107 7.95123 33.5247 7.76086 33.6771 7.60361C33.8295 7.44636 34.0162 7.32648 34.2226 7.25336C34.4291 7.18024 34.6496 7.15586 34.867 7.18212H35.155C36.1883 7.18212 36.705 7.72346 36.705 8.80613V10.2791H38.796C39.7333 10.2791 40.202 10.6735 40.202 11.4621V11.6481C40.202 12.4681 39.7253 12.8818 38.772 12.8891H36.633V17.8321C36.633 18.9688 37.0777 19.5375 37.967 19.5381C38.268 19.5379 38.5648 19.4676 38.8338 19.3327C39.1029 19.1979 39.3368 19.0022 39.517 18.7611C39.533 18.7305 39.609 18.7575 39.745 18.8421C39.9187 18.9594 40.0595 19.1191 40.154 19.3061C40.2854 19.5353 40.3517 19.796 40.346 20.0601C40.3406 20.367 40.2573 20.6676 40.1039 20.9334C39.9505 21.1993 39.732 21.4219 39.469 21.5801C38.7824 22.0123 37.9792 22.2218 37.169 22.1801C34.5737 22.1801 33.28 20.8575 33.288 18.2121V12.8871H32.699C31.733 12.8885 31.25 12.5025 31.25 11.7291Z'
            fill='currentcolor'
        />
        <path
            d='M41.2219 16.1609C41.1968 15.3454 41.3407 14.5334 41.6446 13.7762C41.9486 13.019 42.4059 12.3328 42.9879 11.7609C43.5838 11.1792 44.2912 10.724 45.0677 10.4229C45.8442 10.1218 46.6735 9.98099 47.5059 10.0089C48.2971 9.97625 49.0869 10.1032 49.828 10.3822C50.5692 10.6612 51.2466 11.0866 51.8199 11.6329C52.3713 12.1757 52.8035 12.8275 53.089 13.5467C53.3745 14.2659 53.5069 15.0367 53.4779 15.8099V16.0419C53.4835 16.2926 53.4138 16.5392 53.2779 16.7499C53.1977 16.8323 53.0995 16.895 52.9911 16.9331C52.8827 16.9713 52.767 16.9839 52.6529 16.9699H44.3529C44.3927 17.3662 44.5123 17.7503 44.7044 18.0992C44.8965 18.4481 45.1572 18.7544 45.4709 18.9999C46.1588 19.5048 46.9971 19.7624 47.8499 19.7309C48.5196 19.7846 49.1912 19.6528 49.791 19.3499C50.3907 19.047 50.8955 18.5848 51.2499 18.0139C51.2659 17.9833 51.4062 18.0026 51.6709 18.0719C51.9836 18.1565 52.2691 18.3205 52.4999 18.5479C52.6371 18.6672 52.7461 18.8154 52.8189 18.982C52.8917 19.1486 52.9266 19.3292 52.9209 19.5109C52.9209 20.1916 52.4482 20.8066 51.5029 21.3559C50.3396 21.9579 49.0381 22.2421 47.7299 22.1799C46 22.2651 44.304 21.679 42.9959 20.5439C42.4044 19.9824 41.94 19.3006 41.634 18.5446C41.328 17.7886 41.1875 16.9758 41.2219 16.1609ZM44.3459 15.0239H50.2578C50.2627 14.6594 50.1922 14.2977 50.0508 13.9616C49.9095 13.6255 49.7003 13.3222 49.4363 13.0706C49.1724 12.8191 48.8594 12.6247 48.5169 12.4997C48.1744 12.3746 47.8098 12.3216 47.4459 12.3439C46.6904 12.3275 45.9565 12.5967 45.3909 13.0979C44.8221 13.5932 44.451 14.2771 44.3459 15.0239Z'
            fill='currentcolor'
        />
        <path
            d='M55.5206 20.3724V5.73138C55.5206 5.68471 55.5766 5.63438 55.6886 5.58038C55.8468 5.51172 56.0121 5.46107 56.1816 5.42937C56.3909 5.38359 56.6044 5.36013 56.8186 5.35938C58.2606 5.35938 58.9856 6.25271 58.9936 8.03938V20.3724C58.9936 21.4317 58.485 21.9617 57.4676 21.9624H57.0956C56.8806 21.9918 56.6616 21.9708 56.4561 21.9011C56.2505 21.8313 56.064 21.7148 55.9112 21.5605C55.7584 21.4063 55.6437 21.2187 55.5759 21.0125C55.5081 20.8063 55.4892 20.5871 55.5206 20.3724Z'
            fill='currentcolor'
        />
        <path
            d='M61.0721 16.1609C61.047 15.3454 61.1909 14.5334 61.4948 13.7762C61.7988 13.019 62.2561 12.3328 62.8381 11.7609C63.434 11.1792 64.1414 10.724 64.9179 10.4229C65.6944 10.1218 66.5237 9.98099 67.3561 10.0089C68.1473 9.97625 68.9371 10.1032 69.6782 10.3822C70.4194 10.6612 71.0968 11.0866 71.6701 11.6329C72.2214 12.1758 72.6536 12.8276 72.9391 13.5468C73.2246 14.2659 73.357 15.0367 73.3281 15.8099V16.0419C73.3337 16.2926 73.264 16.5392 73.1281 16.7499C73.0479 16.8323 72.9498 16.895 72.8413 16.9331C72.7329 16.9713 72.6172 16.9839 72.5031 16.9699H64.2031C64.2429 17.3662 64.3625 17.7503 64.5546 18.0992C64.7468 18.4481 65.0075 18.7544 65.3211 18.9999C66.009 19.5048 66.8473 19.7624 67.7001 19.7309C68.3698 19.7846 69.0414 19.6528 69.6412 19.3499C70.2409 19.047 70.7457 18.5848 71.1001 18.0139C71.1161 17.9833 71.2564 18.0026 71.5211 18.0719C71.8338 18.1565 72.1194 18.3205 72.3501 18.5479C72.4873 18.6672 72.5963 18.8154 72.6691 18.982C72.7419 19.1486 72.7768 19.3292 72.7711 19.5109C72.7711 20.1916 72.2984 20.8066 71.3531 21.3559C70.1898 21.9578 68.8883 22.2421 67.5801 22.1799C65.8465 22.2691 64.1457 21.6839 62.8341 20.5469C62.2444 19.9839 61.782 19.3013 61.4781 18.5447C61.1742 17.7882 61.0358 16.9754 61.0721 16.1609ZM64.1961 15.0239H70.1081C70.1129 14.6594 70.0424 14.2977 69.9011 13.9616C69.7597 13.6255 69.5505 13.3222 69.2866 13.0706C69.0226 12.8191 68.7096 12.6247 68.3671 12.4997C68.0246 12.3746 67.66 12.3216 67.2961 12.3439C66.5406 12.3275 65.8067 12.5967 65.2411 13.0979C64.6723 13.5932 64.3012 14.2771 64.1961 15.0239Z'
            fill='currentcolor'
        />
        <path
            d='M75.2742 20.3746V10.5356C75.2742 10.505 75.3302 10.4587 75.4422 10.3967C75.5944 10.321 75.7562 10.2665 75.9232 10.2346C76.1354 10.1811 76.3534 10.1539 76.5722 10.1536C76.8349 10.1381 77.0981 10.1753 77.3463 10.2629C77.5945 10.3505 77.8226 10.4868 78.0174 10.6638C78.2122 10.8408 78.3696 11.0549 78.4805 11.2935C78.5914 11.5322 78.6535 11.7906 78.6632 12.0536C79.095 11.4033 79.686 10.8742 80.3801 10.5168C81.0741 10.1593 81.848 9.98545 82.6282 10.0116C83.2498 9.96463 83.8739 10.0604 84.4528 10.2916C85.0317 10.5228 85.55 10.8834 85.9682 11.3456C86.75 12.3298 87.1483 13.5641 87.0892 14.8196V20.3766C87.0892 21.4366 86.5806 21.9666 85.5632 21.9666H85.1892C84.9742 21.9959 84.7554 21.9748 84.55 21.905C84.3445 21.8352 84.1581 21.7186 84.0055 21.5644C83.8528 21.4102 83.7381 21.2226 83.6704 21.0165C83.6027 20.8104 83.5838 20.5913 83.6152 20.3766V15.3196C83.6534 14.662 83.448 14.0134 83.0382 13.4976C82.8321 13.2678 82.5768 13.0874 82.2912 12.97C82.0057 12.8526 81.6974 12.8012 81.3892 12.8196C80.8469 12.7887 80.3072 12.9159 79.8357 13.1857C79.3643 13.4555 78.9813 13.8564 78.7332 14.3396V20.3726C78.7332 21.4326 78.2245 21.9626 77.2072 21.9626H76.8472C76.6325 21.9917 76.414 21.9704 76.2089 21.9006C76.0037 21.8309 75.8176 21.7144 75.6651 21.5605C75.5127 21.4066 75.398 21.2193 75.3301 21.0136C75.2623 20.8078 75.2432 20.5891 75.2742 20.3746Z'
            fill='currentcolor'
        />
        <path
            d='M88.9019 16.1609C88.8768 15.3454 89.0208 14.5334 89.3247 13.7762C89.6286 13.019 90.0859 12.3328 90.6679 11.7609C91.2638 11.1792 91.9713 10.724 92.7477 10.4229C93.5242 10.1218 94.3536 9.98099 95.1859 10.0089C95.9771 9.97625 96.7669 10.1032 97.508 10.3822C98.2492 10.6612 98.9266 11.0866 99.4999 11.6329C100.053 12.1765 100.486 12.8296 100.772 13.5502C101.057 14.2709 101.19 15.0432 101.16 15.8179V16.0499C101.166 16.3006 101.096 16.5473 100.96 16.7579C100.88 16.8403 100.782 16.903 100.673 16.9411C100.565 16.9793 100.449 16.9919 100.335 16.9779H92.0349C92.0745 17.3741 92.1939 17.7582 92.3859 18.1071C92.5779 18.456 92.8384 18.7624 93.1519 19.0079C93.8398 19.5128 94.6781 19.7704 95.5309 19.7389C96.2006 19.7926 96.8723 19.6608 97.472 19.3579C98.0718 19.055 98.5765 18.5928 98.9309 18.0219C98.9469 17.9913 99.0872 18.0106 99.3519 18.0799C99.6646 18.1646 99.9501 18.3285 100.181 18.5559C100.318 18.6752 100.427 18.8234 100.5 18.99C100.573 19.1565 100.608 19.3372 100.602 19.5189C100.602 20.1996 100.129 20.8146 99.1839 21.3639C98.0206 21.9659 96.7191 22.2501 95.4109 22.1879C93.6806 22.2734 91.9843 21.6873 90.6759 20.5519C90.0834 19.9895 89.6184 19.3064 89.3123 18.5489C89.0063 17.7915 88.8664 16.9771 88.9019 16.1609ZM92.0259 15.0239H97.9379C97.9427 14.6594 97.8722 14.2977 97.7309 13.9616C97.5895 13.6255 97.3803 13.3222 97.1164 13.0706C96.8524 12.8191 96.5394 12.6247 96.1969 12.4997C95.8544 12.3746 95.4898 12.3216 95.1259 12.3439C94.3704 12.3275 93.6365 12.5967 93.0709 13.0979C92.502 13.5932 92.131 14.277 92.0259 15.0239Z'
            fill='currentcolor'
        />
        <path
            d='M102.096 11.7304V11.5104C102.096 10.6904 102.58 10.2804 103.55 10.2804H104.139V8.80739C104.105 8.59064 104.123 8.36912 104.189 8.16022C104.256 7.95131 104.37 7.76068 104.523 7.60327C104.676 7.44586 104.863 7.32593 105.069 7.25291C105.276 7.17989 105.497 7.15576 105.715 7.18239H106.003C107.036 7.18239 107.553 7.72371 107.553 8.80638V10.2794H109.644C110.581 10.2794 111.05 10.6737 111.05 11.4624V11.6484C111.05 12.4684 110.573 12.8821 109.62 12.8894H107.481V17.8324C107.481 18.969 107.925 19.5377 108.815 19.5384C109.116 19.5382 109.412 19.4679 109.682 19.333C109.951 19.1981 110.185 19.0024 110.365 18.7614C110.381 18.7307 110.457 18.7577 110.593 18.8424C110.766 18.9597 110.907 19.1194 111.002 19.3064C111.133 19.5356 111.199 19.7963 111.194 20.0604C111.188 20.3673 111.105 20.6678 110.952 20.9337C110.798 21.1996 110.58 21.4221 110.317 21.5804C109.63 22.0125 108.827 22.2221 108.017 22.1804C105.421 22.1804 104.128 20.8577 104.136 18.2124V12.8874H103.547C102.579 12.8894 102.096 12.5037 102.096 11.7304Z'
            fill='currentcolor'
        />
    </svg>
)

export default TelnetLogo
