import React from 'react'

const WarmthIcon = () => (
    <svg
        width='207'
        height='160'
        viewBox='0 0 207 160'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M3.99828 156.189H62.8083C78.3483 156.189 91.3383 144.439 92.7883 129.069L94.3383 112.629C95.9883 95.1186 83.5983 84.7586 65.8983 84.7586C57.0183 84.7586 52.4183 70.4686 42.0183 70.4686C31.6183 70.4686 25.9583 81.4386 20.9483 89.9386C19.3283 92.6786 16.3683 94.2786 13.1783 94.2786H3.98828M203.998 156.189H145.188C129.648 156.189 116.658 144.439 115.208 129.069L113.658 112.629C112.008 95.1186 124.398 84.7586 142.098 84.7586C150.978 84.7586 155.578 70.4686 165.978 70.4686C176.378 70.4686 182.038 81.4386 187.048 89.9386C188.668 92.6786 191.628 94.2786 194.818 94.2786H204.008M103.998 3.80859V27.6186M51.9583 22.7486L67.2583 40.9886M156.018 22.7486L140.718 40.9886'
            stroke='currentcolor'
            strokeWidth='6'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
)

export default WarmthIcon
