import React from 'react'

const NuvoLogo = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='104'
        height='22'
        viewBox='0 0 104 22'
        fill='none'>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M50.5111 0.00848443V11C50.5111 14.5089 48.879 17.6289 46.3303 19.6411C44.4708 21.1123 42.1239 21.9916 39.5746 21.9916C37.0254 21.9916 34.6779 21.1123 32.8189 19.6411C30.2782 17.6289 28.6376 14.5003 28.6376 10.9916V0H32.8189V10.9916C32.8189 14.7456 35.8476 17.7895 39.5746 17.7895C43.3016 17.7895 46.3303 14.7456 46.3303 10.9916V0L50.5111 0.00848443ZM0 22V11.0085C0 7.4996 1.63257 4.37971 4.18131 2.36742C6.04079 0.896254 8.38822 0.0169183 10.937 0.0169183C13.4862 0.0169183 15.8332 0.896254 17.6926 2.36742C20.2334 4.37971 21.8739 7.50809 21.8739 11.0085V22H17.6926V11.0085C17.6926 7.25442 14.664 4.21906 10.937 4.21906C7.20195 4.21906 4.18131 7.2629 4.18131 11.0085V22H0ZM76.4484 4.21063L78.0804 0.00848443L73.5879 0L71.9558 4.2022L69.2213 11.2368L67.1349 16.6142L65.0405 11.2452L62.306 4.21063L60.6739 0.00848443H56.1899L57.822 4.21063L63.1052 17.7979L64.7373 22H69.5331L71.1651 17.7979L76.4484 4.21063ZM92.5506 17.7979C88.8151 17.7979 85.7949 14.754 85.7949 11C85.7949 7.24598 88.8236 4.21063 92.5506 4.21063C96.2861 4.21063 99.3063 7.25442 99.3063 11C99.3063 14.7455 96.2861 17.7979 92.5506 17.7979ZM92.5506 0.00843387C86.5103 0.00843387 81.6136 4.92927 81.6136 11C81.6136 17.0708 86.5103 21.9916 92.5506 21.9916C98.5909 21.9916 103.487 17.0708 103.487 11C103.487 4.92927 98.5909 0.00843387 92.5506 0.00843387Z'
            fill='currentcolor'
        />
    </svg>
)

export default NuvoLogo
