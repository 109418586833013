import React, { ForwardedRef, KeyboardEvent, MouseEvent } from 'react'
import * as myStyles from './styles.module.scss'
import { ClickableAreaProps } from './types'

const ClickableArea = React.forwardRef(
    (
        {
            children,
            onClick,
            href,
            role = 'button',
            disabled = false,
            className,
            id,
            ariaLabel,
            target,
            variant = 'primary',
            color = 'orange',
            size = 'm',
            hasIcon = false,
            isFullWidth = false,
        }: ClickableAreaProps,
        ref: ForwardedRef<any>,
    ) => {
        const Tag = href ? 'a' : 'div'

        const enterToClick = (e: KeyboardEvent<HTMLAnchorElement | HTMLDivElement>) => {
            if (e.key === 'Enter' && onClick) {
                onClick(href || null, e, {
                    target: href ?? '',
                    component: 'clickable-area',
                })
            }
        }

        const handleClick = (e: MouseEvent<HTMLAnchorElement | HTMLDivElement>) => {
            onClick?.(href || null, e, {
                target: href ?? '',
                component: 'clickable-area',
            })
        }

        const clickableProps = {
            disabled: disabled,
            href: !disabled && href ? href : undefined,
            role: !href ? role : undefined,
            target: href && target ? target : undefined,
            tabIndex: !href ? 0 : undefined,
            onClick: !disabled ? handleClick : undefined,
            onKeyDown: !disabled && onClick ? enterToClick : undefined,
            ['aria-label']: ariaLabel ?? '',
            ['data-click-enabled']: !href && onClick && !disabled ? true : undefined,
        }

        const styles: any = myStyles

        return (
            <Tag
                id={id}
                ref={ref}
                data-color={color}
                data-variant={variant}
                data-has-icon={hasIcon}
                data-size={size}
                data-is-disabled={disabled}
                data-full-width={isFullWidth}
                className={`${styles.b_clickable_area} ${className || ''}`}
                {...clickableProps}>
                {children}
            </Tag>
        )
    },
)
ClickableArea.displayName = 'ClickableArea'

export default ClickableArea
