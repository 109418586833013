import React from 'react'

const GeotabLogo = () => (
    <svg
        width='1790'
        height='231'
        viewBox='0 0 1790 231'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M1790 198.74C1790.03 205.54 1787.69 212.139 1783.4 217.414C1779.11 222.689 1773.12 226.314 1766.46 227.673C1759.8 229.031 1752.87 228.039 1746.85 224.865C1740.84 221.691 1736.11 216.532 1733.47 210.265C1730.83 203.997 1730.45 197.009 1732.38 190.489C1734.31 183.97 1738.45 178.321 1744.07 174.504C1749.7 170.687 1756.48 168.938 1763.25 169.555C1770.02 170.171 1776.37 173.115 1781.22 177.885C1784.04 180.573 1786.27 183.811 1787.78 187.399C1789.29 190.987 1790.05 194.848 1790 198.74ZM1786 198.74C1785.98 195.465 1785.31 192.227 1784.02 189.213C1782.74 186.199 1780.88 183.468 1778.54 181.178C1773.83 176.408 1767.42 173.701 1760.72 173.652C1754.02 173.603 1747.57 176.215 1742.8 180.915L1742.53 181.178C1737.75 185.983 1735.07 192.487 1735.07 199.267C1735.07 206.047 1737.75 212.551 1742.53 217.356C1747.24 222.126 1753.65 224.833 1760.35 224.882C1767.05 224.932 1773.5 222.319 1778.27 217.619L1778.54 217.356C1780.99 214.964 1782.91 212.091 1784.2 208.917C1785.48 205.744 1786.1 202.339 1786 198.916V198.74ZM1779.46 213.887H1771.82L1760.58 201.813H1755.09V213.887H1749.34V182.627H1759.96C1761.7 182.518 1763.45 182.518 1765.19 182.627C1766.62 182.821 1768 183.267 1769.27 183.944C1770.55 184.599 1771.62 185.584 1772.39 186.798C1773.09 188.072 1773.43 189.516 1773.35 190.969C1773.41 192.975 1772.73 194.931 1771.42 196.457C1769.96 198.029 1768.14 199.219 1766.11 199.925L1779.46 213.887ZM1767.47 191.627C1767.5 190.869 1767.35 190.115 1767.03 189.427C1766.68 188.748 1766.13 188.195 1765.45 187.846C1764.77 187.484 1764.02 187.247 1763.25 187.146H1755.17V197.683H1759.56C1760.77 197.81 1762 197.81 1763.21 197.683C1764.11 197.53 1764.97 197.169 1765.71 196.629C1766.35 196.15 1766.86 195.515 1767.2 194.785C1767.59 193.735 1767.71 192.602 1767.55 191.492L1767.47 191.627Z'
            fill='currentcolor'
        />
        <path
            d='M322.373 228.165H544.321V205.466H362.466V123.088H536.794V100.33H362.466V25.3596H544.321V2.60156H322.373V228.165Z'
            fill='currentcolor'
        />
        <path
            d='M842.929 19.0157C826.75 6.62966 785.561 0.347656 720.813 0.347656C664.807 0.347656 625.989 5.85966 605.453 16.7347C584.917 27.6097 574.221 48.4407 574.221 78.3697L573.721 99.6757V115.411C573.721 166.942 581.811 199.182 597.664 211.865C613.517 224.548 655.064 230.652 720.817 230.652C786.57 230.652 828.531 224.133 843.97 211.687C859.409 199.241 867.913 165.727 867.913 112.477C867.583 62.9907 859.049 31.5207 842.929 19.0157ZM829.12 115.292C829.12 161.192 824.586 188.366 815.548 196.07C806.51 203.774 774.507 207.745 720.398 207.745C666.289 207.745 634.464 203.804 625.574 196.07C616.684 188.336 612.001 161.193 612.001 115.293C612.001 69.3927 616.683 42.3927 625.691 34.6927C634.699 26.9927 666.673 23.0177 720.515 23.0177C767.423 23.0177 797.56 26.3657 810.272 32.9447C822.984 39.5237 829.12 55.2267 829.12 79.4997V115.292Z'
            fill='currentcolor'
        />
        <path
            d='M1146.16 2.60156H883.229V27.4926H995.121V228.165H1035.27V27.4926H1146.16V2.60156Z'
            fill='currentcolor'
        />
        <path
            d='M1287.86 2.60156H1232.71L1106.18 228.165H1148.8L1175.2 180.753H1348.28L1374.95 228.165H1416.85L1288.04 3.16456L1287.86 2.60156ZM1187.11 160.277L1261.19 25.3896L1336.22 160.218L1187.11 160.277Z'
            fill='currentcolor'
        />
        <path
            d='M1665.05 109.902C1693.17 103.027 1707.49 87.1146 1707.49 62.4896C1707.49 37.8646 1700.91 21.6556 1687.66 14.0406C1674.42 6.42556 1646.74 2.60156 1604.69 2.60156H1452V228.165H1620.9C1656.46 228.165 1681.71 223.246 1696.11 213.615C1710.51 203.984 1717.89 186.946 1717.89 162.943C1718.07 133.845 1700.23 116.002 1665.05 109.902ZM1492.21 25.3596H1603.92C1631.33 25.3596 1649.29 27.5816 1657.26 31.9376C1665.23 36.2936 1669.11 45.8946 1669.11 60.6516C1669.11 77.7196 1664.58 88.8326 1655.54 93.6916C1646.5 98.5506 1625.61 101.159 1593.31 101.159H1492.21V25.3596ZM1666.36 197.881C1657.47 202.8 1638 205.23 1608.72 205.23H1492.21V123.918H1591.27C1631.9 123.918 1657.08 126.2 1666.18 130.674C1675.28 135.148 1679.63 147.239 1679.63 166.944C1679.69 182.649 1675.22 193.08 1666.36 197.881Z'
            fill='currentcolor'
        />
        <path
            d='M136.1 115.293V138.021H247.284V151.059V151.889C247.284 176.958 242.306 192.689 232.912 198.56C223.518 204.431 197.56 207.66 156.43 207.66C102.558 207.66 69.13 204.43 56.805 198.029C44.48 191.628 38.522 174.529 38.522 146.765V114.765L38.907 84.8647C38.907 57.4547 44.478 40.0897 55.412 33.2747C66.346 26.4597 94.617 22.8997 139.273 22.8997C187.159 22.8997 217.592 25.0627 229.03 29.3597C240.468 33.6567 245.3 45.0337 245.3 62.9907V68.9177H284.474V59.8177C284.474 34.8077 275.792 18.3317 258.634 11.1607C241.476 3.98966 203.783 0.347656 145.614 0.347656C87.445 0.347656 48.42 5.88866 29.218 16.8527C10.016 27.8167 0 50.3677 0 83.6447V136.539C0 176.158 8.89 202 26.225 213.376C43.56 224.752 82.882 230.563 143.274 230.563C207.191 230.563 247.284 225.703 262.486 216.073C277.688 206.443 284.503 184.011 285.451 147.918V115.318L136.1 115.293Z'
            fill='currentcolor'
        />
    </svg>
)

export default GeotabLogo
