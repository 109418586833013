import React from 'react'

const DownloadBigIcon = () => (
    <svg
        width='206'
        height='206'
        viewBox='0 0 206 206'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_1435_12653)'>
            <path
                d='M103 144.18V3M103 144.18L61.8203 103M103 144.18L144.18 103'
                stroke='currentcolor'
                strokeWidth='6'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M203 132.41V179.47C203 192.47 192.47 203 179.47 203H26.53C13.54 203 3 192.47 3 179.47V132.41'
                stroke='currentcolor'
                strokeWidth='6'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </g>
        <defs>
            <clipPath id='clip0_1435_12653'>
                <rect width='206' height='206' fill='currentcolor' />
            </clipPath>
        </defs>
    </svg>
)

export default DownloadBigIcon
