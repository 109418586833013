import React from 'react'

const CrossIcon = () => {
    return (
        <svg
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_8567_22121)'>
                <path d='M4.0415 4.05029L13.941 13.9498' stroke='#1B1A19' />
                <path d='M13.9409 4.05029L4.04142 13.9498' stroke='#1B1A19' />
            </g>
            <defs>
                <clipPath id='clip0_8567_22121'>
                    <rect width='18' height='18' fill='white' transform='translate(-0.00878906)' />
                </clipPath>
            </defs>
        </svg>
    )
}

export default CrossIcon
