// extracted by mini-css-extract-plugin
export var blink = "styles-module--blink--DUwOw";
export var c_navigation = "styles-module--c_navigation--lXMnb";
export var content_wrapper = "styles-module--content_wrapper--MsHDm";
export var description = "styles-module--description--v-9uR";
export var icon = "styles-module--icon--ROzFC";
export var icons_wrapper = "styles-module--icons_wrapper--pSj0+";
export var link = "styles-module--link--42OJ9";
export var navigation_content_item = "styles-module--navigation_content_item--fm1FA";
export var navigation_menu_content = "styles-module--navigation_menu_content--HK7pk";
export var section = "styles-module--section--EUa2Z";
export var social_wrapper = "styles-module--social_wrapper--ejNFd";
export var title = "styles-module--title--5ROKo";
export var trigger = "styles-module--trigger--9P2Hk";