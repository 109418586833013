/* eslint-disable react/prop-types */
import React from 'react'
import * as myStyles from './styles.module.scss'
import { AccordionContentProps, AccordionProps, AccordionTriggerProps } from './types'
import * as Accordion from '@radix-ui/react-accordion'

const styles: any = myStyles

const AccordionTrigger = React.forwardRef<
    React.ElementRef<typeof Accordion.Trigger>,
    AccordionTriggerProps
>(
    (
        { children, className, titleTag = 'h3', typeset = 'bd5-xl', color = 'bw-black', ...props },
        forwardedRef,
    ) => {
        const TitleTag = titleTag
        const types = typeset.replace(/\s/g, '').split(',')
        const dataUseType = types.join(', ')
        const colors = color ? color.replace(/\s/g, '').split(',') : null
        const dataColor = colors ? colors.join(', ') : null

        return (
            <Accordion.Header asChild>
                <Accordion.Trigger
                    className={`${styles['accordion_trigger']} ${className}`}
                    ref={forwardedRef}
                    {...props}>
                    <TitleTag
                        data-use-type={dataUseType}
                        data-use-color={dataColor}
                        className={styles.title}>
                        {children}
                    </TitleTag>
                    <span className={styles.icon} />
                </Accordion.Trigger>
            </Accordion.Header>
        )
    },
)
AccordionTrigger.displayName = 'AccordionTrigger'

const AccordionContent = React.forwardRef<
    React.ElementRef<typeof Accordion.Content>,
    AccordionContentProps
>(
    (
        { children, className, textTag = 'p', typeset = 'bd4-xl', color = 'trans-d50', ...props },
        forwardedRef,
    ) => {
        const TextTag = textTag
        const types = typeset.replace(/\s/g, '').split(',')
        const dataUseType = types.join(', ')
        const colors = color ? color.replace(/\s/g, '').split(',') : null
        const dataColor = colors ? colors.join(', ') : null

        return (
            <Accordion.Content
                className={`${styles['accordion_content']} ${className}`}
                ref={forwardedRef}
                {...props}>
                <TextTag
                    data-use-type={dataUseType}
                    data-use-color={dataColor}
                    className={styles.text}>
                    {children}
                </TextTag>
            </Accordion.Content>
        )
    },
)
AccordionContent.displayName = 'AccordionContent'

const AccordionItem = React.forwardRef<
    React.ElementRef<typeof Accordion.Item>,
    Accordion.AccordionItemProps
>(({ children, className, ...props }, forwardedRef) => (
    <Accordion.Item
        className={`${styles['accordion_item']} ${className}`}
        ref={forwardedRef}
        {...props}>
        {children}
    </Accordion.Item>
))
AccordionItem.displayName = 'AccordionItem'

const AccordionComponent: React.FC<AccordionProps> & {
    Item: typeof Accordion.Item
    Content: typeof AccordionContent
    Trigger: typeof AccordionTrigger
} = ({
    children,
    className,
    tag = 'div',
    disableLineAnimation = false,
    hideTriggerIcon = false,
    ...props
}) => {
    const WrapperTag = tag
    return (
        <Accordion.Root
            asChild
            data-disable-line={disableLineAnimation}
            data-hide-trigger-icon={hideTriggerIcon}
            className={`${styles['b_accordion']} ${className}`}
            {...props}>
            <WrapperTag>{children}</WrapperTag>
        </Accordion.Root>
    )
}
AccordionComponent.displayName = 'Accordion'

AccordionComponent.Item = AccordionItem
AccordionComponent.Content = AccordionContent
AccordionComponent.Trigger = AccordionTrigger

export default AccordionComponent
