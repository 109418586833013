import React from 'react'

const Cloudera = () => (
    <svg
        width='132'
        height='16'
        viewBox='0 0 132 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_8628_10929)'>
            <path
                d='M55.4771 15.9475C54.8738 15.8787 54.2771 15.777 53.7033 15.5737C51.5131 14.7934 50.1984 13.2295 49.6082 11.0131C49.418 10.2983 49.3394 9.56718 49.3394 8.8262C49.3394 6.06554 49.3394 3.30488 49.3394 0.544229C49.3394 0.393409 49.3787 0.357344 49.5262 0.357344C50.5853 0.363901 51.6443 0.363901 52.7066 0.357344C52.8541 0.357344 52.8836 0.399967 52.8836 0.54095C52.8803 3.29505 52.8836 6.05243 52.8803 8.80652C52.8803 9.59013 52.9951 10.3508 53.359 11.0557C54.0148 12.3311 55.2508 12.8491 56.5033 12.7541C58.0967 12.6328 59.1853 11.518 59.4771 9.87538C59.5394 9.53111 59.5656 9.18357 59.5656 8.83275C59.5656 6.0721 59.5656 3.31144 59.5623 0.550786C59.5623 0.39013 59.6017 0.354065 59.759 0.354065C60.818 0.360622 61.8771 0.360622 62.9394 0.354065C63.0705 0.354065 63.1164 0.380294 63.1164 0.521278C63.1131 3.30488 63.123 6.08521 63.1098 8.86882C63.1033 10.236 62.8344 11.5475 62.1492 12.7475C61.1918 14.4295 59.7295 15.4164 57.8476 15.8098C57.5426 15.8721 57.2344 15.9049 56.923 15.9442C56.8377 15.9869 56.7426 15.9311 56.6607 15.977H56.5623C56.3492 15.9409 56.1328 15.9705 55.9164 15.9606C55.8574 15.9606 55.7984 15.9606 55.7394 15.9606C55.6541 15.9508 55.5656 15.9803 55.4803 15.9442L55.4771 15.9475Z'
                fill='currentColor'
            />
            <path
                d='M9.32058 0.0359315C10.0288 0.111341 10.7239 0.249046 11.3927 0.498227C12.3862 0.868718 13.2583 1.42282 14.0157 2.16052C14.1042 2.24905 14.1009 2.29167 14.0157 2.37691C13.3239 3.05888 12.6353 3.74741 11.9534 4.43593C11.8583 4.53101 11.8124 4.53429 11.7107 4.44249C10.3107 3.17691 8.35337 2.872 6.67796 3.67528C5.35337 4.31134 4.56321 5.39659 4.25829 6.81298C3.93698 8.29167 4.14353 9.7015 5.05173 10.9408C6.05173 12.3081 7.44189 12.8523 9.11403 12.7146C10.2517 12.6228 11.1862 12.1113 11.9599 11.2884C12.0419 11.1999 12.0845 11.1933 12.173 11.2818C12.855 11.9736 13.5435 12.6589 14.2353 13.3408C14.3239 13.4294 14.3173 13.4753 14.2353 13.5638C13.0321 14.8556 11.5468 15.6064 9.80911 15.8753C9.64845 15.9015 9.48452 15.9179 9.32386 15.9408C9.23862 15.9769 9.15009 15.9507 9.06485 15.9572C9.00583 15.9572 8.94681 15.9572 8.8878 15.9572C8.6878 15.9572 8.49107 15.9572 8.29107 15.9572C8.23206 15.9572 8.17632 15.9572 8.1173 15.9572C8.03534 15.9572 7.95337 15.9572 7.8714 15.9572C6.92058 15.9212 6.01894 15.672 5.15009 15.2949C4.45173 14.9933 3.81894 14.5933 3.23534 14.1113C2.45173 13.4622 1.82878 12.6851 1.34681 11.79C0.940255 11.0327 0.671403 10.2294 0.520583 9.38347C0.481239 9.15724 0.47796 8.92773 0.458288 8.7015C0.448452 8.62609 0.47796 8.55068 0.441895 8.47528V8.37691C0.47796 8.29823 0.451731 8.21626 0.458288 8.13757C0.458288 8.03593 0.458288 7.93429 0.458288 7.83265C0.448452 7.75396 0.47796 7.66872 0.441895 7.59003V7.49167C0.474681 7.41954 0.451731 7.34085 0.458288 7.26872C0.556649 5.65888 1.13698 4.23265 2.14026 2.98347C3.50419 1.28839 5.30747 0.334292 7.46812 0.0424888C7.59599 0.0260954 7.73042 0.0293741 7.85829 0.019538C7.93042 0.019538 8.00255 0.019538 8.07468 0.019538C8.13042 0.019538 8.18944 0.019538 8.24517 0.019538C8.39271 0.019538 8.53698 0.019538 8.68452 0.019538C8.76321 0.019538 8.83862 0.019538 8.9173 0.019538C8.97632 0.019538 9.03206 0.019538 9.09107 0.019538C9.16648 0.0260954 9.24517 0.00314458 9.3173 0.0359315H9.32058Z'
                fill='currentColor'
            />
            <path
                d='M83.46 0.363935C83.5518 0.363935 83.6042 0.363935 83.6567 0.363935C87.9682 0.363935 92.2764 0.363935 96.5878 0.360657C96.7419 0.360657 96.7878 0.393444 96.7878 0.554099C96.778 1.48197 96.7813 2.40984 96.7878 3.33771C96.7878 3.47541 96.7616 3.52459 96.6108 3.52459C93.0108 3.52131 89.4108 3.52131 85.8108 3.52459C85.719 3.52459 85.6501 3.52459 85.6042 3.41639C85.0993 2.29836 84.4042 1.31803 83.5354 0.45246C83.5157 0.432788 83.5026 0.413116 83.4632 0.367214L83.46 0.363935Z'
                fill='currentColor'
            />
            <path
                d='M83.6128 15.6228C84.1997 15.013 84.6849 14.3868 85.0948 13.6982C85.3013 13.3507 85.4915 12.9933 85.6423 12.6196C85.6915 12.495 85.7603 12.4655 85.8849 12.4655C89.4784 12.4687 93.0751 12.4687 96.6685 12.4655C96.8062 12.4655 96.8554 12.4884 96.8554 12.6425C96.8489 13.5802 96.8489 14.5212 96.8554 15.4589C96.8554 15.5769 96.8423 15.6294 96.7013 15.6294C92.3866 15.6261 88.0685 15.6261 83.7538 15.6261C83.7243 15.6261 83.6915 15.6261 83.6161 15.6261L83.6128 15.6228Z'
                fill='currentColor'
            />
            <path
                d='M90.7049 6.38029C92.0688 6.38029 93.436 6.38029 94.8 6.37701C94.9475 6.37701 94.9803 6.41636 94.9803 6.55734C94.9737 7.48521 94.9737 8.41308 94.9803 9.33767C94.9803 9.46226 94.9541 9.50488 94.8196 9.50488C92.0852 9.5016 89.3475 9.5016 86.6131 9.50488C86.5049 9.50488 86.4623 9.49505 86.4786 9.3639C86.5901 8.43275 86.5901 7.50488 86.4557 6.57701C86.4327 6.42292 86.4557 6.37701 86.6262 6.37701C87.9868 6.38357 89.3442 6.38029 90.7049 6.38029Z'
                fill='currentColor'
            />
            <path
                d='M17.7412 7.99645C17.7412 5.51448 17.7412 3.03251 17.7412 0.550546C17.7412 0.406284 17.7707 0.357103 17.9248 0.360382C18.9838 0.366939 20.0428 0.366939 21.1051 0.360382C21.256 0.360382 21.292 0.399726 21.292 0.547267C21.2888 4.4653 21.2888 8.38005 21.292 12.2981C21.292 12.544 21.2592 12.5112 21.5117 12.5112C23.7609 12.5112 26.0133 12.5112 28.2625 12.5112C28.4101 12.5112 28.4527 12.5473 28.4527 12.6981C28.4461 13.6194 28.4461 14.5407 28.4527 15.4653C28.4527 15.5866 28.4297 15.6325 28.292 15.6325C24.8297 15.6292 21.3674 15.6292 17.9019 15.6325C17.7642 15.6325 17.7445 15.5866 17.7445 15.4653C17.7445 12.9768 17.7445 10.4883 17.7445 8.00301L17.7412 7.99645Z'
                fill='currentColor'
            />
            <path
                d='M45.7298 6.48532C45.2576 3.95746 43.8806 2.07221 41.5855 0.882046C40.6281 0.386963 39.5986 0.134504 38.5265 0.0361438C38.4412 -0.00647913 38.3462 0.0492586 38.2642 0.00335693H38.1986C38.1101 0.0394225 38.015 0.013193 37.9232 0.0197504C37.7527 0.00991431 37.5822 0.0394225 37.4117 0.00335693H37.3134C37.2314 0.0492586 37.133 -0.00647913 37.0511 0.0361438C36.7494 0.0787668 36.4445 0.108275 36.1429 0.167291C33.7462 0.649259 31.9068 1.91811 30.7232 4.06565C29.7429 5.8427 29.4904 7.75418 29.9134 9.72467C30.5101 12.5017 32.1626 14.4165 34.8183 15.4525C35.5363 15.7312 36.2871 15.8689 37.0478 15.9476C37.133 15.9837 37.2216 15.9575 37.3101 15.964C37.3691 15.964 37.4281 15.964 37.4871 15.964C37.697 15.964 37.9068 15.964 38.1166 15.964C38.1757 15.964 38.2347 15.964 38.2937 15.964C38.3691 15.9575 38.4478 15.9804 38.5232 15.9476C38.838 15.9083 39.1527 15.8788 39.4642 15.8165C41.897 15.3214 43.756 14.0296 44.9363 11.8329C45.8412 10.1509 46.074 8.34434 45.7265 6.48205L45.7298 6.48532ZM41.7363 10.2361C41.0445 11.7279 39.874 12.5902 38.2248 12.7411C35.8609 12.9575 33.9199 11.4427 33.5101 9.09516C33.4478 8.7345 33.4019 8.37385 33.4117 8.00336C33.4183 6.97385 33.6445 6.00008 34.2183 5.13123C34.9658 4.00008 36.0216 3.36401 37.3757 3.23942C38.6773 3.12139 39.8314 3.47549 40.7855 4.39024C41.5462 5.12139 41.9593 6.03614 42.1232 7.06565C42.297 8.15746 42.2052 9.22631 41.7396 10.2329L41.7363 10.2361Z'
                fill='currentColor'
            />
            <path
                d='M131.5 15.4751C130.93 14.1407 130.359 12.8062 129.789 11.4718C128.228 7.81935 126.667 4.17017 125.11 0.514433C125.057 0.393121 124.998 0.357056 124.87 0.357056C123.92 0.363613 122.969 0.363613 122.018 0.357056C121.903 0.357056 121.848 0.393121 121.802 0.498039C121.51 1.20296 121.208 1.9046 120.913 2.60951C119.103 6.89476 117.29 11.18 115.477 15.4653C115.418 15.603 115.448 15.6226 115.585 15.6226C116.674 15.6194 117.759 15.6194 118.848 15.6226C118.962 15.6226 119.018 15.5898 119.061 15.4816C119.428 14.5407 119.805 13.6062 120.172 12.6653C120.218 12.5439 120.277 12.5046 120.408 12.5046C122.464 12.5112 124.516 12.5079 126.572 12.5046C126.697 12.5046 126.752 12.5407 126.798 12.6554C127.169 13.5997 127.546 14.5407 127.92 15.4849C127.956 15.5735 127.992 15.6226 128.103 15.6226C129.257 15.6161 130.408 15.6194 131.562 15.6194V15.5866C131.543 15.5472 131.52 15.5112 131.5 15.4718V15.4751ZM125.428 9.67181C124.133 9.66853 122.841 9.67181 121.546 9.67181C121.444 9.67181 121.362 9.6882 121.425 9.53083C122.1 7.83902 122.772 6.14722 123.444 4.45542C123.451 4.43574 123.464 4.41607 123.493 4.36033C123.654 4.76689 123.805 5.13738 123.952 5.51115C124.484 6.8423 125.008 8.17673 125.546 9.50788C125.605 9.65542 125.572 9.67509 125.428 9.67509V9.67181Z'
                fill='currentColor'
            />
            <path
                d='M81.9667 7.1082C81.7405 5.00001 80.8028 3.28853 79.0847 2.02952C77.5339 0.89181 75.7569 0.406564 73.8585 0.373777C71.7995 0.337712 69.7405 0.363941 67.6814 0.357384C67.5339 0.357384 67.5044 0.396728 67.5044 0.537712C67.5077 3.01968 67.5077 5.50165 67.5077 7.98361C67.5077 10.4656 67.5077 12.9607 67.5044 15.4459C67.5044 15.5935 67.547 15.623 67.6847 15.623C69.7044 15.6197 71.7273 15.623 73.747 15.6164C74.947 15.6131 76.1175 15.4295 77.2454 15C79.147 14.2721 80.5765 13.0262 81.3962 11.141C81.9569 9.85247 82.1175 8.49509 81.9667 7.10493V7.1082ZM77.9208 9.99345C77.2814 11.3869 76.1306 12.1344 74.6651 12.4459C74.2093 12.5443 73.747 12.5738 73.2814 12.5738C72.6028 12.5738 71.9273 12.5672 71.2487 12.5771C71.0749 12.5771 71.0454 12.5246 71.0454 12.3672C71.0519 10.9115 71.0487 9.45574 71.0487 7.99673C71.0487 6.53771 71.0487 5.0623 71.0454 3.59673C71.0454 3.44919 71.0847 3.41312 71.229 3.4164C72.147 3.43607 73.0651 3.38033 73.9798 3.44919C74.7569 3.5082 75.4946 3.71148 76.1733 4.10165C77.1569 4.66558 77.8224 5.48525 78.1339 6.57706C78.465 7.73771 78.4224 8.89181 77.9175 9.99673L77.9208 9.99345Z'
                fill='currentColor'
            />
            <path
                d='M110.239 10.495C110.6 10.3901 110.934 10.2852 111.252 10.1442C112.482 9.6065 113.38 8.74748 113.82 7.45896C114.125 6.57043 114.187 5.65568 114.052 4.72781C113.826 3.17043 113.075 1.95732 111.685 1.17371C110.666 0.59994 109.547 0.380268 108.393 0.37371C105.974 0.354038 103.554 0.367153 101.134 0.360596C101.003 0.360596 100.987 0.409776 100.987 0.521251C100.987 5.50158 100.987 10.4852 100.987 15.4655C100.987 15.5967 101.026 15.6262 101.151 15.6262C102.193 15.6196 103.236 15.6196 104.282 15.6262C104.436 15.6262 104.469 15.5803 104.469 15.436C104.462 13.9508 104.469 12.4688 104.462 10.9835C104.462 10.836 104.502 10.7999 104.646 10.8032C105.187 10.8131 105.728 10.8098 106.269 10.8032C106.374 10.8032 106.439 10.836 106.505 10.9147C107.793 12.4491 109.085 13.9803 110.374 15.5147C110.439 15.5934 110.505 15.6262 110.61 15.6229C111.888 15.6196 113.167 15.6229 114.446 15.6196C114.485 15.6196 114.531 15.6393 114.574 15.5901C113.138 13.8983 111.702 12.2065 110.246 10.4917L110.239 10.495ZM108.384 7.89502C107.872 7.95076 107.357 7.91797 106.846 7.92125C106.105 7.92453 105.361 7.92125 104.62 7.92453C104.498 7.92453 104.462 7.89174 104.462 7.77043C104.469 7.05568 104.462 6.34092 104.462 5.62617C104.462 4.91797 104.462 4.20978 104.462 3.4983C104.462 3.38027 104.488 3.33765 104.616 3.34092C105.731 3.34748 106.843 3.34092 107.957 3.34748C108.325 3.34748 108.685 3.39666 109.039 3.50486C109.866 3.75404 110.37 4.2983 110.525 5.1442C110.672 5.9442 110.515 6.67863 109.918 7.26879C109.498 7.68191 108.957 7.83273 108.387 7.89502H108.384Z'
                fill='currentColor'
            />
        </g>
        <defs>
            <clipPath id='clip0_8628_10929'>
                <rect width='131.148' height='16' fill='white' transform='translate(0.427734)' />
            </clipPath>
        </defs>
    </svg>
)

export default Cloudera
