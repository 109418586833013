import React from 'react'

const Strimzi = () => (
    <svg width='74' height='62' viewBox='0 0 74 62' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_8628_10954)'>
            <path
                d='M36.9979 0.000263214L19.2935 10.2219V30.6654L36.9979 40.887L54.7025 30.6654V10.2219L36.9979 0.000263214Z'
                fill='currentColor'
            />
            <mask
                id='mask0_8628_10954'
                maskUnits='userSpaceOnUse'
                x='21'
                y='11'
                width='32'
                height='28'>
                <path
                    d='M37.4773 14.6259C36.7483 15.5605 28.1502 17.6467 21.2822 20.7412V29.5232L37.0071 38.6019L52.7321 29.5232V27.699C50.1185 27.1908 46.8344 26.7069 42.771 26.3558C28.9392 25.1605 41.7465 22.4282 47.5525 17.5298C53.3585 12.6313 28.256 11.1577 28.256 11.1577C28.256 11.1577 38.5019 13.3124 37.4773 14.6259Z'
                    fill='white'
                />
            </mask>
            <g mask='url(#mask0_8628_10954)'>
                <path
                    d='M37.4827 14.6264C36.7537 15.561 28.1555 17.6472 21.2876 20.7417V29.5236L37.0125 38.6024L52.7374 29.5236V27.6995C50.1239 27.1913 46.8398 26.7074 42.7764 26.3563C28.9445 25.161 41.7519 22.4287 47.5578 17.5303C53.3639 12.6317 28.2614 11.1582 28.2614 11.1582C28.2614 11.1582 38.5072 13.3128 37.4827 14.6264Z'
                    fill='#F9F9F9'
                />
            </g>
            <mask
                id='mask1_8628_10954'
                maskUnits='userSpaceOnUse'
                x='0'
                y='-1'
                width='74'
                height='65'>
                <path d='M0.428223 -0.824997H73.5861V63.188H0.428223V-0.824997Z' fill='white' />
            </mask>
            <g mask='url(#mask1_8628_10954)'>
                <path
                    d='M9.54858 53.1174C9.35361 52.8884 9.10195 52.7023 8.79262 52.559C8.4834 52.4159 8.12261 52.3442 7.71025 52.3442C7.38945 52.3442 7.10035 52.413 6.84259 52.5504C6.58496 52.6878 6.45608 52.9 6.45608 53.1861C6.45608 53.4154 6.56423 53.5928 6.78114 53.7187C6.99805 53.8449 7.31763 53.9764 7.73987 54.1138C8.26466 54.2856 8.7325 54.4805 9.14316 54.6979C9.55395 54.9157 9.90218 55.1677 10.1875 55.4539C10.4726 55.7403 10.6925 56.0668 10.8465 56.4332C11.0006 56.7999 11.0777 57.2122 11.0777 57.6702C11.0777 58.4492 10.9064 59.1134 10.5645 59.6632C10.2221 60.2129 9.78586 60.6597 9.25546 61.0033C8.72507 61.3469 8.14005 61.5989 7.50089 61.7591C6.86149 61.9195 6.23843 61.9997 5.63147 61.9997C5.15033 61.9997 4.66932 61.9595 4.18818 61.8795C3.70717 61.7991 3.24603 61.6845 2.80526 61.5359C2.36411 61.3871 1.94882 61.2037 1.55962 60.986C1.1703 60.7686 0.832433 60.5222 0.545898 60.2473L3.26042 57.7389C3.39796 57.8994 3.56378 58.0425 3.75875 58.1684C3.95335 58.2946 4.15648 58.4005 4.36864 58.4863C4.58043 58.5722 4.79515 58.6409 5.01292 58.6925C5.23032 58.7439 5.43089 58.7698 5.61415 58.7698C6.01506 58.7698 6.33573 58.7041 6.57642 58.5722C6.81687 58.4407 6.93721 58.2486 6.93721 57.9966C6.93721 57.7904 6.83674 57.6073 6.63653 57.4468C6.43596 57.2866 6.0609 57.1204 5.51112 56.9486C5.07571 56.8111 4.66627 56.6509 4.28268 56.4676C3.89884 56.2845 3.56671 56.0638 3.28627 55.806C3.00546 55.5484 2.78502 55.2392 2.6248 54.8783C2.46422 54.5176 2.38435 54.0794 2.38435 53.564C2.38435 52.9342 2.51311 52.3502 2.77087 51.8116C3.02851 51.2734 3.39503 50.8065 3.87043 50.4114C4.34559 50.0162 4.91257 49.707 5.57123 49.4836C6.22977 49.2604 6.9544 49.1487 7.74463 49.1487C8.1798 49.1487 8.60936 49.186 9.03318 49.2604C9.45677 49.335 9.85792 49.438 10.2359 49.5696C10.6138 49.7014 10.9631 49.8589 11.2838 50.042C11.6043 50.2254 11.8737 50.4315 12.0913 50.6606L9.54858 53.1174Z'
                    fill='currentColor'
                />
                <path
                    d='M19.3415 52.8426L17.7953 61.6561H13.8093L15.3556 52.8426H12.2803L12.8816 49.4923H22.9151L22.3136 52.8426H19.3415Z'
                    fill='currentColor'
                />
                <path
                    d='M27.4343 54.6808C27.961 54.6808 28.3733 54.5921 28.6713 54.4145C28.9689 54.2371 29.118 53.9536 29.118 53.564C29.118 53.2092 28.9947 52.9657 28.7486 52.8339C28.5021 52.7023 28.1671 52.6363 27.7435 52.6363H27.0048L26.6439 54.6808H27.4343ZM28.3105 61.6561L26.6439 57.2063H26.1629L25.407 61.6561H21.5757L23.7232 49.4923H28.7228C29.387 49.4923 29.9882 49.5669 30.5268 49.7155C31.065 49.8645 31.5232 50.0823 31.9012 50.3685C32.2792 50.6549 32.5683 51.0071 32.7688 51.425C32.969 51.8433 33.0695 52.3214 33.0695 52.8596C33.0695 53.8676 32.8316 54.698 32.3566 55.3508C31.8811 56.0037 31.2312 56.4503 30.4064 56.6909L32.7775 61.6561H28.3105Z'
                    fill='currentColor'
                />
                <path
                    d='M33.3281 61.6561L35.4757 49.4923H39.4787L37.3312 61.6561H33.3281Z'
                    fill='currentColor'
                />
                <path
                    d='M50.3563 61.6561L51.7136 54.6121H51.6106L48.0197 61.6561H45.2192L44.1369 54.6121H44.051L43.0029 61.6561H39.292L41.4395 49.4923H46.5078L47.573 56.1583H47.6589L50.8202 49.4923H56.3695L54.2219 61.6561H50.3563Z'
                    fill='currentColor'
                />
                <path
                    d='M55.7646 61.6559L56.3488 58.3229L62.0184 52.7908H57.517L58.1012 49.4921H67.7739L67.2585 52.3784L61.2797 58.2714H66.3651L65.7637 61.6559H55.7646Z'
                    fill='currentColor'
                />
                <path
                    d='M67.311 61.6561L69.4586 49.4922H73.4617L71.3141 61.6561H67.311Z'
                    fill='currentColor'
                />
            </g>
        </g>
        <defs>
            <clipPath id='clip0_8628_10954'>
                <rect width='72.914' height='62' fill='white' transform='translate(0.543457)' />
            </clipPath>
        </defs>
    </svg>
)

export default Strimzi
