export const FOOTER_LINKS = [
    {
        title: 'Product',
        links: [
            {
                title: 'Overview',
                href: '/product',
            },
            {
                title: 'Docs',
                target: '_blank',
                href: 'https://docs.lenses.io/latest',
            },
        ],
    },
    {
        title: 'Social',
        links: [
            { title: 'Github', href: 'https://github.com/lensesio', target: '_blank' },
            { title: 'Slack', href: 'https://www.launchpass.com/lensesio', target: '_blank' },
            {
                title: 'Linkedin',
                href: 'https://www.linkedin.com/company/lensesio',
                target: '_blank',
            },
            {
                title: 'Youtube',
                href: 'https://www.youtube.com/channel/UCWHoDtkhWPswP5KIY6mq7RQ',
                target: '_blank',
            },
        ],
    },
    {
        title: 'Company',
        links: [
            { title: 'Blog', href: '/blog' },
            { title: 'Contact', href: '/contact-us' },
        ],
    },
    {
        title: 'Legal',
        links: [
            { title: 'Terms', href: '/legals/terms-and-conditions' },
            { title: 'Privacy', href: '/legals/policy' },
            { title: 'Cookies', href: '/legals/cookies' },
            { title: 'SLAs', href: '/legals/sla' },
            { title: 'EULA', href: '/legals/eula' },
        ],
    },
]

export const LEGAL =
    'Apache, Apache Kafka, Kafka and associated open source project names are trademarks of the Apache Software Foundation.'
