import React, { Children } from 'react'
import * as myStyles from './styles.module.scss'
import { HeaderProps } from './types'
import Wrapper from '../wrapper'

const Slot: React.FC<{
    name: 'logo' | 'navigation' | 'actions' | 'actionsMobile'
    children?: React.ReactNode
}> = () => null
Slot.displayName = 'Header.Slot'

const Header = ({ children, className, backgroundMode, position, ...props }: HeaderProps) => {
    const styles: any = myStyles
    const childrenArray = Children.toArray(children) as unknown as React.ReactElement[]
    const logoSlot = childrenArray.find((child) => child?.props?.name === 'logo')
    const navigationSlot = childrenArray.find((child) => child?.props?.name === 'navigation')
    const actionsSlot = childrenArray.find((child) => child?.props?.name === 'actions')
    const actionsMobileSlot = childrenArray.find((child) => child?.props?.name === 'actionsMobile')

    return (
        <div
            className={`${styles.b_header} ${className}`}
            data-background={backgroundMode}
            data-position={position}
            {...props}>
            <Wrapper className={styles.wrapper} type='header'>
                <div className={styles.header_content}>
                    {logoSlot?.props && (
                        <div className={styles.header_logo}>{logoSlot.props?.children}</div>
                    )}
                    {navigationSlot?.props && (
                        <div className={styles.header_navigation}>
                            {navigationSlot.props?.children}
                        </div>
                    )}
                    {actionsSlot?.props && (
                        <div className={styles.header_actions}>{actionsSlot.props?.children}</div>
                    )}
                    {actionsMobileSlot?.props && (
                        <div className={styles.header_actions_mobile}>
                            {actionsMobileSlot.props?.children}
                        </div>
                    )}
                </div>
            </Wrapper>
        </div>
    )
}
Header.displayName = 'Header'

Header.Slot = Slot

export default Header
