import React, { useEffect, useState } from 'react'
import Navigation from '../../basics/navigation'
import * as myStyles from './styles.module.scss'
import Icon from '../../basics/icon'
import { uniqueId } from 'lodash'
import { NavigationProps } from './types'
import { ArrowIcon } from '../../basics/icons'

const NavigationComponent = ({ setIsOpen, nav }: NavigationProps) => {
    const styles: any = myStyles
    const [currentValue, setCurrentValue] = useState<string>('')
    const isOpen = currentValue != ''

    useEffect(() => {
        setIsOpen(isOpen)
    }, [currentValue, isOpen, setIsOpen])

    return (
        <Navigation
            data-is-open={isOpen}
            currentValue={currentValue}
            setCurrentValue={(value) => setCurrentValue(value)}
            className={styles.c_navigation}
            contentClass={styles.content_wrapper}>
            {nav.map(({ section, items, social }) => (
                <Navigation.ListItem key={uniqueId()}>
                    <Navigation.Item value={section}>
                        <Navigation.Trigger className={styles.trigger}>
                            <h4 className={styles.section}>{section}</h4>
                        </Navigation.Trigger>
                        <Navigation.Content className={styles.navigation_menu_content}>
                            {items.map(({ title, description, link, icon, target }) => (
                                <Navigation.Link
                                    key={uniqueId()}
                                    href={link}
                                    target={target}
                                    className={styles.navigation_content_item}>
                                    <div className={styles.icons_wrapper}>
                                        <Icon className={styles.arrow} icon={<ArrowIcon />} />
                                        <Icon className={styles.icon} icon={icon} />
                                    </div>
                                    <div>
                                        <h5 className={styles.title}>{title}</h5>
                                        <p className={styles.description}>{description}</p>
                                    </div>
                                </Navigation.Link>
                            ))}
                            {social && (
                                <div className={styles.social_wrapper}>
                                    {social.map(({ title, link, target }) => (
                                        <Navigation.Link
                                            key={uniqueId()}
                                            href={link}
                                            target={target}
                                            className={styles.link}>
                                            {title}
                                        </Navigation.Link>
                                    ))}
                                </div>
                            )}
                        </Navigation.Content>
                    </Navigation.Item>
                </Navigation.ListItem>
            ))}
        </Navigation>
    )
}

export default NavigationComponent
