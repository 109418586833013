import React from 'react'

const ArrowIcon = () => (
    <svg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M1 6.83334L10.2837 6.83334M6.82616 12.1667L11.6667 6.83334L6.82616 1.5'
            stroke='currentcolor'
            strokeLinecap='square'
        />
    </svg>
)

export default ArrowIcon
