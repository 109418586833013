import React from 'react'

const KandjiLogo = () => (
    <svg id='Layer_1' xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 139 35'>
        <g>
            <g>
                <path
                    fill='currentColor'
                    d='M89.6.8c1.8,0,3,1.3,3,3.1s-1.2,3-3,3c-.4,0-.8,0-1.2-.2s-.7-.4-1-.6c-.3-.3-.5-.6-.6-1-.1-.4-.2-.8-.2-1.2,0-1.8,1.3-3.1,3-3.1Z'
                />
                <path
                    fill='currentColor'
                    d='M82.1.8c1.8,0,3,1.3,3,3.1s-1.2,3-3,3c-.4,0-.8,0-1.2-.2-.4-.1-.7-.4-1-.6-.3-.3-.5-.6-.6-1-.1-.4-.2-.8-.2-1.2,0-1.8,1.3-3.1,3-3.1Z'
                />
                <path
                    fill='currentColor'
                    d='M72.2,1.1v10.9c-.8-.9-1.7-1.6-2.8-2.2-1.1-.5-2.3-.8-3.5-.8-4.8,0-8.7,4.3-8.7,9.5s3.9,9.5,8.7,9.5c1.2,0,2.4-.3,3.5-.8,1.1-.5,2-1.2,2.8-2.2v2.6h4.8V1.1h-4.8ZM67.1,23.7c-1,0-2.1-.3-2.9-.8-.9-.6-1.6-1.4-2-2.3-.4-1-.5-2-.3-3,.2-1,.7-2,1.4-2.7.7-.7,1.7-1.2,2.7-1.5,1-.2,2.1-.1,3,.3,1,.4,1.8,1.1,2.4,1.9.6.9.9,1.9.9,2.9,0,.7-.1,1.4-.4,2-.3.6-.6,1.2-1.1,1.7-.5.5-1,.9-1.7,1.1-.6.3-1.3.4-2,.4h0Z'
                />
                <path
                    fill='currentColor'
                    d='M79.7,9.3v17.8c0,3-.4,3.5-3.6,3.5v4.4h.7c5.7,0,7.6-1.8,7.7-7.3V9.3h-4.8Z'
                />
                <path
                    fill='currentColor'
                    d='M48.6,8.9c-1,0-2,.2-3,.7-.9.5-1.7,1.1-2.3,1.9v-2.2h-4.8v18.4h4.8v-10.7c0-1,.5-1.9,1.2-2.6.7-.7,1.7-1.1,2.7-1.1s1.9.4,2.7,1.1c.7.7,1.1,1.6,1.2,2.6v10.7h4.8v-11.1c0-4.8-2.8-7.7-7.1-7.7Z'
                />
                <path fill='currentColor' d='M87.2,9.3h4.8v18.4h-4.8V9.3h0Z' />
                <path
                    fill='currentColor'
                    d='M31,9.3v2.6c-.8-.9-1.7-1.7-2.8-2.2-1.1-.5-2.3-.8-3.5-.8-4.8,0-8.7,4.3-8.7,9.5s3.9,9.5,8.7,9.5c1.2,0,2.4-.3,3.5-.8,1.1-.5,2-1.2,2.8-2.2v2.6h4.8V9.3h-4.8ZM25.9,23.7c-1,0-2.1-.3-2.9-.8-.9-.6-1.6-1.4-2-2.3-.4-1-.5-2-.3-3,.2-1,.7-2,1.4-2.7.7-.7,1.7-1.2,2.7-1.5,1-.2,2.1-.1,3,.3,1,.4,1.8,1.1,2.4,1.9.6.9.9,1.9.9,2.9,0,.7-.1,1.4-.4,2-.3.6-.6,1.2-1.1,1.7-.5.5-1,.9-1.7,1.1-.6.3-1.3.4-2,.4h0Z'
                />
                <path
                    fill='currentColor'
                    d='M7.5,20.3l-2.8,3.2v4.4H0V1.1h4.7v17l7.5-8.5h5.6l-6.9,7.8,7.3,10.5h-5.4l-5.2-7.6h0Z'
                />
                <path
                    fill='currentColor'
                    d='M138.4,2.2c-.2-.7-.6-1.3-1.2-1.6-.6-.4-1.3-.5-2-.4-.1,0-.2,0-.3,0-5,1.4-10.9,8.9-12.7,11.3h0c0,.1-.2.3-.2.3-.1-.8-.5-1.6-1.1-2.1-.6-.6-1.3-1-2.1-1.1l1.9-.7c.3,0,.6-.3.8-.5.2-.2.3-.5.4-.8,0-.3,0-.6,0-.9-.1-.3-.3-.6-.5-.8-.3-.3-.7-.4-1.2-.4h-4.2c-.4,0-.8.1-1.2.4-.3.3-.5.6-.6,1.1,0,.4,0,.8.2,1.2.2.4.6.6,1,.8l1.9.7c-.8.2-1.5.5-2,1.1-.6.6-.9,1.3-1.1,2v-.2c-.1,0-.1,0-.1,0-1.8-2.4-7.7-9.9-12.8-11.3-.4-.1-.8-.1-1.2,0-.4,0-.8.2-1.1.4-.6.4-1,1-1.2,1.6-.2.9-.2,1.8,0,2.6.6,2.6,2.8,5.7,6,7.4h0l1.5.7c-.3,0-.6.2-.9.3-.3.1-.6.3-.9.4-.4.2-.6.5-.8.9-.1.4-.1.8,0,1.2,0,.1,0,.2.1.3.6.9,1.9,1.7,3.6,1.9,2.7.4,6.1-.5,7.9-4.3.2.6.5,1.1.9,1.5.4.4.9.8,1.4,1-1.7,0-3.6.1-4.3,1.6-.2.5-.3,1.1-.3,1.6h12c0-1.1-.2-2.1-1.2-2.7-1-.5-2.3-.5-3.5-.5.6-.2,1.2-.6,1.6-1,.4-.5.8-1,.9-1.7,1.7,3.9,5.2,4.8,7.9,4.4,1.7-.2,3-1,3.6-1.9.1-.2.2-.5.2-.7,0-.3,0-.5-.1-.8-.1-.4-.4-.7-.8-.9-.3-.2-.6-.3-1-.4-.3,0-.6-.2-.9-.3l1.5-.7h0c3.2-1.7,5.4-4.8,6-7.4.2-.9.2-1.8,0-2.6h0Z'
                />
                <path
                    fill='currentColor'
                    d='M112,21.2c0,.9.3,1.8.6,2.7h10.6c.3-.9.5-1.9.6-2.9h-11.8v.2Z'
                />
                <path
                    fill='currentColor'
                    d='M116.1,28.4c.4.3,1.4.9,1.9.7,2.2-.5,3.7-2,4.6-3.9h-9.4c.7,1.3,1.7,2.4,2.9,3.2Z'
                />
                <path fill='currentColor' d='M120.1.9l-1.4,1-.8-1.9-.8,1.9-1.4-1v2.7h4.4V.9Z' />
            </g>
        </g>
    </svg>
)

export default KandjiLogo
