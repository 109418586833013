import React from 'react'

const ContactIcon = () => (
    <svg
        width='178'
        height='206'
        viewBox='0 0 178 206'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_1429_12780)'>
            <path
                d='M59.4602 203.002H26.1302C13.8602 203.002 3.91016 193.052 3.91016 180.782V25.2317C3.91016 12.9617 13.8602 3.01172 26.1302 3.01172H137.24C149.51 3.01172 159.46 12.9617 159.46 25.2317V91.9017'
                stroke='currentcolor'
                strokeWidth='6'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M103.91 202.999V177.069L142.8 138.179C149.96 131.019 161.57 131.019 168.73 138.179C175.89 145.339 175.89 156.949 168.73 164.109L129.84 202.999H103.91Z'
                stroke='currentcolor'
                strokeWidth='6'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
            <path
                d='M48.3516 47.4414H115.022'
                stroke='#FF4802'
                strokeWidth='6'
                strokeLinecap='round'
            />
            <path
                d='M48.3516 91.8906H70.5716'
                stroke='#FF4802'
                strokeWidth='6'
                strokeLinecap='round'
            />
        </g>
        <defs>
            <clipPath id='clip0_1429_12780'>
                <rect width='178' height='206' fill='white' />
            </clipPath>
        </defs>
    </svg>
)

export default ContactIcon
